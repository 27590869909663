/*
    ----------
*/
$Font : (
    "Default" : "Inter"
);
/* 
    ----------
*/
$Size : (
    "10" : 10px,
    "11" : 11px,
    "12" : 12px,
    "13" : 13px,
    "14" : 14px,
    "15" : 15px,
    "16" : 16px,
    "17" : 17px,
    "18" : 18px,
    "19" : 19px,
    "20" : 20px
);
/* 
    ---------- 
*/
$Color : (
    "Default" : #C8102E,
    "White"   : #FFFFFF,
    "Black"   : #000000,
    "Grey"    : #3E3F41,
    "Light"   : #F4F4F4
);
/* 
    ---------- 
*/
$Mobile : (
    "Black"   : #111B21,
    "Green"   : #25D366
);
/* 
    ----------
*/
.dE5574{ font-family: map-get( $Font, "Default" ) }
/* 
    ----------
*/
.S6pt64{ float: left  }
.yJ452A{ float: right }
.eTt257{ float: none  }
/* 
    ----------
*/
.q7h2M9{ width: 25%  }
.S09r6B{ width: 50%  }
.b7P443{ width: 75%  }
.C96Q4r{ width: 100% }
/* 
    ----------
*/
.eU7H63{ font-size: map-get( $Size, "10" ) }
.S7gw68{ font-size: map-get( $Size, "11" ) }
.iI0402{ font-size: map-get( $Size, "12" ) }
.xG0j09{ font-size: map-get( $Size, "13" ) }
.PlO170{ font-size: map-get( $Size, "14" ) }
.wI860z{ font-size: map-get( $Size, "15" ) }
.Wm8G06{ font-size: map-get( $Size, "16" ) }
.bS8S40{ font-size: map-get( $Size, "17" ) }
.s4U1j1{ font-size: map-get( $Size, "18" ) }
.J589Ta{ font-size: map-get( $Size, "19" ) }
.i260Up{ font-size: map-get( $Size, "20" ) }
/* 
    ----------
*/
.C2y88Q{ font-weight: lighter }
.u37E41{ font-weight: normal  }
.Y4674t{ font-weight: bold    }
.zK3920{ font-weight: bolder  }
/* 
    ----------
*/
.F2n16X{ font-weight: 100 }
.Y8k86e{ font-weight: 200 }
.y3Or88{ font-weight: 300 }
.nS533w{ font-weight: 400 }
.K0cW96{ font-weight: 500 }
.D933nr{ font-weight: 600 }
.jS8601{ font-weight: 700 }
.Zv29i2{ font-weight: 800 }
.Is1259{ font-weight: 900 }
/* 
    ----------
*/
.J284Xb{ font-style: normal  }
.Ep1U39{ font-style: oblique }
.R6t03r{ font-style: italic  }
/* 
    ----------
*/
.v6EU83{ color: map-get( $Color, "Default" ) }
.M99v3q{ color: map-get( $Color, "White" )   }
.vOx602{ color: map-get( $Color, "Black" )   }
.Y0u334{ color: map-get( $Color, "Grey" )    }
.r4K8f4{ color: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.cvC365{ fill: map-get( $Color, "Default" ) }
.f8T6I8{ fill: map-get( $Color, "White" )   }
.sRU313{ fill: map-get( $Color, "Black" )   }
.Vr4196{ fill: map-get( $Color, "Grey" )    }
.b29YY3{ fill: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.u0Bc59{ background: map-get( $Color, "Default" ) }
.rP408N{ background: map-get( $Color, "White" )   }
.u3I753{ background: map-get( $Color, "Black" )   }
.i3eE58{ background: map-get( $Color, "Grey" )    }
.gZ959C{ background: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.wDC543{ display: inline-block }
.eF429z{ display: inline-flex  }
.xA723K{ display: table-cell   }
.X35k4K{ display: table-row    }
.bN2p30{ display: inline       }
.S0u16d{ display: flex         }
.V81zg1{ display: block        }
.oi8E43{ display: grid         }
.zD661i{ display: table        }
.t3c0N7{ display: -webkit-box  }
.hPP928{ display: none         }
.s6D202{ display: list-item    }
/* 
    ----------
*/
.fE0d48{ flex-direction: column         }
.L640Ti{ flex-direction: column-reverse }
.Y065Fd{ flex-direction: row-reverse    }
.fr598R{ flex-direction: row            }
/* 
    ----------
*/
.ZH339f{ justify-content: flex-start    }
.U080Kf{ justify-content: flex-end      }
.vR05U6{ justify-content: center        }
.g1aS48{ justify-content: space-between }
.h18Ea4{ justify-content: space-around  }
.aR51Y0{ justify-content: space-evenly  }
/* 
    ----------
*/
.I4t79h{ align-items: normal     }
.nA9305{ align-items: stretch    }
.d721Vx{ align-items: center     }
.Q3n44O{ align-items: flex-start }
.J7l0G2{ align-items: flex-end   }
.W44s9p{ align-items: start      }
.C6u87n{ align-items: end        }
.Q7Ae62{ align-items: baseline   }
/* 
    ----------
*/
.xD280K{ text-align: start   }
.Q606yt{ text-align: end     }
.vC9r15{ text-align: center  }
.yyO233{ text-align: justify }
/* 
    ----------
*/
.uUG847{ text-transform: lowercase  }
.GzJ197{ text-transform: uppercase  }
.cMq044{ text-transform: capitalize }
.e2M03y{ text-transform: none       }
/*
    ----------
*/
.vF5k47{ text-decoration: none         }
.F83vw7{ text-decoration: dashed       }
.a20K95{ text-decoration: dotted       }
.C71Dm7{ text-decoration: double       }
.El6174{ text-decoration: solid        }
.r9zX81{ text-decoration: overline     }
.sB248t{ text-decoration: wavy         }
.Q3s2S1{ text-decoration: underline    }
.h6T64Y{ text-decoration: line-through }
/*
    ---------- 
*/
.cB3332{ text-underline-offset: 6px }
/*
   ----------
*/
.q8K60t{ text-overflow: ellipsis }
.faJ087{ text-overflow: clip     }
.m1BW70{ text-overflow: unset    }
/*
    ----------
*/
.U596Kr{ vertical-align: baseline    }
.t0G834{ vertical-align: sub         }
.ebZ371{ vertical-align: super       }
.I7e03O{ vertical-align: top         }
.j844Eb{ vertical-align: text-top    }
.eQ738Y{ vertical-align: middle      }
.E0vm38{ vertical-align: bottom      }
.z56Q50{ vertical-align: text-bottom }
/*
    ----------
*/
.gL0474{ white-space: normal }
.wqA166{ white-space: nowrap }
.Q7a1D3{ white-space: pre    }
/* 
    ----------
*/
.cH8711{ position: relative }
.gBt584{ position: absolute }
.p9V85g{ position: sticky   }
.uK7E77{ position: static   }
.Ude336{ position: fixed    }
/*
    ----------
*/
.x35Ac2{ overflow: visible }
.J057Si{ overflow: hidden  }
.jC732U{ overflow: scroll  }
.L9Rm29{ overflow: auto    }
/*
    ----------
*/
.yL0811{ cursor: alias         }
.v6CN61{ cursor: all-scroll    }
.qE800M{ cursor: auto          }
.tJ204O{ cursor: cell          }
.sDN443{ cursor: col-resize    }
.p8C19l{ cursor: context-menu  }
.E0m26B{ cursor: copy          }
.L567nt{ cursor: crosshair     }
.Ri032C{ cursor: default       }
.kD889h{ cursor: e-resize      }
.S6u0h5{ cursor: ew-resize     }
.c2Q91N{ cursor: grab          }
.dZ9M00{ cursor: grabbing      }
.gM266u{ cursor: help          }
.dP797B{ cursor: move          }
.j3Uh65{ cursor: n-resize      }
.cZ180w{ cursor: ne-resize     }
.aH3174{ cursor: nw-resize     }
.j53Z9D{ cursor: nwse-resize   }
.Gp2446{ cursor: no-drop       }
.Lr9809{ cursor: not-allowed   }
.N4g4m6{ cursor: pointer       }
.k6G6h5{ cursor: progress      }
.Yri682{ cursor: row-resize    }
.Zd4o45{ cursor: s-resize      }
.X2R6j8{ cursor: se-resize     }
.p2M9c8{ cursor: sw-resize     }
.Xw0F20{ cursor: text          }
.Q6j5W4{ cursor: vertical-text }
.S344z0{ cursor: w-resize      }
.z9V38t{ cursor: wait          }
.Ac102a{ cursor: zoom-in       }
.d6F48n{ cursor: zoom-out      }
.h30X35{ cursor: none          }
/*
    ----------
*/
.Kg80G9{ word-wrap: normal     }
.uQ136M{ word-wrap: break-word }
/*
    ----------
*/
.z9X51G{ pointer-events: none }
.Bj1642{ pointer-events: auto }
/*
    ----------
*/
.oQ637t{ border-style: dotted }
.r380oV{ border-style: dashed }
.F5m883{ border-style: solid  }
.Fb292w{ border-style: double }
.Fcx262{ border-style: groove }
.eS845b{ border-style: ridge  }
.L2sq24{ border-style: inset  }
.t3KL23{ border-style: outset }
.Z7o516{ border-style: none   }
.Wx078J{ border-style: hidden }
/*
    ----------
*/
.ob2A34{ border:        0 }
.xq62J3{ border-top:    0 }
.PvN976{ border-bottom: 0 }
.rJ26U8{ border-left:   0 }
.LjJ561{ border-right:  0 }
/*
    ----------
*/
.L72y8b{ border-collapse: collapse }
.Z5y7o1{ border-collapse: separate }
/*
    ----------
*/
.m9X7C5{ margin: 0 auto }
/*
    ----------
*/
.pEO064{ margin:        0 }
.vSV800{ margin-left:   0 }
.D797aR{ margin-right:  0 }
.sX6873{ margin-top:    0 }
.B2d81S{ margin-bottom: 0 }
/*
    ----------
*/
.a527eB{ padding:        0 }
.z8o5S4{ padding-top:    0 }
.dW8M00{ padding-right:  0 }
.C7D19o{ padding-left:   0 }
.R3a5i0{ padding-bottom: 0 }
/*
    ----------
*/
.GO52g1{ border-radius: 0    }
.g03C00{ border-radius: 25%  }
.K79e1C{ border-radius: 50%  }
.pZR189{ border-radius: 75%  }
.kZm977{ border-radius: 100% }
/*
    ----------
*/
.wzU404{ flex: 0 0 auto }
.uTM149{ flex: 1 1 auto }
.U977pl{ flex: 0 1 auto }
.z097Vp{ flex: 1 0 auto }
/*
    ----------
*/
.P1470a{ flex-grow: 0 }
.iw2U41{ flex-grow: 1 }
.I750Xd{ flex-grow: 2 }
.S276Td{ flex-grow: 3 }
/*
    ----------
*/
.X594yq{ flex-basis: 1 }
.U716Tv{ flex-basis: 2 }
.Rzr931{ flex-basis: 3 }
/*
    ----------
*/
.o9T8I8{ flex-wrap: wrap         }
.R0X85t{ flex-wrap: nowrap       }
.Fv9Z90{ flex-wrap: wrap-reverse }
/*
    ----------
*/
.vE6956{ order: 1 }
.XjX424{ order: 2 }
.W85it5{ order: 3 }
/*
    ----------
*/
.Q330w3{ align-content: stretch       }
.v69MS6{ align-content: center        }
.X19jp8{ align-content: space-evenly  }
.v74JJ5{ align-content: flex-start    }
.uH27J4{ align-content: flex-end      }
.T3l2s4{ align-content: space-between }
.D123Qj{ align-content: space-around  }
/*
    ----------
*/
.j1Mr03{ flex-shrink: 0 }
.n7OS97{ flex-shrink: 1 }
/*
    ----------
*/
.Ax30Q1{ box-shadow: none }
/*
    ----------
*/
.J55M9e{ content: "" }
/* 
    ----------
*/
.C4v153{ outline: 0    }
.sD881L{ outline: auto }
/* 
    ----------
*/
.pL4U70{ opacity: 0    }
.q693Lc{ opacity: 25%  }
.aT591i{ opacity: 50%  }
.S8k6H9{ opacity: 75%  }
.aV498I{ opacity: 100% }
/*
    ----------
*/
.cB943h{ -webkit-line-clamp: 2 }
.Bt6232{ -webkit-line-clamp: 3 }
.X2a9R4{ -webkit-line-clamp: 4 }
/*
    ----------
*/
.gDk539{ -webkit-box-orient: block-axis  }
.J4i292{ -webkit-box-orient: inline-axis }
.uQ1c23{ -webkit-box-orient: horizontal  }
.G7o96W{ -webkit-box-orient: vertical    }
/*
    ----------
*/
.c8R6W7{ list-style: decimal-leading-zero }
.WvX121{ list-style: lower-alpha          }
.Iy85Z8{ list-style: lower-greek          }
.X6y6H0{ list-style: lower-latin          }
.xT170D{ list-style: lower-roman          }
.J564hZ{ list-style: upper-alpha          }
.Qd352p{ list-style: upper-latin          }
.Np616D{ list-style: upper-roman          }
.W18b0l{ list-style: disc                 }
.bR099N{ list-style: georgian             }
.Jd2580{ list-style: inside               }
.R723p4{ list-style: outside              }
.dK9146{ list-style: square               }
.h57Y8Q{ list-style: circle               }
.kO539q{ list-style: armenian             }
.vY001R{ list-style: decimal              }
.Au5718{ list-style: numeric              }
.GnG350{ list-style: none                 }
/*
    ----------
*/
.C196Fv{ writing-mode: horizontal-tb }
.Z95a8x{ writing-mode: vertical-lr   }
.x0F757{ writing-mode: vertical-rl   }
/*
    ----------
*/
.Klc797{ transition: all .8s ease }
/*
    ----------
*/
.t0T2S2{ transform: none           }
.wD3v95{ transform: rotate(90deg)  }
.Zka424{ transform: rotate(180deg) }
.v1M46U{ transform: rotate(270deg) }
.j833Ma{ transform: rotate(360deg) }
/*
    ----------
*/
.jX0924{ object-fit: fill       }
.C0go67{ object-fit: contain    }
.Mg1457{ object-fit: cover      }
.uX61k8{ object-fit: scale-down }
.y60E9p{ object-fit: none       }
/* 
    ----------
*/
.qW182T{ line-height: 1.0 }
.tSz942{ line-height: 1.5 }
.YS535e{ line-height: 2.0 }
/*
    ----------
*/
.SFq502{ background-position: left top      }
.iJM161{ background-position: left center   }
.Gr070F{ background-position: left bottom   }
.Px402I{ background-position: right top     }
.t6K867{ background-position: right center  }
.mG848G{ background-position: right bottom  }
.J2l421{ background-position: center top    }
.F710rg{ background-position: center center }
.vW8q13{ background-position: center bottom }
/*
    ----------
*/
.Tq143x{ background-size: auto    }
.T659Fi{ background-size: cover   }
.B4A44p{ background-size: contain }
/*
    ----------
*/
.P6cE22{ background-repeat: repeat    }
.XcJ938{ background-repeat: repeat-x  }
.rYx754{ background-repeat: repeat-y  }
.N965Go{ background-repeat: no-repeat }
.rG790K{ background-repeat: space     }
.kV124B{ background-repeat: round     }
/*
    ----------
*/
.P779mc{ box-sizing: border-box }
/*
    ----------
*/
.H9g95G{ word-break: normal     }
.kO189r{ word-break: break-all  }
.wK1970{ word-break: keep-all   }
.kL64Z1{ word-break: break-word }
/*
    ----------
*/
.q629Gd{ filter: brightness(0.5)   }
.jSO180{ filter: contrast(160%)     }
.H61jT8{ filter: saturate(3)        }
.wM7o82{ filter: invert(100%)       }
.Q8b7e3{ filter: grayscale(50%)     }
.T354Vs{ filter: sepia(100%)        }
.U029ho{ filter: blur(3px)          }
.o98b8U{ filter: hue-rotate(142deg) }
/*
    ----------
*/
.C23pq8{ resize: both       }
.qS03n2{ resize: none       }
.Ss37t2{ resize: horizontal }
.n3R12l{ resize: vertical   }
/*
    ----------
*/
@mixin Size( $Width, $Height ){ 
    width  : $Width; 
    height : $Height 
}
@mixin Start( $Start, $Top ){ 
    left : $Start; 
    top  : $Top 
}
@mixin End( $End, $Top ){ 
    right : $End; 
    top   : $Top 
}
@mixin Top( $Start, $Bottom ){ 
    left   : $Start; 
    bottom : $Bottom 
}
@mixin Bottom( $End, $Bottom ){ 
    right  : $End; 
    bottom : $Bottom 
}
@mixin Margin( $Top, $End, $Bottom, $Start ){ 
    margin: $Top $End $Bottom $Start 
}
@mixin Padding( $Top, $End, $Bottom, $Start ){ 
    padding: $Top $End $Bottom $Start 
}
/* 
    ----------
*/
html{
    @extend .dE5574;
}
a{
    @extend .v6EU83;
    @extend .vF5k47;
}
a:hover{
    @extend .Q3s2S1;
}
/* 
    ----------
*/
:root{
    --css-s4j78n : 12.5px;
    --css-w411n0 : 13.5px;
    --css-e07660 : 14.5px;
    --css-t515p5 : 21px;
    --css-s823ly : 22px;
    --css-h494sb : 24px;
    --css-u73c17 : 145px;
}
:root{
    --css-g0217c : #32536A;
    --css-q526mt : #FF6F75;
    --css-a7g38c : #547AF5;
    --css-x93o1r : #FFCA28;
    --css-d310zv : #9B9B9B;
    --css-z3794l : #737E78;
    --css-g33o36 : #343A40;
    --css-c34a72 : #CBD2D9;
    --css-k23b2o : #4D2B1A;
    --css-sr694d : #FC6E6B;
    --css-j122d2 : #E0CBAE;
    --css-xe7m81 : #8C9CB7;
    --css-r2157z : #FBBC04;
    --css-m02l09 : #CBD2D9;
    --css-q238d9 : #E2E2E2;
    --css-p9830t : #6C757D;
}
:root{
    --css-j980f4 : #121212;
    --css-n436ik : #2B2B2B;
    --css-w859ad : #999999;
}
:root{
    --css-j71y4n : 0px 25px 50px 0px rgba( 38, 53, 65, 0.12 );
}
/* 
    ----------
*/
body{
    @extend .dE5574;
    @extend .Wm8G06;
    @extend .rP408N;
    &::-webkit-scrollbar{
        @include Size( 1px, auto );
    }
    &::-webkit-scrollbar-track{
        background: map-get( $Color, "Light" );
    }
    &::-webkit-scrollbar-thumb{
        background: map-get( $Color, "Default" );
    }
}
/* 
    ----------
*/
.w1324l{
    @extend .Klc797;
    .hw9107{
        @include Size( 100%, 45px );
        @extend .gZ959C;
        .g301va{
            @include Size( 100%, 45px );
            @extend .Klc797;
            .p083cW{
                @extend .S7gw68;
                @extend .vOx602;
                line-height: 45px;
                u{
                    @extend .cB3332;
                }
            }
        }
        .g053w3{
            @include Size( 100%, 45px );
            @extend .Klc797;
            .u96gu5{
                @include Size( 100%, auto );
                @extend .C4v153;
                @extend .Klc797;
                @extend .S7gw68;
                @extend .vOx602
            }
        }
    }
    .f55237{
        @include Size( 100%, 75px );
        @extend .cH8711;
        .c694qt{
            @include Size( 100%, 75px );
            @extend .Klc797;
            @extend .C4v153;
            @extend .cH8711;
            z-index: 9999;
            svg{
                @include Size( 54px, 54px );
                @extend .Klc797;
            }
        }
        &::before{
            @include Start( 0, 0 );
            @include Size( 316px, 75px );
            @extend .S6pt64;
            @extend .J55M9e;
            @extend .gBt584;
            @extend .u0Bc59;
        }
    }
    .rP69J9{
        @include Size( 100%, auto );
        @extend .GnG350;
        li{
            @extend .cH8711;
            a{
                @extend .vOx602;
                @extend .pEO064;
                @extend .K0cW96;
                @extend .cH8711;
                font-size: var(--css-w411n0);
                padding: 0 14px;
                line-height: 75px;
            }
            ul{
                @include Start( 0, 75px );
                @extend .GnG350;
                @extend .cH8711;
                @extend .hPP928;
                background: var(--css-j980f4);
                box-shadow: var(--css-j71y4n);
                min-width: 275px;
                z-index: 999;
                li{
                    @include Size( 100%, auto );
                    padding: 0 24px;
                    a{
                        @include Size( 100%, 48px );
                        @extend .xG0j09;
                        @extend .pEO064;
                        @extend .a527eB;
                        @extend .vF5k47;
                        @extend .Klc797;
                        color: var(--css-w859ad);
                        border-bottom: 1px solid var(--css-n436ik);
                        line-height: 48px;
                        span{
                            @extend .vF5k47;
                        }
                        &::after{
                            @extend .yJ452A;
                            @extend .Klc797;
                            content: url( "./Media/Icon/010.svg" );
                            margin: 3px 0 0 0;
                        }
                        &:hover{
                            @extend .M99v3q;
                            &::after{
                                content: url( "./Media/Icon/011.svg" );
                            }
                        }
                    }
                    &:last-child a{
                        @extend .ob2A34;
                        @extend .vF5k47;
                    }
                }
                &::before, &::after{
                    @include Size( 0, 0 );
                    @include Top( 16px, 100% );
                    @extend .J55M9e;
                    @extend .gBt584;
                }
                &::before{
                    @include Size( 0, 0 );
                    border-style: solid;
                    border-right: 10px solid transparent;
                    border-left: 10px solid transparent;
                    border-bottom: 10px solid var(--css-j980f4);
                    border-top: 0;
                }
            }
            &:first-child a{
                @extend .C7D19o;
            }
            &:last-child a{
                @extend .dW8M00;
            }
            &:hover ul{
                @extend .Klc797;
                display: block!important
            }
        }
    }
    .c37034{
        @include Size( 100%, 75px );
        @extend .Klc797;
        .f031o3{
            @include Size( 100%, 40px );
            @extend .Klc797;
            @extend .C4v153;
            @extend .ob2A34;
            @extend .iI0402;
            @extend .M99v3q;
            @extend .K0cW96;
            @extend .u0Bc59;
            min-width: 164px;
            border-radius: 40px;
            line-height: 40px;
            &::before{
                content: "Bilgilendirme Talebi"
            }
            &:hover{
                @extend .u3I753;
                @extend .M99v3q;
                @extend .vF5k47;
            }
        }
    }
    .s8956w{
        @include Size( 100%, 50px );
        @extend .u3I753;
        @extend .cH8711;
        @extend .hPP928;
        z-index: 9999;
        .g2d163{
            @include Size( 100%, 50px );
            @extend .Klc797;
            .o188l9{
                @include Size( 100%, auto );
                @extend .M99v3q;
                @extend .K0cW96;
                font-size: var(--css-w411n0);
                u{
                    @extend .Klc797;
                    @extend .cB3332;
                }
            }
            .q746r5{
                @include Size( 100%, auto );
                @extend .M99v3q;
                @extend .K0cW96;
                font-size: var(--css-w411n0);
            }
            .swiper{
                @include Size( 100%, 50px );
            }
        }
    }
    .m78d3j{
        @include Size( 100%, 75px );
        @extend .cH8711;
        @extend .rP408N;
        @extend .hPP928;
        z-index: 9999;
        .s5c45j{
            @include Size( 100%, 75px );
            @extend .Klc797;
            @extend .C4v153;
            .x0b837{
                @include Size( 100%, auto );
                @extend .rP408N;
            }
            svg{
                @include Size( 40px, 40px );
                @extend .Klc797;
            }
        }
        .ox8525{
            @include Size( 100%, 75px );
            @extend .Klc797;
            .n772zy{
                @include Size( auto, 40px );
                @extend .u0Bc59;
                @extend .ob2A34;
                @extend .Klc797;
                @extend .C4v153;
                @extend .iI0402;
                @extend .M99v3q;
                @extend .K0cW96;
                border-radius: 40px;
                &::before{
                    content: "Bilgilendirme Talebi"
                }
                &:hover{
                    @extend .u3I753;
                }
            }
            .v7c42n{
                @include Size( auto, 75px );
                @extend .Klc797;
                @extend .C4v153;
                animation-name: Ring;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                svg{
                    @include Size( 18px, 18px );
                    @extend .Klc797;
                }
            }
            .q928g8{
                @include Size( auto, 75px );
                @extend .Klc797;
                @extend .C4v153;
                transform: matrix( -1, 0, 0, 1, 0, 0 );
                svg{
                    @include Size( 22px, 22px );
                    @extend .Klc797;
                    @extend .sRU313;
                    path{
                        @extend .sRU313;
                    }
                }
            }
            .v10846{
                @include Size( auto, 75px );
                @extend .Klc797;
                @extend .C4v153;
                svg{
                    @include Size( 22px, 22px );
                    @extend .Klc797;
                }
            }
        }
        .col-md-3{
            @include Size( 25%, auto );
        }
        .col-md-9{
            @include Size( 75%, auto );
        }
    }
    .w22y4l{
        @include Start( 0, 0 );
        @include Size( 100%, calc( 100vh - 80px ) );
        @extend .hPP928;
        @extend .Klc797;
        @extend .rP408N;
        @extend .jC732U;
        @extend .hPP928;
        padding: 125px 0 0 0!important;
        min-height: 100dvh;
        z-index: 9998;
        .t328w9{
            @include Size( 100%, auto );
            @extend .gZ959C;
            @extend .Klc797;
            .z120dk{
                @include Size( 100%, auto );
                @extend .Klc797;
                .k922mv{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    @extend .cH8711;
                    z-index: 12;
                    .s2p966{
                        @include Size( 100%, 48px );
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .xG0j09;
                        @extend .vOx602;
                        @extend .nS533w;
                        @extend .aV498I;
                        &::placeholder{
                            @extend .aV498I;
                            color: var(--css-z3794l);
                        }
                    }
                    .d8005g{
                        @include Size( 100%, 48px );
                        @extend .Klc797;
                        @extend .C4v153;
                        max-width: 48px;
                        svg{
                            @include Size( 19px, 19px );
                        }
                    }
                }
            }
        }
        .j4d4t5{
            @include Size( 100%, auto );
            @extend .rP408N;
            @extend .Klc797;
            .y98o1m{
                @include Size( 100%, auto );
                @extend .GnG350;
                li{
                    a{
                        @include Size( 100%, 55px );
                        @extend .PlO170;
                        @extend .vOx602;
                        @extend .K0cW96;
                        border-bottom: 1px solid var(--css-q238d9);
                        line-height: 55px;
                        strong{
                            @extend .K0cW96;
                        }
                        svg{
                            @include Size( 17px, 17px );
                            @extend .Klc797;
                            margin: 1px 0 0 0;
                        }
                    }
                }
            }
            .x0j627{
                @include Size( 100%, auto );
                @extend .GnG350;
                li{
                    a{
                        @include Size( 100%, 55px );
                        @extend .PlO170;
                        @extend .vOx602;
                        @extend .K0cW96;
                        border-bottom: 1px solid var(--css-q238d9);
                        line-height: 55px;
                        strong{
                            @extend .K0cW96;
                        }
                        svg{
                            @include Size( 17px, 17px );
                            @extend .Klc797;
                            margin: 1px 0 0 0;
                        }
                    }
                }
            }
            .w057op{
                .r307z9{
                    @include Size( 100%, 48px );
                    @extend .ob2A34;
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .cH8711;
                    @extend .M99v3q;
                    @extend .K0cW96;
                    @extend .xG0j09;
                    background: map-get( $Color, "Default" );
                    border-radius: 48px;
                    &::before{
                        @include Size( 48px, 48px );
                        @include End( 0, 0 );
                        @extend .yJ452A;
                        @extend .gBt584;
                        @extend .S0u16d;
                        @extend .vR05U6;
                        @extend .d721Vx;
                        padding: 5px 0 0 1px;
                        content: url( "./Media/Mobile/007.svg" );
                    }
                    &::after{
                        @include Size( 48px, 100% );
                        @extend .yJ452A;
                        @extend .kZm977;
                        @extend .J55M9e;
                        background: darken( map-get( $Color, "Default" ), 5% );
                        padding: 1px 0 0 0;
                    }
                }
                .bs0p47{
                    @include Size( 100%, 48px );
                    @extend .ob2A34;
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .cH8711;
                    @extend .M99v3q;
                    @extend .K0cW96;
                    @extend .xG0j09;
                    background: map-get( $Mobile, "Green" );
                    border-radius: 48px;
                    &::before{
                        @include Size( 48px, 48px );
                        @include End( 0, 0 );
                        @extend .yJ452A;
                        @extend .gBt584;
                        @extend .S0u16d;
                        @extend .vR05U6;
                        @extend .d721Vx;
                        content: url( "./Media/Mobile/004.svg" );
                        padding: 5px 0 0 1px;
                    }
                    &::after{
                        @include Size( 48px, 100% );
                        @extend .yJ452A;
                        @extend .kZm977;
                        @extend .J55M9e;
                        background: darken( map-get( $Mobile, "Green" ), 5% );
                    }
                }
            }
        }
    }
}
/* 
    ----------
*/
.h119in{
    @extend .Klc797;
    .w958nq{
        @extend .Klc797;
        .w542au{
            @extend .Klc797;
            .v7q534{
                @include Size( 100%, 100% );
                @extend .Klc797;
                .m6083e{
                    @extend .vOx602;
                    @extend .K0cW96;
                    font-size: var(--css-h494sb);
                    line-height: 30px;
                    label{
                        @extend .v6EU83;
                    }
                }
                .e745l2{
                    @extend .PlO170;
                    @extend .nS533w;
                    @extend .Y0u334;
                    line-height: 25px
                }
                .k66z9t{
                    @extend .PlO170;
                    @extend .nS533w;
                    @extend .Y0u334;
                    @extend .hPP928;
                    line-height: 25px
                }
                .d01iq4{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .k51l81{
                        @include Size( 100%, 42px );
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .u0Bc59;
                        @extend .xG0j09;
                        @extend .M99v3q;
                        @extend .K0cW96;
                        border: 1px solid map-get( $Color, "Default" );
                        border-radius: 42px;
                        max-width: 180px;
                        &:hover{
                            @extend .rP408N;
                            @extend .v6EU83;
                        }
                    }
                    .b584xj{
                        @include Size( 100%, 42px );
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .rP408N;
                        @extend .vOx602;
                        @extend .K0cW96;
                        @extend .ob2A34;
                        font-size: var(--css-w411n0);
                        border-radius: 42px;
                        max-width: 160px;
                        &:hover{
                            @extend .Q3s2S1;
                            @extend .cB3332;
                        }
                    }
                }
            }
            .qcc560{
                @include Size( 100%, auto );
                @extend .T659Fi;
                @extend .F710rg;
                @extend .N965Go;
                @extend .cH8711;
            }
            .pi4981{
                @include Size( 100%, auto );
                @extend .T659Fi;
                @extend .F710rg;
                @extend .N965Go;
                @extend .cH8711;
                @extend .hPP928;
            }
        }
    }
    .c250ba{
        @extend .Klc797;
        .o720ha{
            @extend .Klc797;
            .o50h0d{
                @extend .vOx602;
                @extend .K0cW96;
                @extend .i260Up;
            }
            .m8m73h{
                @extend .nS533w;
                @extend .Y0u334;
                font-size: var(--css-w411n0);
                line-height: 23px;
            }
        }
    }
    .x35f74{
        @include Size( 100%, auto );
        .h4s606{
            @extend .Klc797;
            .e74i23{
                @include Size( 100%, 477px );
                img{
                    @include Size( 100%, auto );
                }
            }
        }
        .po7670{
            @include Size( 100%, 477px );
            @extend .Klc797;
            .q35h02{
                @extend .vOx602;
                @extend .K0cW96;
                font-size: var(--css-h494sb);
                strong{
                    @extend .v6EU83;
                    @extend .K0cW96;
                }
            }
            .w000w8{
                @extend .PlO170;
                @extend .nS533w;
                @extend .Y0u334;
                line-height: 25px
            }
            .s12a3e{
                @extend .xG0j09;
                @extend .nS533w;
                @extend .Y0u334;
                @extend .hPP928;
                line-height: 23px;
                &::before{
                    content: "İşletmenizin çevrimiçi varlığını oluşturmak tanınırlığınızı artırarak daha fazla müşteri çekmenize ve online ile yerel satışları artırmanıza yardımcı olabilir."
                }
            }
            .g729kz{
                @extend .Klc797;
                .y15u5Q{
                    @extend .GnG350;
                    li{
                        span{
                            @include Size( 100%, 16px );
                            @extend .Klc797;
                            i{
                                @include Size( 18px, 18px );
                                @extend .rP408N;
                                @extend .kZm977;
                                border: 1px solid map-get( $Color, "Grey" );
                                svg{
                                    @include Size( 10px, 10px );
                                    margin: 1px 0 0 0;
                                }
                            }
                            strong{
                                @extend .PlO170;
                                @extend .Y0u334;
                                @extend .nS533w;
                                line-height: 18px
                            }
                        }
                    }
                }
            }
        }
        .c741h7{
            @include Size( 100%, 477px );
            @extend .Klc797;
            .r89a95{
                @include Size( 100%, 48px );
                @extend .rP408N;
                @extend .kZm977;
                max-width: 48px;
                box-shadow: var(--css-j71y4n);
                svg{
                    @include Size( 20px, 20px );
                    @extend .Klc797;
                }
                img{
                    @include Size( 20px, 20px );
                    @extend .Klc797;
                }
            }
        }
    }
    .p2z98p{
        @extend .Klc797;
        .z93q7k{
            @include Size( 100%, 250px );
            @extend .Klc797;
            .c28g97{
                @extend .vOx602;
                @extend .K0cW96;
                font-size: var(--css-h494sb);
                line-height: 25px;
                strong{
                    @extend .v6EU83;
                    @extend .K0cW96;
                }
            }
            .qo3320{
                @extend .PlO170;
                @extend .Y0u334;
                line-height: 24px;
            }
            .b47q67{
                @extend .PlO170;
                @extend .Y0u334;
                @extend .hPP928;
                line-height: 24px;
            }
            .vp0y83{
                @extend .PlO170;
                @extend .Y0u334;
                @extend .hPP928;
                line-height: 24px;
            }
            .n7193f{
                @extend .PlO170;
                @extend .Y0u334;
                @extend .hPP928;
                line-height: 24px;
            }
            .i31t7q{
                @extend .PlO170;
                @extend .Y0u334;
                line-height: 24px;
            }
            .x5e769{
                @extend .PlO170;
                @extend .Y0u334;
                @extend .hPP928;
                line-height: 24px;
            }
        }
    }
    .g64my5{
        .n5a38b{
            @include Size( 100%, 250px );
            @extend .Klc797;
            .c28g97{
                @extend .vOx602;
                @extend .K0cW96;
                font-size: var(--css-h494sb);
                line-height: 25px;
                strong{
                    @extend .v6EU83;
                    @extend .K0cW96;
                }
            }
            .i31t7q{
                @extend .PlO170;
                @extend .Y0u334;
                line-height: 24px;
            }
        }
    }
    .sI631f{
        @extend .u3I753;
        margin: 75px 0 25px 0;
        padding: 75px 0;
        .mt635K{
            @extend .Klc797;
            .u1s51D{
                @include Size( 100%, auto );
                @extend .gZ959C;
                img{
                    @include Size( 100%, auto );
                }
            }
        }
        .g430eq{
            @extend .Klc797;
            .v4075g{
                @include Size( 100%, auto );
                @extend .gZ959C;
                img{
                    @include Size( 100%, auto );
                }
            }
        }
    }
    .j0r112{
        @extend .Klc797;
        @extend .Klc797;
        .p2769m{
            @include Size( 100%, 450px );
            @extend .Klc797;
            .b98w7p{
                @extend .Klc797;
                .p72qx6{
                    @include Size( 96px, 96px );
                    @extend .gZ959C;
                    max-width: 96px
                }
            }
        }
        .h68k88{
            @extend .Klc797;
            .d8q835{
                @extend .vF5k47;
                @extend .Klc797;
                .y62e43{
                    @include Size( 100%, auto );
                    @extend .u0Bc59;
                    .q892wd{
                        @extend .Wm8G06;
                        @extend .M99v3q;
                        @extend .K0cW96;
                        line-height: 26px
                    }
                }
                .ja605u{
                    @extend .Klc797;
                    .h33p0j{
                        @include Size( auto, 150px );
                        @extend .gZ959C;
                        @extend .a527eB;
                        margin: -50px 0 0 0;
                        min-width: 150px;
                    }
                    .z48376{
                        @extend .Klc797;
                        .t53z62{
                            @extend .wI860z;
                            @extend .vOx602;
                            @extend .nS533w;
                            @extend .vF5k47;
                        }
                        .o1m43l{
                            @extend .xG0j09;
                            @extend .nS533w;
                            @extend .Y0u334;
                            @extend .vF5k47;
                            line-height: 20px
                        }
                    }
                }
                &:hover .o1m43l{
                    @extend .vF5k47;
                }
            }
            .swiper-pagination-bullets{
                @extend .S0u16d;
                @extend .vR05U6;
                @extend .d721Vx;
                @extend .hPP928;
                .swiper-pagination-bullet{
                    @include Size( 11px, 11px );
                    @extend .wDC543;
                    @extend .gZ959C;
                    @extend .kZm977;
                    @extend .N4g4m6;
                    @extend .Klc797;
                    margin: 50px 10px 0 0;
                    &:nth-child(1):hover{
                        background: var(--css-q526mt);
                    }
                    &:nth-child(2):hover{
                        background: var(--css-x93o1r);
                    }
                    &:nth-child(3):hover{
                        background: var(--css-a7g38c);
                    }
                    &:nth-child(4):hover{
                        background: var(--css-d310zv);
                    }
                    &:nth-child(5):hover{
                        background: var(--css-z3794l);
                    }
                    &:hover{
                        @extend .u0Bc59;
                    }
                }
                .swiper-pagination-bullet-active{
                    background: map-get( $Color, "Black" );
                }
            }
            .swiper .swiper-slide:nth-child(1) .y62e43{
                background: var(--css-q526mt);
            }
            .swiper .swiper-slide:nth-child(2) .y62e43{
                background: var(--css-x93o1r)
            }
            .swiper .swiper-slide:nth-child(2) .q892wd{
                color: var(--css-g33o36)
            }
            .swiper .swiper-slide:nth-child(3) .y62e43{
                background: var(--css-a7g38c);
            }
            .swiper .swiper-slide:nth-child(4) .y62e43{
                background: var(--css-d310zv);
            }
            .swiper .swiper-slide:nth-child(5) .y62e43{
                background: var(--css-z3794l);
            }
            .swiper .swiper-slide:nth-child(5) .q892wd{
                @extend .M99v3q;
            }
        }
        .container .row .col-md-3:last-child .row .col-md-6 .p2769m .b98w7p{ justify-content: end!important }
    }
    .e8336u{
        @extend .Klc797;
        .ac1099{
            @extend .Klc797;
            .s3674h{
                @include Size( 100%, auto );
                @extend .rP408N;
                .b39yo6{
                    @include Size( 100%, auto );
                    img{
                        @include Size( 110px, auto );
                    }
                }
            }
        }
    }
    .s3zb07{
        @extend .Klc797;
        .a419o0{
            @include Size( 100%, 573px );
            @extend .Klc797;
            .i1888e{
                @include Size( 100%, 48px );
                @extend .Klc797;
                max-width: 48px;
                .m24ho4{
                    @extend .Klc797;
                    img{
                        @include Size( 20px, 20px );
                    }
                }
            }
        }
        .e10di5{
            @include Size( 100%, auto );
            @extend .cH8711;
            .n185ke{
                @extend .Klc797;
                img{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                }
            }
            .t3d7q5{
                @include Size( 100%, auto );
                @include Top( 100px, -50px );
                @extend .u0Bc59;
                @extend .gBt584;
                @extend .Wm8G06;
                @extend .M99v3q;
                @extend .K0cW96;
                @extend .pL4U70;
                max-width: 410px;
                line-height: 26px
            }
        }
        .br6312{
            @extend .Klc797;
            border-bottom: 1px solid var(--css-c34a72);
            .s63448{
                @extend .Wm8G06;
                @extend .vOx602;
                @extend .K0cW96;
            }
            .v976lh{
                @extend .nS533w;
                @extend .Y0u334;
                font-size: var(--css-w411n0);
                line-height: 22px;
            }
        }
    }
    .h5l5y9{
        @extend .Klc797;
        .se5658{
            @include Size( 100%, auto );
        }
        .g4zf86{
            @include Size( 100%, auto );
            @extend .Klc797;
            .e99v68{
                @extend .Wm8G06;
                @extend .vOx602;
                @extend .K0cW96;
                line-height: 26px
            }
            .t77am5{
                @extend .nS533w;
                @extend .Y0u334;
                font-size: var(--css-w411n0);
                line-height: 22px
            }
            .a22gb2{
                @extend .xG0j09;
                @extend .nS533w;
                @extend .cB3332;
                @extend .Q3s2S1;
                @extend .Y0u334;
                line-height: 24px;
                &:hover{
                    @extend .vF5k47;
                }
            }
        }
    }
    .m505cz{
        @extend .Klc797;
        .hl280a{
            @extend .Klc797;
            .a917yq{
                @include Size( 100%, 108px );
                @extend .rP408N;
                box-shadow: var(--css-j71y4n);
                .o840cp{
                    @include Size( 100%, 52px );
                    @extend .kZm977;
                    min-width: 52px;
                    svg{
                        @include Size( 26px, 26px );
                    }
                    img{
                        @include Size( 26px, 26px );
                    }
                }
            }
            .x661n3{
                @extend .xG0j09;
                @extend .vOx602;
                @extend .K0cW96;
            }
        }
        .row .col-md-1{
            @include Size( 10%, auto );
        }
    }
}
/* 
    ----------
*/
.g65z5u{
    @extend .Klc797;
    .e918a8{
        @extend .Klc797;
        .x63112{
            @include Size( 100%, 400px );
            @extend .Klc797;
            .o49df7{
                @extend .vOx602;
                @extend .K0cW96;
                font-size: var(--css-h494sb);
                line-height: 32px;
                strong{
                    @extend .v6EU83;
                    @extend .K0cW96;
                }
            }
            .u04m7q{
                @extend .PlO170;
                @extend .Y0u334;
                line-height: 24px
            }
        }
    }
    .b0e620{
        @extend .Klc797;
        .v8128v{
            @include Size( 100%, auto );
            @extend .Klc797;
            img{
                @include Size( 100%, auto );
                @extend .Klc797;
            }
            video{
                @include Size( 100%, auto );
                @extend .Klc797;
            }
        }
        .z9w60a{
            @extend .Klc797;
            .i745hd{
                @extend .vOx602;
                @extend .K0cW96;
                @extend .i260Up;
            }
            .gi119a{
                @extend .Y0u334;
                @extend .nS533w;
                font-size: var(--css-w411n0);
                line-height: 25px;
            }
            .g30798{
                @extend .nS533w;
                font-size: var(--css-w411n0);
                color: var(--css-g0217c);
                line-height: 27px;
            }
        }
    }
    .zx1987{
        @extend .Klc797;
        .a65254{
            @include Size( 100%, 477px );
            @extend .Klc797;
            .v5t92l{
                @include Size( 100%, 46px );
                @extend .rP408N;
                @extend .kZm977;
                max-width: 46px;
                box-shadow: var(--css-j71y4n);
                img{
                    @include Size( 20px, 20px );
                    @extend .Klc797;
                }
            }
        }
        .k791vc{
            @extend .Klc797;
            .n97qf5{
                @include Size( 100%, 477px );
                @extend .T659Fi;
                @extend .N965Go;
                @extend .F710rg;
                background-image: url( "https://cdn.benozgurtosun.com/v1/corporation/813844.webp" );
            }
        }
        .d3726r{
            @include Size( 100%, 477px );
            @extend .Klc797;
            .t4q297{
                @extend .vOx602;
                @extend .K0cW96;
                font-size: var(--css-t515p5);
                strong{
                    @extend .v6EU83;
                    @extend .K0cW96;
                }
            }
            .o4e46u{
                @extend .Y0u334;
                @extend .nS533w;
                font-size: var(--css-w411n0);
                line-height: 24px
            }
            .t50h35{
                @extend .Klc797;
                .t03rw5{
                    @include Size( 100%, auto );
                    .o091hh{
                        @include Size( 100%, 31px );
                        @extend .rP408N;
                        @extend .ob2A34;
                        @extend .Klc797;
                        @extend .C4v153;
                        .j0z12w{
                            @include Size( 6%, auto );
                            @extend .Y0u334;
                            @extend .xG0j09;
                            min-width: 6%;
                            svg{
                                @include Size( 16px, 16px );
                                margin: -1px 0 0 0;
                                path{
                                    @extend .Vr4196;
                                }
                            }
                        }
                        .z9y50r{
                            @include Size( 94%, auto );
                            @extend .Y0u334;
                            font-size: var(--css-w411n0);
                            min-width: 94%;
                        }
                    }
                }
            }
        }
    }
    .a555ki{
        @extend .Klc797;
        @extend .gZ959C;
        .r4497j{
            @extend .cH8711;
            .q9c6m0{
                @include Size( 100%, auto );
                @extend .gZ959C;
                img{
                    @include Size( 100%, auto );
                }
            }
            .c37dc8{
                @extend .Klc797;
                .w0r597{
                    @extend .bS8S40;
                    @extend .vOx602;
                    @extend .K0cW96;
                }
            }
        }
    }
    .h68k88{
        @extend .Klc797;
        .d8q835{
            @extend .vF5k47;
            @extend .Klc797;
            .y62e43{
                @include Size( 100%, auto );
                @extend .u0Bc59;
                .q892wd{
                    @extend .Wm8G06;
                    @extend .M99v3q;
                    @extend .K0cW96;
                    line-height: 26px
                }
                .q0440q{
                    @extend .Wm8G06;
                    @extend .M99v3q;
                    @extend .K0cW96;
                    @extend .hPP928;
                    line-height: 26px
                }
            }
            .ja605u{
                @extend .Klc797;
                .h33p0j{
                    @include Size( auto, 150px );
                    @extend .gZ959C;
                    @extend .a527eB;
                    margin: -50px 0 0 0;
                    min-width: 150px;
                }
                .z48376{
                    @extend .Klc797;
                    .t53z62{
                        @extend .wI860z;
                        @extend .vOx602;
                        @extend .nS533w;
                        @extend .vF5k47;
                    }
                    .o1m43l{
                        @extend .xG0j09;
                        @extend .nS533w;
                        @extend .Y0u334;
                        @extend .vF5k47;
                        line-height: 20px
                    }
                }
            }
            &:hover .o1m43l{
                @extend .vF5k47;
            }
        }
        .swiper-pagination-bullets{
            @extend .S0u16d;
            @extend .vR05U6;
            @extend .d721Vx;
            @extend .hPP928;
            .swiper-pagination-bullet{
                @include Size( 11px, 11px );
                @extend .wDC543;
                @extend .gZ959C;
                @extend .kZm977;
                @extend .N4g4m6;
                @extend .Klc797;
                margin: 50px 10px 0 0;
                &:nth-child(1):hover{
                    background: var(--css-q526mt);
                }
                &:nth-child(2):hover{
                    background: var(--css-x93o1r);
                }
                &:nth-child(3):hover{
                    background: var(--css-a7g38c);
                }
                &:nth-child(4):hover{
                    background: var(--css-d310zv);
                }
                &:nth-child(5):hover{
                    background: var(--css-z3794l);
                }
                &:hover{
                    @extend .u0Bc59;
                }
            }
            .swiper-pagination-bullet-active{
                background: map-get( $Color, "Black" );
            }
        }
        .swiper .swiper-slide:nth-child(1) .y62e43{
            background: var(--css-q526mt);
        }
        .swiper .swiper-slide:nth-child(2) .y62e43{
            background: var(--css-x93o1r)
        }
        .swiper .swiper-slide:nth-child(2) .q892wd{
            color: var(--css-g33o36)
        }
        .swiper .swiper-slide:nth-child(3) .y62e43{
            background: var(--css-a7g38c);
        }
        .swiper .swiper-slide:nth-child(4) .y62e43{
            background: var(--css-d310zv);
        }
        .swiper .swiper-slide:nth-child(5) .y62e43{
            background: var(--css-z3794l);
        }
        .swiper .swiper-slide:nth-child(5) .q892wd{
            @extend .M99v3q;
        }
        .swiper .swiper-slide:nth-child(6) .y62e43{
            background: var(--css-k23b2o);
        }
        .swiper .swiper-slide:nth-child(7) .y62e43{
            background: var(--css-sr694d);
        }
        .swiper .swiper-slide:nth-child(8) .y62e43{
            background: var(--css-j122d2);
        }
        .swiper .swiper-slide:nth-child(8) .q892wd{
            color: var(--css-g33o36);
        }
        .swiper .swiper-slide:nth-child(9) .y62e43{
            background: var(--css-xe7m81);
        }
        .swiper .swiper-slide:nth-child(10) .y62e43{
            background: var(--css-r2157z);
        }
        .swiper .swiper-slide:nth-child(10) .q892wd{
            color: var(--css-g33o36);
        }
    }
    .e8336u{
        @extend .Klc797;
        .ac1099{
            @extend .Klc797;
            .s3674h{
                @include Size( 100%, auto );
                @extend .rP408N;
                .b39yo6{
                    @include Size( 100%, auto );
                    img{
                        @include Size( 110px, auto );
                    }
                }
            }
        }
    }
    .k84mu0{
        @extend .Klc797;
        .t079x8{
            @include Size( 100%, auto );
            @extend .rP408N;
            box-shadow: var(--css-j71y4n);
            .k96847{
                @extend .Klc797;
                .f062xc{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    img{
                        @include Size( 25px, 25px );
                        @extend .Klc797;
                    }
                }
                .r22m60{
                    @extend .vOx602;
                    @extend .K0cW96;
                    font-size: var(--css-e07660);
                    border-bottom: 1px solid var(--css-m02l09);
                }
                .y8450e{
                    @extend .Au5718;
                    @extend .Y0u334;
                    @extend .xG0j09;
                    li{
                        span{
                            @include Size( 100%, 28px );
                            strong{
                                @extend .Y0u334;
                                @extend .xG0j09;
                                @extend .nS533w;
                            }
                        }
                    }
                }
            }
        }
    }
    .h405q4{
        @extend .Klc797;
        .d540uf{
            @include Size( 100%, auto );
            @extend .rP408N;
            @extend .cH8711;
            box-shadow: var(--css-j71y4n);
            min-height: 618px;
            .d01z5h{
                @include Size( 100%, auto );
                @extend .rP408N;
                .v406f3{
                    @extend .Wm8G06;
                    @extend .vOx602;
                    @extend .K0cW96;
                    line-height: 23px;
                }
                .r74wm6{
                    @extend .Y0u334;
                    @extend .xG0j09;
                    @extend .nS533w;
                    line-height: 20px
                }
                .w432xg{
                    @extend .bS8S40;
                    @extend .vOx602;
                    @extend .K0cW96;
                    &::after{
                        @extend .Wm8G06;
                        content: " ₺";
                    }
                }
                .w67h3x{
                    @include Size( 100%, 44px );
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .ob2A34;
                    @extend .xG0j09;
                    @extend .v6EU83;
                    @extend .nS533w;
                    @extend .gZ959C;
                    &:hover{
                        @extend .M99v3q;
                        @extend .u0Bc59;
                    }
                }
                .r279j3{
                    @include Size( 100%, 44px );
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .ob2A34;
                    @extend .xG0j09;
                    @extend .M99v3q;
                    @extend .nS533w;
                    @extend .u0Bc59;
                    &:hover{
                        @extend .v6EU83;
                        @extend .gZ959C;
                    }
                }
            }
            .u4025u{
                @include Size( 100%, auto );
                @extend .rP408N;
                @extend .GnG350;
                li{
                    label{
                        @include Size( 32px, 32px );
                        @extend .kZm977;
                        min-width: 32px;
                        svg{
                            @include Size( 15px, 15px );
                        }
                    }
                    span{
                        @extend .xG0j09;
                        @extend .vOx602;
                        @extend .nS533w;
                        line-height: 32px
                    }
                }
            }
        }
    }
    .Select{
        @include Size( 100%, auto );
        @extend .Klc797;
        &::before{
            @include Start( auto, -50px );
            @include Size( 100%, 50px );
            @extend .S6pt64;
            @extend .u0Bc59;
            @extend .M99v3q;
            @extend .vC9r15;
            @extend .xG0j09;
            @extend .K0cW96;
            @extend .gBt584;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            content: "3.618 Kişinin Seçimi";
            line-height: 50px;
        }
    }
    .q35322{
        @extend .cH8711;
        .p796s4{
            @extend .Klc797;
            .u61409{
                @include Size( 60%, auto );
                @extend .T659Fi;
            }
            .q76j70{
                @include Size( 40%, 100% );
                @extend .pEO064;
                padding: 80px 64px 80px 0;
                .q20g3c{
                    @extend .vOx602;
                    @extend .K0cW96;
                    font-size: var(--css-t515p5);
                    line-height: 29px;
                }
                .d624lk{
                    @extend .vOx602;
                    @extend .nS533w;
                    font-size: var(--css-e07660);
                    line-height: 24px;
                }
                .d7fv32{
                    @include Size( auto, 45px );
                    @extend .rP408N;
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .xG0j09;
                    @extend .vOx602;
                    border: 1px solid map-get( $Color, "Black" );
                    min-width: 185px;
                    &:hover{
                        @extend .u3I753;
                        @extend .M99v3q;
                    }
                }
            }
        }
        &::before{
            @include Start( 0, 0 );
            @include Size( 100%, calc( 100% - 80px ) );
            @extend .S6pt64;
            @extend .J55M9e;
            @extend .gBt584;
            transform: translate( -50%, 40px );
            box-shadow: var(--css-j71y4n);
        }
    }
    .m505cz{
        @extend .Klc797;
        .hl280a{
            @extend .Klc797;
            .a917yq{
                @include Size( 100%, 108px );
                @extend .rP408N;
                box-shadow: var(--css-j71y4n);
                .o840cp{
                    @include Size( 100%, 52px );
                    @extend .kZm977;
                    min-width: 52px;
                    svg{
                        @include Size( 26px, 26px );
                    }
                    img{
                        @include Size( 26px, 26px );
                    }
                }
            }
            .x661n3{
                @extend .xG0j09;
                @extend .vOx602;
                @extend .K0cW96;
            }
        }
        .row .col-md-1{
            @include Size( 10%, auto );
        }
    }
    .w728x2{
        @extend .Klc797;
        .ck3902{
            @include Size( 100%, auto );
            @extend .rP408N;
            @extend .cH8711;
            @extend .Klc797;
            @extend .C4v153;
            box-shadow: var(--css-j71y4n);
            .q8la01{
                @extend .Klc797;
                .e88a72{
                    @include Size( 100%, 40px );
                    @extend .kZm977;
                    @extend .gZ959C;
                    max-width: 40px;
                    svg{
                        @include Size( 20px, 20px );
                        path{
                            @extend .cvC365;
                        }
                    }
                }
                .k8716o{
                    @extend .vOx602;
                    @extend .K0cW96;
                    font-size: var(--css-e07660);
                    border-bottom: 1px solid var(--css-c34a72);
                    line-height: 21px;
                }
                .t027vd{
                    @extend .xG0j09;
                    @extend .Y0u334;
                    @extend .nS533w;
                    line-height: 22px;
                }
            }
            &::after{
                @include Size( 100%, 48px );
                @include Top( 0, -48px );
                @extend .gZ959C;
                @extend .wDC543;
                @extend .gBt584;
                @extend .pEO064;
                @extend .xG0j09;
                @extend .xD280K;
                content: "0534 920 41 44";
                padding: 0 48px;
                line-height: 48px;
            }
        }
        .n5x508{
            @include Size( 100%, auto );
            @extend .rP408N;
            @extend .cH8711;
            @extend .Klc797;
            @extend .C4v153;
            box-shadow: var(--css-j71y4n);
            .a9680l{
                @extend .Klc797;
                .y347yn{
                    @include Size( 100%, 40px );
                    @extend .kZm977;
                    @extend .gZ959C;
                    max-width: 40px;
                    svg{
                        @include Size( 18px, 18px );
                        path{
                            @extend .cvC365;
                        }
                    }
                }
                .rm263w{
                    @extend .vOx602;
                    @extend .K0cW96;
                    font-size: var(--css-e07660);
                    border-bottom: 1px solid var(--css-c34a72);
                    line-height: 21px;
                }
                .t456c4{
                    @extend .xG0j09;
                    @extend .Y0u334;
                    @extend .nS533w;
                    line-height: 22px;
                }
            }
            &::after{
                @include Size( 100%, 48px );
                @include Top( 0, -48px );
                @extend .gZ959C;
                @extend .wDC543;
                @extend .gBt584;
                @extend .pEO064;
                @extend .xG0j09;
                @extend .xD280K;
                content: "info@benozgurtosun.com";
                padding: 0 48px;
                line-height: 48px;
            }
        }
        .r87933{
            @include Size( 100%, auto );
            @extend .rP408N;
            box-shadow: var(--css-j71y4n);
            .c68146{
                @extend .vOx602;
                @extend .nS533w;
                @extend .PlO170;
            }
        }
    }
    .f63v0g{
        @include Size( 100%, auto );
        @extend .rP408N;
        box-shadow: var(--css-j71y4n);
        .j396h2{
            @extend .Klc797;
            .q87a3p{
                @extend .Klc797;
                .b384t5{
                    @extend .Klc797;
                    .c997p0{
                        @extend .vOx602;
                        @extend .nS533w;
                        @extend .xG0j09;
                        line-height: 25px;
                        &::after{
                            @extend .wDC543;
                            @extend .iI0402;
                            @extend .v6EU83;
                            content: "*";
                            padding: 0 0 0 4px;
                        }
                    }
                    .ka270b{
                        @extend .vOx602;
                        @extend .nS533w;
                        @extend .xG0j09;
                        line-height: 25px;
                    }
                    .a21z73{
                        @include Size( 100%, 45px );
                        @extend .gZ959C;
                        @extend .ob2A34;
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .vOx602;
                        @extend .xG0j09;
                    }
                    .uk6620{
                        @include Size( 100%, 80px );
                        @extend .gZ959C;
                        @extend .ob2A34;
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .vOx602;
                        @extend .xG0j09;
                    }
                    .w966mo{
                        @include Size( 100%, 100px );
                        @extend .gZ959C;
                        @extend .ob2A34;
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .vOx602;
                        @extend .xG0j09;
                    }
                    .z160i4{
                        @include Size( 100%, 48px );
                        @extend .u0Bc59;
                        @extend .M99v3q;
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .K0cW96;
                        font-size: var(--css-w411n0);
                        border: 1px solid map-get( $Color, "Default" );
                        &:hover{
                            @extend .rP408N;
                            @extend .v6EU83;
                        }
                    }
                }
                .i471vp{
                    @extend .Klc797;
                    .w70883{
                        @extend .vOx602;
                        @extend .nS533w;
                        font-size: var(--css-w411n0);
                    }
                }
            }
        }
        .b854v7{
            @extend .vOx602;
            @extend .nS533w;
            @extend .PlO170;
        }
    }
    .j8w7l9{
        @extend .Klc797;
        .s84j9p{
            @extend .Klc797;
            .w39m22{
                @extend .Klc797;
                img{
                    @include Size( 100%, auto );
                }
            }
        }
        .n98kk4{
            @include Size( 100%, 500px );
            @extend .T659Fi;
            @extend .F710rg;
            @extend .N965Go;
        }
        .y76v0s{
            @include Size( 100%, 500px );
            .p5o2a6{
                @extend .vOx602;
                @extend .K0cW96;
                font-size: var(--css-s823ly);
            }
            .s194hd{
                @extend .vOx602;
                @extend .nS533w;
                @extend .PlO170;
                @extend .yyO233;
                line-height: 24.5px
            }
        }
    }
    .p3287u{
        @extend .Klc797;
        .z8475v{
            @extend .Klc797;
            .d630m2{
                @include Size( 100%, 526px );
                @extend .T659Fi;
                @extend .N965Go;
                @extend .J2l421;
                background-image: url( "https://cdn.benozgurtosun.com/v1/corporation/813844.webp" )
            }
        }
        .j71bv3{
            @include Size( 100%, auto );
            @extend .Klc797;
            .i63421{
                @include Size( 100%, auto );
                thead{
                    tr th{
                        @include Size( auto, 50px );
                        @extend .u0Bc59;
                        padding: 0 0 0 10px;
                        span{
                            @extend .iI0402;
                            @extend .K0cW96;
                            @extend .M99v3q;
                            line-height: 50px;
                        }
                        &:nth-child(1){
                            padding: 0 0 0 25px;
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            @include Size( auto, 50px );
                            @extend .gZ959C;
                            padding: 0 10px;
                            label{
                                @extend .iI0402;
                                @extend .nS533w;
                                @extend .vOx602;
                                line-height: 50px;
                            }
                            a{
                                @extend .iI0402;
                                @extend .nS533w;
                                @extend .vOx602;
                                line-height: 50px;
                            }
                        }
                        &:nth-child(even) td{
                            @extend .rP408N;
                        }
                        &:nth-child(1) td:nth-child(1){
                            padding: 0 0 0 25px;
                        }
                        &:nth-child(2) td:nth-child(1){
                            padding: 0 0 0 25px;
                        }
                        &:nth-child(3) td:nth-child(1){
                            padding: 0 0 0 25px;
                        }
                        &:nth-child(4) td:nth-child(1){
                            padding: 0 0 0 25px;
                        }
                        &:nth-child(5) td:nth-child(1){
                            padding: 0 0 0 25px;
                        }
                        &:nth-child(6) td:nth-child(1){
                            padding: 0 0 0 25px;
                        }
                    }
                }
            }
        }
    }
    .y0422f{
        @extend .Klc797;
        .z493ea{
            @include Size( 100%, auto );
            @extend .GnG350;
            max-width: 512px;
            li{
                a{
                    @include Size( 100%, 60px );
                    @extend .rP408N;
                    @extend .vOx602;
                    @extend .K0cW96;
                    @extend .vF5k47;
                    font-size: var(--css-e07660);
                    border-bottom: 1px solid var(--css-c34a72);
                    line-height: 60px;
                    &::after{
                        @extend .S6pt64;
                        content: url( "./Media/Icon/009.svg" );
                        margin: 3px 0 0 0;
                        line-height: 1
                    }
                }
            }
        }
        .offset-md-4{
            margin-left: 30%;
        }
    }
    .v7672m{
        @extend .Klc797;
        .p021ti{
            @include Size( 100%, 624px );
            .e59s27{
                @extend .vOx602;
                @extend .K0cW96;
                font-size: var(--css-t515p5);
                line-height: 30px
            }
            .i0k43i{
                @extend .nS533w;
                @extend .Y0u334;
                font-size: var(--css-e07660);
                line-height: 24px
            }
        }
        .i700xb{
            @include Size( 100%, 100% );
            .z2499o{
                @extend .vOx602;
                @extend .K0cW96;
                @extend .i260Up;
                line-height: 29px
            }
            .m1364c{
                @extend .nS533w;
                @extend .Y0u334;
                font-size: var(--css-e07660);
                line-height: 24px
            }
        }
        .t883f0{
            @extend .Klc797;
            .m798x7{
                @include Size( 100%, 624px );
                @extend .T659Fi;
                @extend .N965Go;
                @extend .J2l421;
                @extend .gZ959C;
            }
            .f43j54{
                @include Size( 100%, auto );
                @extend .Klc797;
                img{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                }
            }
        }
    }
    .sr551s{
        @extend .Klc797;
        .v408hk{
            @include Size( 100%, auto );
            img{
                @include Size( 100%, auto );
                @extend .Klc797;
            }
        }
    }
}
/* 
    ----------
*/
.g65z5u{
    @extend .Klc797;
    .e918a8{
        @extend .Klc797;
        .x4i0g2{
            @include Size( 100%, 500px );
            @extend .Klc797;
            .m97p51{
                @extend .vOx602;
                @extend .K0cW96;
                font-size: var(--css-u73c17);
                strong{
                    @extend .v6EU83;
                    @extend .K0cW96;
                }
            }
            .pea034{
                @extend .PlO170;
                color: var(--css-g0217c);
                line-height: 24px
            }
        }
    }
}
/* 
    ----------
*/
.r686wp{
    @extend .Klc797;
    .hv89h7{
        @include Size( 100%, 85px );
        background: var(--css-j980f4);
        border-bottom: 1px solid var(--css-n436ik);
        .s955un{
            @include Size( 100%, 85px );
            @extend .GnG350;
            li{
                a{
                    @include Size( 100%, 44px );
                    min-width: 44px;
                    svg{
                        @include Size( 18px, 18px );
                        @extend .Klc797;
                        path{
                            @extend .Klc797;
                            fill: var(--css-w859ad);
                        }
                    }
                    &:hover svg path{
                        @extend .f8T6I8;
                    }
                }
                &:nth-child(2) a svg{
                    @include Size( 16px, 16px );
                }
                &:nth-child(3) a svg{
                    @include Size( 16px, 16px );
                }
                &:nth-child(4) a svg{
                    @include Size( 19px, 19px );
                }
                &:nth-child(5) a svg{
                    @include Size( 18px, 18px );
                }
            }
        }
        .r766cl{
            @include Size( 100%, 85px );
            @extend .GnG350;
            li{
                a{
                    @include Size( 100%, 44px );
                    min-width: 44px;
                    svg{
                        @include Size( 20px, 20px );
                        path{
                            @extend .Klc797;
                            fill: var(--css-w859ad);
                        }
                    }
                    &:hover svg path{
                        @extend .f8T6I8;
                    }
                }
                &:nth-child(2) a svg{
                    @include Size( 17px, 17px );
                    margin: 3px 0 0 0;
                }
            }
        }
    }
    .g26s0e{
        @include Size( 100%, auto );
        background: var(--css-j980f4);
        .n90bv9{
            @extend .Klc797;
            .a407p5{
                @extend .xG0j09;
                @extend .M99v3q;
                @extend .K0cW96;
            }
            .r3Bx93{
                @extend .GnG350;
                li{
                    a{
                        @extend .vF5k47;
                        @extend .Klc797;
                        @extend .a527eB;
                        font-size: var(--css-s4j78n);
                        color: var(--css-w859ad);
                        margin: 0 0 8px 0;
                        line-height: 19px;
                        &:hover{
                            @extend .M99v3q;
                        }
                    }
                }
            }
        }
        .r46f3s{
            @extend .Klc797;
            .w84z88{
                @extend .xG0j09;
                @extend .M99v3q;
                @extend .K0cW96;
            }
            .w8M19n{
                @extend .Klc797;
                font-size: var(--css-s4j78n);
                color: var(--css-w859ad);
                line-height: 22.5px;
                &::before{
                    content: "Eğer herhangi bir konuda yardıma ihtiyacınız varsa, lütfen bize bildirin. Size yardımcı olmaktan memnuniyet duyarız."
                }
            }
            .q317to{
                @extend .Klc797;
                font-size: var(--css-s4j78n);
                color: var(--css-w859ad);
                line-height: 22.5px;
                &::before{
                    content: "Size yardımcı olabileceğim bir konu var mı? İletişim kurmak isterseniz, bizimle hemen irtibata geçebilirsiniz."
                }
            }
            .n959k8{
                @extend .Klc797;
                @extend .iI0402;
                color: var(--css-w859ad);
                line-height: 23px;
                svg{
                    @include Size( 18px, 18px );
                    margin: -3px 10px 0 0;
                    path{
                        fill: var(--css-w859ad);
                    }
                }
                strong{
                    @extend .nS533w;
                }
            }
            .m176ye{
                @extend .Klc797;
                font-size: var(--css-s4j78n);
                color: var(--css-w859ad);
                line-height: 23px;
                svg{
                    @include Size( 17px, 17px );
                    margin: -2px 10px 0 0;
                    path{
                        fill: var(--css-w859ad);
                    }
                }
                strong{
                    @extend .nS533w;
                }
            }
        }
    }
    .w158k4{
        background: var(--css-j980f4);
        .j05q38{
            @include Size( 100%, 70px );
            border-top: 1px solid var(--css-n436ik);
            .z2596n{
                @include Size( 100%, 100% );
                @extend .Klc797;
                .f07622{
                    @extend .xG0j09;
                    @extend .nS533w;
                    color: var(--css-w859ad);
                    line-height: 70px;
                }
            }
            .z7h704{
                @include Size( 100%, 100% );
                @extend .Klc797;
                .y43to7{
                    @include Size( 100%, 100% );
                    @extend .GnG350;
                    .e76r8a{
                        @extend .xG0j09;
                        @extend .nS533w;
                        color: var(--css-w859ad);
                        line-height: 70px;
                    }
                    .z8244c{
                        @extend .xG0j09;
                        @extend .nS533w;
                        color: var(--css-w859ad);
                        line-height: 70px;
                    }
                }
            }
        }
    }
    .e59co7{
        @include Size( 100%, auto );
        @extend .cH8711;
        @extend .hPP928;
        .q41b5t{
            @include Size( 100%, auto );
            @extend .Klc797;
            video{
                @include Size( 100%, auto );
                @extend .Klc797;
                @extend .z9X51G;
            }
        }
        &::after{
            @include Size( 100%, 150px );
            @include Top( 0, 0 );
            @extend .J55M9e;
            @extend .wDC543;
            @extend .gBt584;
            background: var(--css-j980f4);
            border-bottom: 1px solid var(--css-n436ik);
            z-index: -1;
        }
    }
}
/* 
    ----------
*/
@keyframes Ring {
    from{
        transform: rotate3d( 0, 0, 1, 0deg );
    }
    20%, 32%, 44%, 56%, 68%{
        transform: rotate3d( 0, 0, 1, 0deg );
    }
    23%, 35%, 47%, 59%, 71%{
        transform: rotate3d( 0, 0, 1, 15deg );
    }
    26%, 38%, 50%, 62%, 74%{
        transform: rotate3d( 0, 0, 1, 0deg );
    }
    29%, 41%, 53%, 65%, 77%{
        transform: rotate3d( 0, 0, 1, -15deg );
    }
    80% {
        transform: rotate3d( 0, 0, 1, 0deg );
    }
}