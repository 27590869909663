@media (min-width: 1920px) and (max-width: 1925px){
    .f55237::before{ width: 508px!important }
}
@media (min-width: 1366px) and (max-width: 1367px){
    .rP69J9 li:nth-child(1), .k66z9t br, .s12a3e br, .o4e46u br, .w000w8 br, .qo3320 br, .i31t7q br{ display: none!important }
    .v976lh, .m1364c{ display: -webkit-box }
    .v976lh, .m1364c{ -webkit-box-orient: vertical }
    .k66z9t, .s12a3e, .rP69J9 li ul li:first-child, .vp0y83, .n7193f, .b47q67{ display: block!important }
    .y8450e li span strong, .y8450e li span, .e74i23 img, .b39yo6 img, .n98kk4 iframe, .z82455 .o01a97 img, .p72qx6 img, .h33p0j img, .n98kk4 iframe, .n97qf5, .w728x2 .container .row .col-md-6, #main_Internet .i31t7q::before, #main_Success .qo3320::before, #main_Internet .qo3320::before, #main_Middle .qo3320::before{ width: 100%!important }
    .j0r112 .container .row .col-md-3{ width: 23%!important }
    .k84mu0 .container .row .col-md-3, .h405q4 .container .row .col-md-3, .v7672m .container .row .col-md-12 .col-md-3{ width: 25%!important }
    .v7672m .container .row .col-md-12 .col-md-5{ width: 38%!important }
    .v7672m .container .row .col-md-12 .col-md-4{ width: 37%!important }
    .p3287u .container .row .col-md-5, .j8w7l9 .container .row .col-md-6{ width: 40%!important }
    .w542au .row .col-md-5{ width: 47%!important }
    #React_A01NB8 .row .col-md-6, #React_B694LU .row .col-md-6, .w728x2 .container .row .col-md-3, .q76j70, .u61409, .j0r112 .container .row .col-md-3 .row .col-md-6{ width: 50%!important }
    .w542au .row .col-md-7{ width: 53%!important }
    .j0r112 .container .row .col-md-6{ width: 54%!important }
    .p3287u .container .row .col-md-7, .j8w7l9 .container .row .col-md-6:last-child{ width: 60%!important }
    .y15u5Q li span i svg{ width: 8px!important }
    .j0z12w svg{ width: 15px!important }
    .y15u5Q li span i{ width: 16px!important }
    .v5t92l img{ width: 17px!important }
    .r89a95 img, .m24ho4 img{ width: 19px!important }
    .o840cp img{ width: 24px!important }
    .i1888e{ min-width: 42px!important }
    .Up955K img{ width: 120px!important }
    .f55237::before{ width: 283px!important }
    .r89a95{ max-width: 42px!important }
    .p72qx6{ max-width: 92px }
    .h33p0j{ min-width: 125px!important }
    .u4025u li label{ max-width: 30px!important }
    .v5t92l{ max-width: 44px!important }
    .h119in .j0r112 .p2769m .b98w7p .p72qx6{ max-width: 92px!important }
    .h33p0j{ max-width: 135px!important }
    #main_Internet .i31t7q{ max-width: 440px!important }
    #main_Success .qo3320{ max-width: 441px!important }
    #main_Internet .qo3320{ max-width: 452px!important }
    .qo3320{ max-width: 459px!important }
    .b47q67{ max-width: 462px }
    .n7193f{ max-width: 474px }
    .vp0y83{ max-width: 482px }
    .c741h7, .e74i23, .e74i23 img, .o840cp, .h33p0j, .p72qx6, .p72qx6 img, .h33p0j img, .z82455 .o01a97 img, .n98kk4, .n98kk4 iframe, .y76v0s, .a65254, #main_Internet{ height: auto!important }
    .p2769m, .a419o0, .o840cp, .n98kk4 iframe, .n97qf5, .d3726r, .po7670{ height: 100%!important }
    .c741h7, .po7670{ min-height: 409px!important }
    .o1m43l, .v976lh, .y8450e li span strong, .y8450e li span, .u4025u li span, .k791vc, .g30798, .m1364c, .z2499o, .t456c4, .t027vd{ overflow: hidden }
    .o1m43l, .y8450e li span strong, .y8450e li span, .u4025u li span, .g30798, .z2499o, .t456c4, .t027vd{ white-space: nowrap }
    .o1m43l, .y8450e li span strong, .y8450e li span, .u4025u li span, .g30798, .z2499o, .t456c4, .t027vd{ text-overflow: ellipsis }
    .k791vc, .n98kk4{ position: relative!important }
    .n97qf5, .n98kk4 iframe{ position: absolute!important }
    .a65254, .c741h7{ align-items: start!important }
    .i700xb{ justify-content: start!important }
    .v976lh, .m1364c{ -webkit-line-clamp: 3 }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320{ line-height: 0!important }
    .m6083e, #main_Internet .c28g97, #main_Success .c28g97, #main_Middle .c28g97{ line-height: 1!important }
    .v976lh, .h119in .j0r112 .h68k88 .d8q835 .ja605u .z48376 .o1m43l{ line-height: 21px!important }
    #main_Internet .i31t7q::before, #main_Success .qo3320::before, #main_Internet .qo3320::before, .o4e46u, .m1364c, .s194hd, .u04m7q, .n7193f, .b47q67, .c28g97, .i31t7q{ line-height: 23px!important }
    .qo3320, #main_Middle .qo3320::before, .w000w8, .d624lk, .vp0y83, .s12a3e{ line-height: 24px!important }
    .g30798{ line-height: 25px!important }
    .z2499o{ line-height: 27px!important }
    .q20g3c{ line-height: 28px!important }
    .u4025u li span{ line-height: 30px!important }
    .rP69J9 li ul li:first-child a{ line-height: 48px!important }
    .rP69J9 li:first-child a{ line-height: 70px!important }
    .k791vc{ float: none!important }
    #main_Internet .i31t7q::before, #main_Success .qo3320::before, #main_Internet .qo3320::before, #main_Middle .qo3320::before{ float: left!important }
    #main_Internet .i31t7q::before, #main_Success .qo3320::before, #main_Internet .qo3320::before, #main_Middle .qo3320::before{ color: #3E3F41!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320{ color: #FFFFFF!important }
    .y15u5Q li span i svg, #main_Internet .z93q7k, .j0z12w svg{ margin: 0!important }
    .u4025u li span{ margin-left: 0!important }
    .po7670{ margin-top: 0px!important }
    .d624lk{ margin: 4px 0 0 0!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320{ margin: 8px 0 0 0!important }
    .k66z9t, .t50h35{ margin-top: 8px!important }
    .d7fv32, .e745l2{ margin: 12px 0 0 0!important }
    .br6312 h3{ margin: 12px 0 4px 0!important }
    .y15u5Q li:first-child span{ margin-top: 16px!important }
    .b384t5{ margin: 16px 0 0 0!important }
    .hl280a, .z93q7k{ margin-top: 24px!important }
    .c741h7, .h4s606, .o720ha, .h68k88, .b98w7p{ margin-top: 35px!important }
    .k51l81, .b584xj{ margin-top: 12px!important }
    .w000w8{ margin-top: 10px!important }
    .Up955K{ margin-top: 48px!important }
    .h119in .j0r112 .h68k88 .d8q835 .ja605u .h33p0j{ margin: -35px 0 0 0!important }
    .j8w7l9 .n98kk4, .y76v0s, .Up955K, .z9w60a, .a65254, .k791vc, .d3726r{ margin-top: 36px!important }
    .g65z5u .y0422f .offset-md-4{ margin-left: 27.5%!important }
    .rP69J9 li a, .t77am5{ padding: 0!important }
    .h119in .e8336u .ac1099 .s3674h .b39yo6, .g65z5u .e8336u .ac1099 .s3674h .b39yo6{ padding: 0 4px!important }
    .rP69J9 li a{ padding: 0 14px!important }
    .y62e43{ padding: 48px 32px!important }
    .q87a3p{ padding: 20px 0 0 0!important }
    .n98kk4{ padding-bottom: 56.25%!important }
    .k791vc{ padding-bottom: 414px!important }
    .q76j70{ padding-right: 32px!important }
    .z48376{ padding-left: 24px!important }
    .c68146{ padding: 58px 0 0 0!important }
    .o1m43l{ font-size: 12.4px!important }
    .u4025u li span{ font-size: 12.5px!important }
    .i31t7q, .g30798{ font-size: 13px!important }
    #React_27E0XH, .v976lh{ font-size: 13.4px!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320, .w000w8, .o4e46u, .z9y50r, .s194hd, .m1364c, .u04m7q, .vp0y83, .n7193f, .b47q67, .t53z62, .qo3320, .y15u5Q li span strong{ font-size: 13.5px!important }
    .k66z9t{ font-size: 13.6px!important }
    .s12a3e{ font-size: 13.7px!important }
    .m1364c{ font-size: 13.8px!important }
    .d624lk{ font-size: 14px!important }
    .q892wd, .br6312 h3{ font-size: 15px!important }
    .w0r597{ font-size: 16px!important }
    .z2499o, .i745hd{ font-size: 18px!important }
    .t4q297{ font-size: 19.6px!important }
    .q20g3c{ font-size: 20px!important }
    .c28g97, #main_Middle .c28g97, .p5o2a6{ font-size: 21px!important }
    .q35h02{ font-size: 21.8px!important }
    .m6083e{ font-size: 22px!important }
    .o49df7{ font-size: 23px!important }
    .r89a95 img, .m24ho4 img{ height: 19px!important }
    .f55237, .f55237::before, .c694qt, .c37034{ height: 70px!important }
    .r89a95{ height: 42px!important }
    .y15u5Q li span{ height: 13px!important }
    .y15u5Q li span i{ height: 16px!important }
    .y15u5Q li span i svg{ height: 8px!important }
    .z93q7k{ height: 200px!important }
    .i1888e{ height: 42px!important }
    .a917yq{ height: 90px!important }
    .o840cp img{ height: 24px!important }
    .x63112{ height: 350px!important }
    .Up955K img{ height: 120px!important }
    .v5t92l{ height: 44px!important }
    .v5t92l img{ height: 17px!important }
    .j0z12w svg{ height: 15px!important }
    .o091hh{ height: 28px!important }
    .u4025u li label{ height: 30px!important }
    .n98kk4{ height: 0!important }
    .n98kk4 iframe{ top: 0 }
    .n98kk4 iframe{ left: 0 }
    #main_Internet .z93q7k{ height: 275px!important }
    #main_Middle .z93q7k{ height: 250px!important }
    #main_Internet .i31t7q::before{ content: "Benimle çalışan işletmelere değer katan bu markalar, yeni işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar." }
    #main_Middle .qo3320::before{ content: "Bugün 7 Bölge'de, 68 Şehir'de, 5.000+ İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım." }
    #main_Internet .qo3320::before{ content: "Kurumsal web tasarım ile müşteri tabanınızı büyüterek online varlığınızı güçlendirin ve müşteri kitlenizi genişletin." }
    #main_Success .qo3320::before{ content: "İşletmenizin çevrimiçi etkisini artırın ve müşteri kitlenizi büyütmek için profesyonel bir web tasarım ile sınırları zorlayın." }
}
@media (min-width: 1280px) and (max-width: 1281px){
    .rP69J9 li:nth-child(1), .o4e46u br, .p3287u .container .row .col-md-5, .s12a3e br, .w000w8 br, .k66z9t br{ display: none }
    .vp0y83, .n7193f, .b47q67, .rP69J9 li ul li:first-child, .s12a3e{ display: block!important }
    .v976lh, .m1364c{ display: -webkit-box }
    .e74i23 img, .w728x2 .container .row .col-md-6, .n97qf5, .z82455 .o01a97 img, .n98kk4 iframe, .p3287u .container .row .col-md-7, .b39yo6 img, .p72qx6 img, .h33p0j img, .n98kk4 iframe, .y8450e li span strong, .y8450e li span, #main_Internet .i31t7q::before, #main_Success .qo3320::before, #main_Internet .qo3320::before, #main_Middle .qo3320::before{ width: 100%!important }
    .j8w7l9 .container .row .col-md-6:last-child{ width: 65%!important }
    .j0r112 .container .row .col-md-6{ width: 54%!important }
    .w542au .row .col-md-7{ width: 53%!important }
    .j0r112 .container .row .col-md-3 .row .col-md-6, #React_B694LU .row .col-md-6, #React_A01NB8 .row .col-md-6, .w728x2 .container .row .col-md-3, .u61409, .q76j70{ width: 50%!important }
    .w542au .row .col-md-5{ width: 47%!important }
    .v7672m .container .row .col-md-12 .col-md-4{ width: 38%!important }
    .v7672m .container .row .col-md-12 .col-md-5{ width: 37%!important }
    .j8w7l9 .container .row .col-md-6{ width: 35%!important }
    .j0r112 .container .row .col-md-3{ width: 23%!important }
    .v7672m .container .row .col-md-12 .col-md-3, .k84mu0 .container .row .col-md-3, .h405q4 .container .row .col-md-3{ width: 25%!important }
    .z2499o{ width: 100%!important }
    .f55237::before{ width: 240px!important }
    .Up955K img{ width: 120px!important }
    .o840cp img{ width: 23px!important }
    .r89a95 img, .m24ho4 img{ width: 18px!important }
    .v5t92l img{ width: 17px!important }
    .y15u5Q li span i{ width: 16px!important }
    .j0z12w svg{ width: 15px!important }
    .y15u5Q li span i svg{ width: 8px!important }
    .u4025u li label{ max-width: 30px!important }
    .r89a95{ max-width: 42px!important }
    .v5t92l{ max-width: 44px!important }
    .p72qx6{ max-width: 96px }
    .h33p0j{ max-width: 150px!important }
    .x5e769{ max-width: 419px!important }
    #main_Internet .qo3320{ max-width: 431px!important }
    #main_Internet .i31t7q{ max-width: 448px!important }
    #main_Success .qo3320, .b47q67{ max-width: 450px!important }
    #main_Middle .qo3320{ max-width: 459px!important }
    #main_Internet .qo3320, .n7193f{ max-width: 461px!important }
    .vp0y83{ max-width: 468px }
    #React_27E0XH{ max-width: 493px!important }
    .i1888e{ min-width: 42px!important }
    .o840cp{ min-width: 100%!important }
    .h33p0j{ min-width: 125px!important }
    #main_Internet, .a65254, .z82455 .o01a97 img, .n98kk4 iframe, .y76v0s, .n98kk4, .o840cp, .h33p0j, .p72qx6 img, .h33p0j img, .p72qx6, .e74i23 img, .c741h7, .e74i23, #main_Success{ height: auto!important }
    .po7670, .n98kk4 iframe, .d3726r, .n97qf5, .a419o0, .p2769m{ height: 100%!important }
    .n98kk4{ height: 0!important }
    .y15u5Q li span i svg{ height: 8px!important }
    .y15u5Q li span{ height: 13px!important }
    .j0z12w svg{ height: 15px!important }
    .y15u5Q li span i{ height: 16px!important }
    .v5t92l img{ height: 17px!important }
    .r89a95 img, .m24ho4 img{ height: 18px!important }
    .o840cp img{ height: 23px!important }
    .o091hh{ height: 28px!important }
    .u4025u li label{ height: 30px!important }
    .r89a95{ height: 42px!important }
    .i1888e, .v5t92l{ height: 44px!important }
    .f55237, .f55237::before, .c694qt, .c37034{ height: 70px!important }
    .a917yq{ height: 90px!important }
    .Up955K img{ height: 120px!important }
    .z93q7k{ height: 300px!important }
    #main_Middle .z93q7k{ height: 250px!important }
    #main_Internet .z93q7k{ height: 275px!important }
    .x63112{ height: 350px!important }
    .c741h7, .po7670{ min-height: 409px!important }
    .y8450e li span strong, .y8450e li span, .u4025u li span, .k791vc, .g30798, .z2499o, .m1364c, .v976lh, .o1m43l, .m8m73h, .t456c4, .t027vd, .z2499o{ overflow: hidden }
    .y8450e li span strong, .y8450e li span, .u4025u li span, .g30798, .z2499o, .o1m43l, .m8m73h, .t456c4, .t027vd, .z2499o{ white-space: nowrap }
    .m8m73h, .y8450e li span strong, .y8450e li span, .u4025u li span, .g30798, .z2499o, .o1m43l, .t456c4, .t027vd, .z2499o{ text-overflow: ellipsis }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320{ line-height: 0!important }
    .m6083e, #main_Internet .c28g97, #main_Success .c28g97, #main_Middle .c28g97{ line-height: 1!important }
    .v976lh{ line-height: 21px!important }
    .x5e769, .e99v68{ line-height: 22px!important }
    .i31t7q, .s194hd, .vp0y83, .n7193f, .b47q67{ line-height: 22.5px!important }
    .s12a3e, .o4e46u, .c28g97, .qo3320, #main_Internet .i31t7q::before, #main_Success .qo3320::before, #main_Internet .qo3320::before, .u04m7q{ line-height: 23px!important }
    .w000w8, .d624lk, #main_Middle .qo3320::before, .m1364c{ line-height: 24px!important }
    .g30798{ line-height: 25px!important }
    .z2499o{ line-height: 27px!important }
    .q20g3c{ line-height: 28px!important }
    .u4025u li span{ line-height: 30px!important }
    .rP69J9 li ul li:first-child a{ line-height: 48px!important }
    .rP69J9 li:first-child a{ line-height: 70px!important }
    .n97qf5, .n98kk4 iframe{ position: absolute!important }
    .k791vc, .n98kk4{ position: relative!important }
    .c741h7, .a65254{ align-items: start!important }
    .i700xb{ justify-content: start!important }
    .v976lh, .m1364c{ -webkit-box-orient: vertical }
    .v976lh, .m1364c{ -webkit-line-clamp: 3 }
    .k791vc{ float: none!important }
    #main_Internet .i31t7q::before, #main_Success .qo3320::before, #main_Internet .qo3320::before, #main_Middle .qo3320::before{ float: left }
    #main_Internet .i31t7q::before, #main_Success .qo3320::before, #main_Internet .qo3320::before, #main_Middle .qo3320::before{ color: #3E3F41!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320{ color: #FFFFFF!important }
    .u4025u li span{ font-size: 12.5px!important }
    .g30798, .x661n3{ font-size: 13px!important }
    #React_27E0XH{ font-size: 13.3px!important }
    .v976lh, .qo3320{ font-size: 13.4px!important }
    .m8m73h, .o4e46u, .z9y50r, .s194hd, .u04m7q, .vp0y83, .n7193f, .b47q67, .i31t7q, .x5e769, .y15u5Q li span strong, .w000w8, #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320{ font-size: 13.5px!important }
    .k66z9t{ font-size: 13.6px!important }
    .s12a3e{ font-size: 13.7px!important }
    .d624lk, .t53z62{ font-size: 14px!important }
    .m1364c{ font-size: 14.2px!important }
    .br6312 h3, .q892wd, .h119in .h5l5y9 .g4zf86 .e99v68{ font-size: 15px!important }
    .w0r597{ font-size: 16px!important }
    .i745hd, .z2499o{ font-size: 18px!important }
    .o50h0d{ font-size: 19px!important }
    .t4q297{ font-size: 19.6px!important }
    .q20g3c{ font-size: 20px!important }
    #main_Middle .c28g97, .p5o2a6{ font-size: 21px!important }
    .c28g97{ font-size: 21.4px!important }
    .q35h02{ font-size: 21.8px!important }
    .m6083e{ font-size: 22px!important }
    .o49df7{ font-size: 23px!important }
    .y15u5Q li span i svg, #main_Internet .z93q7k, .j0z12w svg{ margin: 0!important }
    .z93q7k{ margin-top: 0!important }
    .u4025u li span{ margin-left: 0!important }
    .d624lk{ margin: 4px 0 0 0!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320{ margin: 8px 0 0 0!important }
    .k66z9t, .t50h35{ margin-top: 8px!important }
    .w000w8{ margin-top: 10px!important }
    .k51l81, .b584xj{ margin-top: 12px!important }
    .d7fv32, .e745l2, .h119in .h5l5y9 .g4zf86 .e99v68{ margin: 12px 0 0 0!important }
    .br6312 h3{ margin: 12px 0 4px 0!important }
    .y15u5Q li:first-child span{ margin-top: 15px!important }
    .b384t5, .x661n3{ margin: 16px 0 0 0!important }
    .hl280a{ margin-top: 24px!important }
    .h68k88, .b98w7p, .po7670, .c741h7{ margin-top: 35px!important }
    .j8w7l9 .n98kk4, .y76v0s, .Up955K, .z9w60a, .a65254, .k791vc, .d3726r{ margin-top: 36px!important }
    .o720ha{ margin-top: 42px!important }
    .r87933{ margin: 48px 0 0 0!important }
    .g65z5u .y0422f .offset-md-4{ margin-left: 27.5%!important }
    .rP69J9 li a, .t77am5{ padding: 0!important }
    .rP69J9 li a{ padding: 0 14px!important }
    .z48376{ padding-top: 0!important }
    .r87933{ padding: 48px 36px!important }
    .n98kk4{ padding-bottom: 56.25%!important }
    .q87a3p{ padding: 20px 0 0 0!important }
    .k791vc{ padding-bottom: 414px!important }
    .q76j70{ padding-right: 32px!important }
    .y62e43{ padding: 48px 42px!important }
    .z48376{ padding-left: 24px!important }
    .n98kk4 iframe{ top: 0 }
    .n98kk4 iframe{ left: 0 }
    #main_Internet .i31t7q::before{ content: "Benimle çalışan işletmelere değer katan bu markalar küçük işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar." }
    #main_Success .qo3320::before{ content: "İşletmeniz'in online varlığını güçlendirin, müşteri kitlenizi büyütmek için profesyonel bir web tasarım ile sınırları zorlayın." }
    #main_Middle .qo3320::before{ content: "Bugün 7 Bölge'de, 68 Şehir'de, 5.000+ İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım." }
    #main_Internet .qo3320::before{ content: "Profesyonel web tasarım ile müşteri tabanınızı büyüterek dijital varlığınızı güçlendirin ve müşteri kitlenizi genişletin." }
}
@media (min-width: 992px) and (max-width: 1279px){
    .rP69J9 li:nth-child(3), .w000w8 br, .h405q4 .container .row .col-md-3:last-child, .o4e46u br, .zx1987 .container .row .col-md-1:nth-child(3), .p3287u .container .row .col-md-5, .x35f74 .container .row .col-md-1:nth-child(3), .b584xj{ display: none!important }
    .k66z9t, .b47q67, .n7193f{ display: block!important }
    .v976lh, .m1364c, .t77am5{ display: -webkit-box }
    .e74i23 img, .p72qx6 img, .h33p0j img, #main_Internet .i31t7q::before, #main_Success .qo3320::before, #main_Internet .qo3320::before, #main_Middle .qo3320::before, .e745l2, .n98kk4 iframe, .y8450e li span, .w728x2 .container .row .col-md-6, .n97qf5, .z82455 .o01a97 img, .n98kk4 iframe, .p3287u .container .row .col-md-7, .se5658 img, .b39yo6 img{ width: 100%!important }
    .j8w7l9 .container .row .col-md-6:last-child{ width: 65%!important }
    .f55237 .container .row .col-md-8{ width: 61.66666667%!important }
    .j0r112 .container .row .col-md-6{ width: 60%!important }
    .u61409{ width: 54%!important }
    .w542au .row .col-md-7{ width: 53%!important }
    .j0r112 .container .row .col-md-3 .row .col-md-6, #React_A01NB8 .row .col-md-6, .w728x2 .container .row .col-md-3, #React_B694LU .row .col-md-6{ width: 50%!important }
    .x35f74 .container .row .col-md-5:last-child, .zx1987 .container .row .col-md-5:last-child{ width: 48.66666667%!important }
    .w542au .row .col-md-5{ width: 47%!important }
    .q76j70{ width: 46%!important }
    .x35f74 .container .row .col-md-5, .zx1987 .container .row .col-md-5{ width: 43%!important }
    .j8w7l9 .container .row .col-md-6{ width: 35%!important }
    .v7672m .container .row .col-md-5{ width: 36.66666667%!important }
    .v7672m .container .row .col-md-4{ width: 38.33333333%!important }
    .k84mu0 .container .row .col-md-3, .h405q4 .container .row .col-md-3{ width: 33.3%!important }
    .f55237 .container .row .col-md-2:first-child{ width: 22.33333333%!important }
    .j0r112 .container .row .col-md-3{ width: 20%!important }
    .f55237 .container .row .col-md-2:last-child{ width: 15.9%!important }
    .f55237::before{ width: 226px!important }
    .c694qt svg{ width: 45px!important }
    .o840cp img{ width: 20px!important }
    .y15u5Q li span i, .s955un li:nth-child(4) a svg{ width: 18px!important }
    .n959k8 svg, .s955un li a svg, .s955un li:nth-child(5) a svg, .v5t92l img, .r89a95 img,  .m24ho4 img{ width: 17px!important }
    .m176ye svg{ width: 16px!important }
    .s955un li:nth-child(2) a svg, .s955un li:nth-child(3) a svg, .j0z12w svg{ width: 15px!important }
    .y15u5Q li span i svg{ width: 9px!important }
    .Up955K img{ width: 110px!important }
    .f031o3, .o840cp{ min-width: 100%!important }
    .s955un li a, .r766cl li a{ min-width: 40px!important }
    .i1888e{ min-width: 42px!important }
    .v5t92l, .r89a95{ max-width: 44px!important }
    .h33p0j{ min-width: 125px!important }
    .h33p0j{ max-width: 140px!important }
    .k51l81, .b584xj{ max-width: 160px!important }
    .d7fv32{ max-width: 175px!important }
    .x5e769,  #main_Internet .i31t7q{ max-width: 419px!important }
    .b47q67, #main_Success .qo3320{ max-width: 420px }
    .n7193f{ max-width: 430px!important }
    #main_Internet .qo3320{ max-width: 431px!important }
    section#main_Internet .qo3320{ max-width: 431px!important }
    .vp0y83{ max-width: 437px!important }
    #main_Internet .qo3320{ max-width: 452px!important }
    #main_Middle .qo3320{ max-width: 459px!important }
    .po7670, .n98kk4 iframe, .d3726r, .n97qf5, .a419o0, .p2769m{ height: 100%!important }
    .f031o3, #main_Internet, .a65254, .z82455 .o01a97 img, .n98kk4 iframe, .y76v0s, .n98kk4, .o840cp, .se5658 img, .h33p0j, .p72qx6 img, .h33p0j img, .p72qx6, .e74i23 img, .c741h7, .e74i23{ height: auto!important }
    .n98kk4{ height: 0!important }
    .y15u5Q li span i svg{ height: 9px!important }
    .y15u5Q li span{ height: 14px!important }
    .s955un li:nth-child(2) a svg, .s955un li:nth-child(3) a svg, .j0z12w svg{ height: 15px!important }
    .m176ye svg{ height: 16px!important }
    .n959k8 svg, .s955un li a svg, .s955un li:nth-child(5) a svg, .r89a95 img, .m24ho4 img, .v5t92l img{ height: 17px!important }
    .y15u5Q li span i, .s955un li:nth-child(4) a svg{ height: 18px!important }
    .o840cp img{ height: 20px!important }
    .o091hh{ height: 28px!important }
    .s955un li a, .r766cl li a, .k51l81, .b584xj{ height: 40px!important }
    .i1888e, .d7fv32{ height: 42px!important }
    .v5t92l, .r89a95{ height: 44px!important }
    .c694qt svg{ height: 45px!important }
    .a917yq{ height: 72px!important }
    .f55237, .f55237::before, .c694qt, .c37034{ height: 65px!important }
    .Up955K img{ height: 110px!important }
    #main_Middle .z93q7k{ height: 200px!important }
    #main_Internet .z93q7k{ height: 250px!important }
    .x63112{ height: 350px!important }
    .se5658 img{ max-height: 234px }
    .m8m73h, .y8450e li span, .u4025u li span, .k791vc, .g30798, .z2499o, .m1364c, .t77am5, .r3Bx93 li a, .v976lh, .o1m43l, .t456c4, .t027vd{ overflow: hidden }
    .m8m73h, .y8450e li span, .u4025u li span, .g30798, .z2499o, .r3Bx93 li a, .o1m43l, .t456c4, .t027vd{ white-space: nowrap }
    .m8m73h, .y8450e li span, .u4025u li span, .g30798, .z2499o, .r3Bx93 li a, .o1m43l, .t456c4, .t027vd{ text-overflow: ellipsis }
    .f031o3, #main_Internet .c28g97, #main_Success .c28g97, #main_Middle .c28g97, .m6083e{ line-height: 1!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320, .e745l2{ line-height: 0!important }
    .m1364c{ line-height: 20px!important }
    .w8M19n, .q317to, .t77am5, .v976lh{ line-height: 21px!important }
    .o4e46u, .s194hd, .b47q67, .vp0y83, .n7193f, .x5e769{ line-height: 22px!important }
    .u04m7q{ line-height: 22.5px!important }
    .c28g97, #main_Internet .i31t7q::before, #main_Success .qo3320::before, #main_Internet .qo3320::before, .d624lk, .e745l2::before{ line-height: 22px!important }
    .rP69J9 li:first-child a{ line-height: 70px!important }
    #main_Middle .qo3320::before, .w000w8, .e745l2::before, .k66z9t{ line-height: 24px!important }
    .g30798{ line-height: 25px!important }
    .q20g3c{ line-height: 27.5px!important }
    .z2499o{ line-height: 27px!important }
    .k51l81, .b584xj{ line-height: 42px!important }
    .rP69J9 li a, .f031o3{ line-height: 70px!important }
    .rP69J9{ justify-content: space-between!important }
    .c694qt, .i700xb{ justify-content: start!important }
    .c694qt{ justify-content: center!important }
    .c741h7, .a65254{ align-items: start!important }
    .c694qt{ align-items: center!important }
    .v976lh, .m1364c, .t77am5{ -webkit-box-orient: vertical }
    .v976lh, .t77am5, .m1364c{ -webkit-line-clamp: 3 }
    .f031o3{ text-decoration: underline }
    .n97qf5, .n98kk4 iframe{ position: absolute!important }
    .k791vc, .n98kk4{ position: relative!important }
    .f031o3{ text-align: right!important }
    .f031o3, .rP69J9 li a{ text-underline-offset: 7px }
    .k791vc{ float: none!important }
    #main_Internet .i31t7q::before, #main_Success .qo3320::before, #main_Internet .qo3320::before, #main_Middle .qo3320::before{ float: left }
    #main_Internet .i31t7q::before, #main_Success .qo3320::before, #main_Internet .qo3320::before, #main_Middle .qo3320::before, .e745l2::before{ color: #3E3F41!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320, e745l2, .e745l2{ color: #FFFFFF!important }
    .f031o3{ color: #000000!important }
    .f031o3{ background: transparent!important }
    .k51l81, .b584xj{ font-size: 11.5px!important }
    .x661n3, .r3Bx93 li a, .w8M19n, .q317to{ font-size: 12px!important }
    .m1364c{ font-size: 12.3px!important }
    .a407p5, .w84z88, .f031o3, .rP69J9 li a{ font-size: 12.5px!important }
    .t77am5, .e745l2, .m8m73h, .v976lh, .o4e46u, .z9y50r, .g30798, .s194hd, .d624lk{ font-size: 13px!important }
    .w000w8, .y15u5Q li span strong{ font-size: 13.3px!important }
    .b47q67{ font-size: 13.4px!important }
    .k66z9t, #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320, .s12a3e, .u04m7q, .x5e769, .n7193f, .vp0y83, .y15u5Q li span strong{ font-size: 13.5px!important }
    .e99v68, .br6312 h3, .t53z62{ font-size: 14px!important }
    .q892wd{ font-size: 15px!important }
    .w0r597, .z2499o{ font-size: 16px!important }
    .o50h0d{ font-size: 17px!important }
    .i745hd{ font-size: 18px!important }
    .q20g3c{ font-size: 18.5px!important }
    .t4q297, .p5o2a6{ font-size: 19px!important }
    .m6083e{ font-size: 19.5px!important }
    .c28g97, .q35h02{ font-size: 20px!important }
    #main_Middle .c28g97{ font-size: 21px!important }
    .o49df7{ font-size: 23px!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320, .e745l2{ margin: 8px 0 0 0!important }
    .j0z12w svg, #main_Internet .z93q7k{ margin: 0!important }
    .q317to, .n959k8, .m176ye, .r3Bx93, .w8M19n{ margin-top: 12px!important }
    .Up955K, .z9w60a, .a65254, .k791vc, .d3726r, .hl280a, .z93q7k{ margin-top: 24px!important }
    .o720ha{ margin-top: 36px!important }
    .t50h35, .k66z9t{ margin-top: 8px!important }
    .e745l2, .w000w8{ margin: 10px 0 0 0!important }
    .c741h7, .h4s606, .h68k88, .b98w7p, .y76v0s, .j8w7l9 .n98kk4{ margin-top: 42px!important }
    .t77am5{ margin-top: 4px!important }
    .br6312 h3{ margin: 8px 0 4px 0!important }
    .k51l81, .b584xj, .vp0y83{ margin-top: 10px!important }
    .x661n3{ margin-top: 12px!important }
    .d7fv32{ margin: 12px 0 0 0!important }
    .v7q534{ margin: 24px 0 0 0!important }
    .d624lk{ margin: 4px 0 0 0!important }
    .y15u5Q li:first-child span{ margin-top: 15px!important }
    .b384t5{ margin: 16px 0 0 0!important }
    .a407p5, .w84z88{ margin: 36px 0 0 0!important }
    .r87933{ margin: 48px 0 0 0!important }
    .po7670{ margin-top: 50px!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .g65z5u .y0422f .offset-md-4{ margin-left: 23%!important }
    .y15u5Q li span i svg{ margin: 0 0 0 1px!important }
    .rP69J9 li a{ padding: 0!important }
    .z48376{ padding-top: 0!important }
    .t77am5{ padding: 0!important }
    .q87a3p{ padding: 20px 0 0 0!important }
    .z48376{ padding-left: 24px!important }
    .q76j70{ padding-right: 24px!important }
    .y62e43{ padding: 36px 32px 42px 32px!important }
    .r87933{ padding: 48px 36px!important }
    .k791vc{ padding-bottom: 414px!important }
    .n98kk4{ padding-bottom: 56.25%!important }
    .n98kk4 iframe{ top: 0 }
    .n98kk4 iframe{ left: 0 }
    .f031o3{ border-radius: 0!important }
    .k51l81, .b584xj{ border-radius: 40px!important }
    .e745l2::before{ content: "Ben Özgür Tosun 7 Bölge'de, 68+ Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım." }
    #main_Internet .i31t7q::before{ content: "Benimle çalışan işletmelere; değer katan bu küresel markalar, yeni işletmelere başarılı olabilmeleri için gereken araçları sunar." }
    #main_Success .qo3320::before{ content: "Dijital varlığınızı güçlendirmek ve müşteri kitlenizi genişletmek için profesyonel bir web tasarım ile sınırları zorlayın." }
    #main_Internet .qo3320::before{ content: "Kurumsal bir web tasarımı ile dijital varlığınızı güçlendirin ve müşteri tabanınızı genişleterek müşteri kitlenizi artırın." }
    #main_Middle .qo3320::before{ content: "Bugün 7 Bölge'de, 68 Şehir'de, 5.000+ İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım." }
}
@media (min-width: 801px) and (max-width: 991.2px){
    .f55237::before, .e745l2 br, .b584xj, .rP69J9 li:nth-child(3), .m505cz .row .col-md-1:nth-child(8), .m505cz .row .col-md-1:nth-child(2), .s3zb07 .container .row .col-md-1, .qo3320 br, .w000w8 br, .x35f74 .container .row .col-md-1, .p3287u .container .row .col-md-5, .q35322::before, .o4e46u br, .zx1987 .container .row .col-md-1{ display: none!important }
    .vp0y83, .n7193f, .b47q67{ display: block!important }
    .e745l2, .o4e46u, .v976lh, .t77am5, .x5e769::before{ display: -webkit-box }
    .f031o3, .g26s0e .container .row .col-md-12 .row .col-md-9, .g26s0e .container .row .col-md-12 .row .col-md-3, .s3zb07 .container .row .col-md-10, .o1m43l, .h33p0j img, .p72qx6 img, .j0r112 .container .row .col-md-3 .row .col-md-6, .m8m73h, .sr551s .container .row .col-md-8, .v7672m .container .row .col-md-12, .v7672m .container .row .col-md-12 .row .col-md-3, .v7672m .container .row .col-md-12 .row .col-md-4, .v7672m .container .row .col-md-12 .row .col-md-5, .m1364c, .e745l2::before, .w000w8::before, .qo3320::before, #main_Success .qo3320::before, #main_Internet .i31t7q::before, .se5658 img, .t77am5, .g30798, .o01a97 img, .zx1987 .n97qf5, .t4q297, .u61409, .q76j70, .w728x2 .container .row .col-md-3, .w728x2 .container .row .col-md-6, .j8w7l9 .container .row .col-md-6, .n98kk4 iframe, .p5o2a6, .p3287u .container .row .col-md-7, .g65z5u .y0422f .col-md-6, .b39yo6 img, .g65z5u .v7672m{ width: 100%!important }
    .w542au .row .col-md-5, .w542au .row .col-md-7, #React_A01NB8 .row .col-md-6, .k84mu0 .container .row .col-md-3, .zx1987 .container .row .col-md-5, .x35f74 .container .row .col-md-5, .h405q4 .container .row .col-md-3, #React_B694LU .row .col-md-6{ width: 50%!important }
    .f55237 .container .row .col-md-2:first-child{ width: 9%!important }
    .j0r112 .container .row .col-md-3{ width: 12%!important }
    .m505cz .row .col-md-1{ width: 12.5%!important }
    .f55237 .container .row .col-md-2:last-child{ width: 23%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3{ width: 25%!important }
    .f55237 .container .row .col-md-8{ width: 68%!important }
    .j0r112 .container .row .col-md-6{ width: 76%!important }
    .y15u5Q li span i svg{ width: 7px!important }
    .j0z12w svg{ width: 13px!important }
    .s955un li:nth-child(2) a svg, .s955un li:nth-child(3) a svg, .y15u5Q li span i{ width: 14px!important }
    .m176ye svg, .r766cl li:nth-child(2) a svg{ width: 15px!important }
    .s955un li a svg, .n959k8 svg,  .s955un li:nth-child(5) a svg{ width: 16px!important }
    .s955un li:nth-child(4) a svg{ width: 17px!important }
    .r766cl li a svg, .o840cp img{ width: 18px!important }
    .c694qt svg{ width: 36px!important }
    .Up955K img{ width: 124px!important }
    .p72qx6{ max-width: 100%!important }
    .h33p0j{ min-width: 96px!important }
    .h33p0j{ max-width: 124px!important }
    .k51l81{ max-width: 150px!important }
    .d7fv32{ max-width: 170px!important }
    .d7fv32{ min-width: 175px!important }
    #main_Internet .i31t7q{ max-width: 335px!important }
    .x5e769{ max-width: 336px }
    .b47q67, #main_Success .qo3320{ max-width: 340px }
    .n7193f{ max-width: 345px }
    .qo3320{ max-width: 349px!important }
    .vp0y83{ max-width: 350px }
    .z493ea{ max-width: 460px!important }
    .q20g3c, .d624lk{ max-width: 480px!important }
    .o840cp, .f031o3{ min-width: 100%!important }
    .s955un li a, .r766cl li a{ min-width: 36px!important }
    .po7670, .zx1987 .n97qf5, .d3726r, .n98kk4 iframe{ height: 100%!important }
    .i700xb, .n98kk4, .y76v0s, .o01a97 img, .p2z98p, .o840cp, .se5658 img, .h33p0j img, .h33p0j, .p72qx6 img, .p72qx6, .p2769m, .c741h7, .e74i23{ height: auto!important }
    .n98kk4{ height: 0!important }
    .y15u5Q li span i svg{ height: 7px!important }
    .y15u5Q li span{ height: 11px!important }
    .j0z12w svg{ height: 13px!important }
    .s955un li:nth-child(2) a svg, .s955un li:nth-child(3) a svg, .y15u5Q li span i{ height: 14px!important }
    .m176ye svg, .r766cl li:nth-child(2) a svg{ height: 15px!important }
    .n959k8 svg, .s955un li a svg, .s955un li:nth-child(5) a svg{ height: 16px!important }
    .s955un li:nth-child(4) a svg{ height: 17px!important }
    .r766cl li a svg, .o840cp img{ height: 18px!important }
    .o091hh{ height: 25px!important }
    .c694qt svg, .f031o3, .s955un li a, .r766cl li a, .k51l81{ height: 36px!important }
    .hw9107, .g301va{ height: 40px!important }
    .hv89h7, .s955un{ height: 80px!important }
    .j05q38{ height: 65px!important }
    .a917yq{ height: 64px!important }
    .z93q7k{ height: 200px!important }
    .Up955K img{ height: 124px!important }
    .d7fv32{ height: 42px!important }
    .x63112{ height: 250px!important }
    .n5x508, .ck3902{ min-height: 296px!important }
    .po7670{ min-height: 304px!important }
    .d540uf{ min-height: 632px!important }
    .se5658 img{ max-height: 170px }
    .o4e46u, .t4q297, .g30798, .v976lh, .t77am5, .x5e769::before, .o1m43l, .m8m73h, .zx1987 .k791vc, .w000w8, .e745l2{ overflow: hidden!important }
    .t4q297, .g30798, .o1m43l, .m8m73h{ white-space: nowrap }
    .t4q297, .m8m73h, .o1m43l, .g30798{ text-overflow: ellipsis }
    .e99v68{ line-height: 1!important }
    .e745l2, .w000w8, .qo3320, .i31t7q{ line-height: 0!important }
    .y15u5Q li span strong{ line-height: 14px!important }
    .o4e46u, .t77am5, .v976lh, .vp0y83, .n7193f, .b47q67, .o1m43l{ line-height: 19px!important }
    .w000w8::before, .qo3320::before, #main_Success #main_Internet .i31t7q::before, .g30798, .m8m73h, .x5e769, .t77am5, #main_Internet .i31t7q::before{ line-height: 21px!important }
    .d624lk, .u04m7q{ line-height: 22px!important }
    .q892wd, .m1364c, .e745l2::before{ line-height: 23px!important }
    .s194hd{ line-height: 24px!important }
    .c28g97{ line-height: 25px!important }
    .q20g3c, .z2499o{ line-height: 28px!important }
    .k51l81, .f031o3{ line-height: 36px!important }
    .p083cW{ line-height: 40px!important }
    .f07622, .e76r8a{ line-height: 65px!important }
    .e745l2, .v976lh, .t77am5, .x5e769::before, .o4e46u{ -webkit-box-orient: vertical }
    .p796s4{ flex-direction: column!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ justify-content: center!important }
    .rP69J9{ justify-content: space-between!important }
    .n98kk4, .zx1987 .k791vc{ position: relative!important }
    .n98kk4 iframe, .zx1987 .n97qf5{ position: absolute }
    .e745l2::before, .w000w8::before, .qo3320::before, #main_Success .qo3320::before, #main_Internet .i31t7q::before{ float: left }
    .zx1987 .k791vc{ float: none!important }
    .e745l2::before, .w000w8::before, .qo3320::before, #main_Success .qo3320::before, #main_Internet .i31t7q::before{ color: #3E3F41!important }
    .e745l2, .w000w8, .qo3320, .i31t7q{ color: #FFFFFF!important }
    .p083cW, .u96gu5{ font-size: 10px!important }
    .f031o3, .r3Bx93 li a, .x661n3, .n959k8, .k51l81{ font-size: 11px!important }
    .rP69J9 li a{ font-size: 11.4px!important }
    .m8m73h, .g30798, .w8M19n, .q317to, .m176ye, .o4e46u, .z9y50r{ font-size: 11.5px!important }
    .vp0y83, .n7193f, .b47q67{ font-size: 11.96px!important }
    .w84z88, .t77am5, .f07622, .e76r8a, .a407p5, .x5e769, .w000w8, #main_Internet .i31t7q::before, .qo3320, .y15u5Q li span strong, .o1m43l, .v976lh{ font-size: 12px!important }
    .e745l2, .u04m7q, .d7fv32{ font-size: 12.5px!important }
    .d624lk, .e99v68, .s63448, .t53z62{ font-size: 13px!important }
    .c68146, .s194hd, .m1364c{ font-size: 13.5px!important }
    .q892wd, .w0r597, .t4q297{ font-size: 14px!important }
    .o50h0d, .i745hd{ font-size: 15px!important }
    .m6083e, .c28g97{ font-size: 16px!important }
    .q35h02{ font-size: 16.2px!important }
    .z2499o{ font-size: 17px!important }
    .p5o2a6{ font-size: 18px!important }
    .q20g3c{ font-size: 18.4px!important }
    .o49df7{ font-size: 21.4px!important }
    .v976lh, .t77am5, .x5e769::before{ -webkit-line-clamp: 3 }
    .o4e46u, .w000w8, .e745l2{ -webkit-line-clamp: 2 }
    .f031o3, .k51l81{ border-radius: 36px!important }
    .r46f3s, .y7367g, .z93q7k, .po7670, .s3zb07 .container .row .offset-md-1, .sI631f{ margin: 0!important }
    .y15u5Q li span i svg{ margin: 0 0 0 1px!important }
    .qo3320{ margin: 2px 0 0 0!important }
    #main_Internet .i31t7q{ margin: 1px 0 0 0!important }
    .j0z12w svg{ margin: -2px 0 0 0!important }
    .vp0y83, .n7193f, .b47q67{ margin-top: 3px!important }
    .s63448{ margin-bottom: 3px!important }
    .e745l2{ margin-top: 4px!important }
    .d624lk, .o4e46u{ margin: 4px 0 0 0!important }
    .y15u5Q li span strong{ margin-left: 4px!important }
    .x5e769{ margin-top: 5px!important }
    .n959k8, .m176ye{ margin-top: 7px!important }
    .k51l81, .w000w8, .qcc560{ margin-top: 8px!important }
    .r3Bx93 li a{ margin: 0 0 5px 0!important }
    .x661n3, .w8M19n, .q317to{ margin-top: 10px!important }
    .sr551s .container .row .offset-md-2, .v7672m .container .row .col-md-12{ margin-left: 0!important }
    .w728x2{ margin-bottom: 0!important }
    .y15u5Q li span, .r3Bx93, .t883f0, .g65z5u .v7672m .col-md-4 .i700xb{ margin-top: 12px!important }
    .Up955K, .n98kk4, .y76v0s{ margin: 24px 0 0 0!important }
    .o720ha, .z9w60a, .k791vc{ margin-top: 24px!important }
    .b384t5, .h4s606, .c37dc8{ margin-top: 18px!important }
    .br6312{ margin: 8px 0 0 0!important }
    .h405q4 .container .row .col-md-3:nth-child(3) .d540uf, .h405q4 .container .row .col-md-3:nth-child(4) .d540uf{ margin-bottom: 24px!important }
    .c741h7, .b98w7p, .h68k88{ margin-top: 36px!important }
    .i700xb{ margin: 24px 0 0 0!important }
    .a407p5, .w84z88{ margin-top: 32px!important }
    .h33p0j{ margin: -30px 0 0 0!important }
    .g65z5u .y0422f .offset-md-4{ margin-left: 17%!important }
    .r87933{ margin: 48px 0 0 0!important }
    .d540uf{ margin-bottom: 74px!important }
    .q35322{ margin: 24px 0 0 0!important }
    .rP69J9 li a, .y76v0s, .q76j70, .t77am5, .o01a97{ padding: 0!important }
    .z48376{ padding: 0 0 0 18px!important }
    .q87a3p{ padding-top: 20px!important }
    .u4025u{ padding-top: 32px!important }
    .o9l44l{ padding-bottom: 22px!important }
    .y62e43{ padding: 24px 42px 32px 42px!important }
    .q76j70{ padding: 36px 48px!important }
    .n98kk4{ padding-bottom: 56.25%!important }
    .r87933{ padding: 48px 36px!important }
    .u4025u{ padding-bottom: 32px!important }
    .zx1987 .k791vc{ padding-bottom: 312px!important }
    .p5o2a6, .s194hd{ text-align: center!important }
    .m1364c{ text-align: start!important }
    .n98kk4 iframe{ left: 0 }
    .n98kk4 iframe{ top: 0 }
    .r46f3s{ border-top: 1px solid var(--css-n436ik)!important }
    .o9l44l{ border-bottom: 1px solid var(--css-n436ik)!important }
    .w000w8::before{ content: "İşletmenizin çevrimiçi varlığını güçlendirerek müşteri kitlesi oluşturun ve satışları artırın." }
    .e745l2::before{ content: "Ben Özgür Tosun 68+ Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım." }
    #main_Middle .qo3320::before{ content: "Bugün 68 Şehir'de, 5.000 Farklı İşletme İçin Hizmet Sunmanın Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım." }
    #main_Internet .qo3320::before{ content: "İyi bi web tasarım ile müşteri tabanınızı genişleterek çevrimiçi varlığınızı güçlendirin ve müşteri kitlenizi genişletin." }
    #main_Success .qo3320::before{ content: "Çevrimiçi etkinliğinizi artırarak ve işletmenizin dijital varlığını güçlendirin ve müşteri kitlenizi genişletin." }
    #main_Internet .i31t7q::before{ content: "Küresel markalar iş birliği yaptığı işletmelere değer katar ve girişimlere başarılı olmaları için gerekli araçları sunar." }
}
@media (min-width: 768px) and (max-width: 800px){
    .f55237::before, .e745l2 br, .b584xj, .rP69J9 li:nth-child(3), .m505cz .row .col-md-1:nth-child(8), .m505cz .row .col-md-1:nth-child(2), .s3zb07 .container .row .col-md-1, .qo3320 br, .w000w8 br, .x35f74 .container .row .col-md-1, .p3287u .container .row .col-md-5, .q35322::before, .o4e46u br, .zx1987 .container .row .col-md-1{ display: none!important }
    .vp0y83, .n7193f, .b47q67{ display: block!important }
    .e745l2, .o4e46u, .v976lh, .t77am5, .x5e769::before{ display: -webkit-box }
    .f031o3, .g26s0e .container .row .col-md-12 .row .col-md-9, .g26s0e .container .row .col-md-12 .row .col-md-3, .s3zb07 .container .row .col-md-10, .o1m43l, .h33p0j img, .p72qx6 img, .j0r112 .container .row .col-md-3 .row .col-md-6, .m8m73h, .sr551s .container .row .col-md-8, .v7672m .container .row .col-md-12, .v7672m .container .row .col-md-12 .row .col-md-3, .v7672m .container .row .col-md-12 .row .col-md-4, .v7672m .container .row .col-md-12 .row .col-md-5, .m1364c, .e745l2::before, .w000w8::before, .qo3320::before, #main_Success .qo3320::before, #main_Internet .i31t7q::before, .se5658 img, .t77am5, .g30798, .o01a97 img, .zx1987 .n97qf5, .t4q297, .u61409, .q76j70, .w728x2 .container .row .col-md-3, .w728x2 .container .row .col-md-6, .j8w7l9 .container .row .col-md-6, .n98kk4 iframe, .p5o2a6, .p3287u .container .row .col-md-7, .g65z5u .y0422f .col-md-6, .b39yo6 img{ width: 100%!important }
    .j0r112 .container .row .col-md-6{ width: 76%!important }
    .f55237 .container .row .col-md-8{ width: 68%!important }
    .w542au .row .col-md-5, .w542au .row .col-md-7, #React_A01NB8 .row .col-md-6, .k84mu0 .container .row .col-md-3, .zx1987 .container .row .col-md-5, .x35f74 .container .row .col-md-5, .h405q4 .container .row .col-md-3, #React_B694LU .row .col-md-6{ width: 50%!important }
    .f55237 .container .row .col-md-2:last-child{ width: 23%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3{ width: 25%!important }
    .m505cz .row .col-md-1{ width: 12.5%!important }
    .j0r112 .container .row .col-md-3{ width: 12%!important }
    .f55237 .container .row .col-md-2:first-child{ width: 9%!important }
    .y15u5Q li span i svg{ width: 7px!important }
    .j0z12w svg{ width: 13px!important }
    .s955un li:nth-child(2) a svg, .s955un li:nth-child(3) a svg, .y15u5Q li span i{ width: 14px!important }
    .m176ye svg, .r766cl li:nth-child(2) a svg{ width: 15px!important }
    .s955un li a svg, .n959k8 svg,  .s955un li:nth-child(5) a svg{ width: 16px!important }
    .s955un li:nth-child(4) a svg{ width: 17px!important }
    .r766cl li a svg, .o840cp img{ width: 18px!important }
    .c694qt svg{ width: 36px!important }
    .Up955K img{ width: 124px!important }
    .p72qx6{ max-width: 100%!important }
    .q20g3c, .d624lk{ max-width: 480px!important }
    .z493ea{ max-width: 460px!important }
    .vp0y83{ max-width: 350px }
    .qo3320{ max-width: 349px!important }
    .n7193f{ max-width: 345px }
    #main_Success .qo3320, .b47q67{ max-width: 340px!important }
    .x5e769{ max-width: 336px }
    #main_Internet .i31t7q{ max-width: 335px!important }
    .d7fv32{ max-width: 170px!important }
    .k51l81{ max-width: 150px!important }
    .h33p0j{ max-width: 124px!important }
    .o840cp, .f031o3{ min-width: 100%!important }
    .d7fv32{ min-width: 175px!important }
    .h33p0j{ min-width: 96px!important }
    .s955un li a, .r766cl li a{ min-width: 36px!important }
    .i700xb, .n98kk4, .y76v0s, .o01a97 img, .p2z98p, .o840cp, .se5658 img, .h33p0j img, .h33p0j, .p72qx6 img, .p72qx6, .p2769m, .c741h7, .e74i23{ height: auto!important }
    .po7670, .zx1987 .n97qf5, .d3726r, .n98kk4 iframe{ height: 100%!important }
    .n98kk4{ height: 0!important }
    .y15u5Q li span i svg{ height: 7px!important }
    .y15u5Q li span{ height: 11px!important }
    .j0z12w svg{ height: 13px!important }
    .s955un li:nth-child(2) a svg, .s955un li:nth-child(3) a svg, .y15u5Q li span i{ height: 14px!important }
    .m176ye svg, .r766cl li:nth-child(2) a svg{ height: 15px!important }
    .n959k8 svg, .s955un li a svg, .s955un li:nth-child(5) a svg{ height: 16px!important }
    .s955un li:nth-child(4) a svg{ height: 17px!important }
    .r766cl li a svg, .o840cp img{ height: 18px!important }
    .o091hh{ height: 25px!important }
    .c694qt svg, .f031o3, .s955un li a, .r766cl li a, .k51l81{ height: 36px!important }
    .hw9107, .g301va{ height: 40px!important }
    .hv89h7, .s955un{ height: 80px!important }
    .j05q38{ height: 65px!important }
    .a917yq{ height: 64px!important }
    .z93q7k{ height: 200px!important }
    .Up955K img{ height: 124px!important }
    .d7fv32{ height: 42px!important }
    .x63112{ height: 250px!important }
    .po7670{ min-height: 304px!important }
    .d540uf{ min-height: 632px!important }
    .n5x508, .ck3902{ min-height: 296px!important }
    .se5658 img{ max-height: 170px }
    .o4e46u, .t4q297, .g30798, .v976lh, .t77am5, .x5e769::before, .o1m43l, .m8m73h, .zx1987 .k791vc, .w000w8, .e745l2{ overflow: hidden!important }
    .t4q297, .g30798, .o1m43l, .m8m73h{ white-space: nowrap }
    .t4q297, .m8m73h, .o1m43l, .g30798{ text-overflow: ellipsis }
    .e99v68{ line-height: 1!important }
    .e745l2, .w000w8, .qo3320, .i31t7q{ line-height: 0!important }
    .y15u5Q li span strong{ line-height: 14px!important }
    .o4e46u, .t77am5, .v976lh, .vp0y83, .n7193f, .b47q67, .o1m43l{ line-height: 19px!important }
    .qo3320::before, #main_Internet .i31t7q::before{ line-height: 20px!important }
    .w000w8::before, #main_Success #main_Internet .i31t7q::before, .g30798, .m8m73h, .x5e769, .t77am5{ line-height: 21px!important }
    .e745l2::before, .d624lk, .u04m7q{ line-height: 22px!important }
    .q892wd, .m1364c{ line-height: 23px!important }
    .s194hd{ line-height: 24px!important }
    .c28g97{ line-height: 25px!important }
    .q20g3c, .z2499o{ line-height: 28px!important }
    .k51l81, .f031o3{ line-height: 36px!important }
    .p083cW{ line-height: 40px!important }
    .f07622, .e76r8a{ line-height: 65px!important }
    .e745l2, .o4e46u, .v976lh, .t77am5, .x5e769::before{ -webkit-box-orient: vertical }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ justify-content: center!important }
    .rP69J9{ justify-content: space-between!important }
    .p5o2a6, .s194hd{ text-align: center!important }
    .m1364c{ text-align: start!important }
    .n98kk4, .zx1987 .k791vc{ position: relative!important }
    .n98kk4 iframe, .zx1987 .n97qf5{ position: absolute }
    .n98kk4 iframe{ left: 0 }
    .n98kk4 iframe{ top: 0 }
    .e745l2::before, .w000w8::before, .qo3320::before, #main_Success .qo3320::before, #main_Internet .i31t7q::before{ float: left }
    .zx1987 .k791vc{ float: none!important }
    .e745l2::before, .w000w8::before, .qo3320::before, #main_Success .qo3320::before, #main_Internet .i31t7q::before{ color: #3E3F41!important }
    .e745l2, .w000w8, .qo3320, .i31t7q{ color: #FFFFFF!important }
    .p083cW, .u96gu5{ font-size: 10px!important }
    .f031o3, .r3Bx93 li a, .x661n3, .n959k8, .k51l81{ font-size: 11px!important }
    .rP69J9 li a{ font-size: 11.4px!important }
    .m8m73h, .g30798, .w8M19n, .q317to, .m176ye, .o4e46u, .z9y50r{ font-size: 11.5px!important }
    .vp0y83, .n7193f, .b47q67{ font-size: 11.96px!important }
    .w84z88, .t77am5, .f07622, .e76r8a, .a407p5, .x5e769, .w000w8, #main_Internet .i31t7q::before, .qo3320, .y15u5Q li span strong, .o1m43l, .v976lh{ font-size: 12px!important }
    .e745l2, .u04m7q, .d7fv32{ font-size: 12.5px!important }
    .d624lk, .e99v68, .s63448, .t53z62{ font-size: 13px!important }
    .c68146, .s194hd{ font-size: 13.5px!important }
    .m1364c{ font-size: 13.8px!important }
    .q892wd, .w0r597, .t4q297{ font-size: 14px!important }
    .o50h0d, .i745hd{ font-size: 15px!important }
    .m6083e, .c28g97{ font-size: 16px!important }
    .q35h02{ font-size: 16.2px!important }
    .z2499o, .p5o2a6{ font-size: 18px!important }
    .q20g3c{ font-size: 18.4px!important }
    .o49df7{ font-size: 21.4px!important }
    .v976lh, .t77am5, .x5e769::before{ -webkit-line-clamp: 3 }
    .o4e46u, .w000w8, .e745l2{ -webkit-line-clamp: 2 }
    .p796s4{ flex-direction: column!important }
    .f031o3, .k51l81{ border-radius: 36px!important }
    .r46f3s, .y7367g, .z93q7k, .po7670, .s3zb07 .container .row .offset-md-1, .sI631f{ margin: 0!important }
    .y15u5Q li span i svg{ margin: 0 0 0 1px!important }
    .qo3320{ margin: 2px 0 0 0!important }
    #main_Internet .i31t7q{ margin: 1px 0 0 0!important }
    .j0z12w svg{ margin: -2px 0 0 0!important }
    .vp0y83, .n7193f, .b47q67{ margin-top: 3px!important }
    .s63448{ margin-bottom: 3px!important }
    .e745l2{ margin-top: 4px!important }
    .d624lk, .o4e46u{ margin: 4px 0 0 0!important }
    .y15u5Q li span strong{ margin-left: 4px!important }
    .x5e769{ margin-top: 5px!important }
    .n959k8, .m176ye{ margin-top: 7px!important }
    .k51l81, .w000w8, .qcc560{ margin-top: 8px!important }
    .r3Bx93 li a{ margin: 0 0 5px 0!important }
    .x661n3, .w8M19n, .q317to{ margin-top: 10px!important }
    .sr551s .container .row .offset-md-2, .v7672m .container .row .col-md-12{ margin-left: 0!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb, .w728x2{ margin-bottom: 0!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb, .y15u5Q li span, .r3Bx93{ margin-top: 12px!important }
    .Up955K, .n98kk4, .y76v0s, .i700xb, .t883f0{ margin: 24px 0 0 0!important }
    .o720ha, .z9w60a, .k791vc{ margin-top: 24px!important }
    .b384t5, .h4s606, .c37dc8{ margin-top: 18px!important }
    .br6312{ margin: 8px 0 0 0!important }
    .h405q4 .container .row .col-md-3:nth-child(3) .d540uf, .h405q4 .container .row .col-md-3:nth-child(4) .d540uf{ margin-bottom: 24px!important }
    .c741h7, .b98w7p, .h68k88{ margin-top: 36px!important }
    .a407p5, .w84z88{ margin-top: 32px!important }
    .h33p0j{ margin: -30px 0 0 0!important }
    .g65z5u .y0422f .offset-md-4{ margin-left: 17%!important }
    .r87933{ margin: 48px 0 0 0!important }
    .d540uf{ margin-bottom: 74px!important }
    .q35322{ margin: 24px 0 0 0!important }
    .rP69J9 li a, .y76v0s, .q76j70, .t77am5, .o01a97{ padding: 0!important }
    .z48376{ padding: 0 0 0 18px!important }
    .q87a3p{ padding-top: 20px!important }
    .u4025u{ padding-top: 32px!important }
    .o9l44l{ padding-bottom: 22px!important }
    .y62e43{ padding: 24px 42px 32px 42px!important }
    .q76j70{ padding: 36px 48px!important }
    .n98kk4{ padding-bottom: 56.25%!important }
    .r87933{ padding: 48px 36px!important }
    .u4025u{ padding-bottom: 32px!important }
    .zx1987 .k791vc{ padding-bottom: 312px!important }
    .r46f3s{ border-top: 1px solid var(--css-n436ik)!important }
    .o9l44l{ border-bottom: 1px solid var(--css-n436ik)!important }
    .w000w8::before{ content: "İşletmenizin çevrimiçi varlığını güçlendirerek müşteri kitlesi oluşturun ve satışları artırın." }
    .e745l2::before{ content: "Ben Özgür Tosun 68+ Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım." }
    #main_Middle .qo3320::before{ content: "Bugün 68 Şehir'de, 5.000 Farklı İşletme İçin Hizmet Sunmanın Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım." }
    #main_Internet .qo3320::before{ content: "İyi bi web tasarım ile müşteri tabanınızı genişleterek çevrimiçi varlığınızı güçlendirin ve müşteri kitlenizi genişletin." }
    #main_Success .qo3320::before{ content: "Çevrimiçi etkinliğinizi artırarak ve işletmenizin dijital varlığını güçlendirin ve müşteri kitlenizi genişletin." }
    #main_Internet .i31t7q::before{ content: "Küresel markalar iş birliği yaptığı işletmelere değer katar ve girişimlere başarılı olmaları için gerekli araçları sunar." }
}
@media screen and (max-width: 767px){
    .hw9107, .f55237, .hv89h7, .qcc560, .y7367g{ display: none!important }
    .s8956w, .m78d3j, .e59co7, .pi4981{ display: block!important }
    .r46f3s{ border-top: 1px solid var(--css-n436ik)!important }
    .r46f3s, .j05q38{ margin: 24px 0 0 0!important }
    .r46f3s .o9l44l{ margin: 16px 0 0 0!important }
    .r686wp .g26s0e .r46f3s .o9l44l:last-child{ border-top: 1px solid var(--css-n436ik)!important }
    .r686wp .g26s0e .r46f3s .o9l44l:last-child{ margin: 24px 0 0 0!important }
}
@media screen and (min-width:600px) and (max-width:767px){
    .e745l2 br, .qo3320 br, .p3287u .container .row .col-md-5, .Select, .q35322::before{ display: none!important }
    .q892wd, .t77am5{ display: -webkit-box }
    .p2769m .b98w7p, .j0r112 .container .row .col-md-3 .row .col-md-6{ width: auto!important }
    .h33p0j img, .z48376, .t53z62, .o1m43l, .b39yo6 img, .se5658 img, .p72qx6 img, .x35f74 .container .row .col-md-5:last-child, .m6083e, .e745l2, .e745l2, .c250ba .container .row .col-md-2 .m8m73h, .s3zb07 .container .row .col-md-10:last-child, .g30798, .zx1987 .container .row .col-md-5:last-child, .o01a97 img, .u61409, .q76j70{ width: 100%!important }
    .j0r112 .container .row .col-md-3:last-child .row .col-md-6:last-child, .j0r112 .container .row .col-md-3 .row .col-md-6{ width: 66.6%!important }
    .w057op .row .col-md-6, .m6747x .container .row .col-md-4{ width: 50%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .col-md-3, .j0r112 .container .row .col-md-3 .row .col-md-6:last-child, .j0r112 .container .row .col-md-3:last-child .row .col-md-6{ width: 33.3%!important }
    .m505cz .row .col-md-1{ width: 20%!important }
    .c250ba .container .row .col-md-2, .b0e620 .container .row .col-md-2{ width: 25%!important }
    .x35f74 .container .row .col-md-5, .s3zb07 .container .row .col-md-10, .zx1987 .container .row .col-md-5{ width: calc( 100% - 60px )!important }
    .y15u5Q li span i svg{ width: 8px!important }
    .m176ye svg, .m24ho4 img{ width: 15px!important }
    .y15u5Q li span i{ width: 16px!important }
    .n959k8 svg, .v7c42n svg, .x35f74 .r89a95 img{ width: 17px!important }
    .x35f74 .container .row .col-md-1, .s3zb07 .container .row .col-md-1, .zx1987 .container .row .col-md-1, .v5t92l img{ width: 18px!important }
    .q928g8 svg, .v10846 svg, .o840cp img{ width: 21px!important }
    .s5c45j svg{ width: 38px!important }
    .Up955K img{ width: 100px!important }
    .p72qx6, .i1888e, .o840cp, .x35f74 .r89a95, .v5t92l{ max-width: 100%!important }
    .h33p0j{ max-width: 124px!important }
    .d7fv32{ min-width: 180px!important }
    .h33p0j{ min-width: 124px!important }
    .x35f74 .e74i23, .x35f74 .r89a95, .po7670, .p2769m, .v7q534, .p2z98p, .n5a38b, .se5658 img, .n5a38b, .o840cp, .z93q7k, .h33p0j, .h33p0j img, .p72qx6, .p72qx6 img, .a419o0, .i1888e, .a65254, .d3726r, .o01a97 img, .n98kk4, .y76v0s, .i700xb{ height: auto!important }
    .x35f74 .c741h7, .n97qf5, .v5t92l{ height: 100%!important }
    .y15u5Q li span i svg{ height: 8px!important }
    .m176ye svg, .m24ho4 img{ height: 15px!important }
    .y15u5Q li span i{ height: 16px!important }
    .n959k8 svg, .v7c42n svg, .y15u5Q li span, .x35f74 .r89a95 img{ height: 17px!important }
    .v5t92l img{ height: 18px!important }
    .q928g8 svg, .v10846 svg, .o840cp img{ height: 21px!important }
    .s5c45j svg, .n772zy{ height: 38px!important }
    .k51l81, .b584xj{ height: 40px!important }
    .d7fv32{ height: 42px!important }
    .a917yq{ height: 84px!important }
    .Up955K img{ height: 100px!important }
    .x63112{ height: 200px!important }
    #main_Middle .z93q7k, #main_Internet .z93q7k, #main_Success .z93q7k, #main_Internet .t934dn{ height: 250px!important }
    .se5658 img{ max-height: 188px }
    .d540uf{ min-height: auto!important }
    .n772zy{ border-radius: 38px!important }
    .k51l81, .b584xj{ border-radius: 40px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .q892wd, .t77am5, .c250ba .container .row .col-md-2 .m8m73h, .z48376 .o1m43l, .g30798, .k791vc, .t027vd, .t456c4{ overflow: hidden }
    .r3Bx93 li a, .w8M19n, .q317to, .c250ba .container .row .col-md-2 .m8m73h, .z48376 .o1m43l, .g30798, .t027vd, .t456c4{ white-space: nowrap }
    .r3Bx93 li a, .w8M19n, .q317to, .c250ba .container .row .col-md-2 .m8m73h, .z48376 .o1m43l, .g30798, .t027vd, .t456c4{ text-overflow: ellipsis }
    .q892wd, .t77am5{ -webkit-box-orient: vertical }
    .q892wd, .t77am5{ -webkit-line-clamp: 3 }
    .d01iq4, .v7q534{ justify-content: start!important }
    .j0r112 .container .row .col-md-3 .row .col-md-6:last-child .p2769m, .p2769m{ justify-content: space-between!important }
    .x63112, .d01z5h, .z93q7k{ align-items: start!important }
    .v7q534, .n5a38b, .t934dn{ align-items: center!important }
    .p2769m{ flex-direction: row!important }
    .p796s4{ flex-direction: column!important }
    #main_Middle, #main_Internet, #main_Success, #main_Internet{ background: #F4F4F4!important }
    .i63421 tbody tr td label, .i63421 thead tr th span{ font-size: 10px!important }
    .n959k8{ font-size: 11.5px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .m176ye, .x661n3, .s194hd, .c250ba .container .row .col-md-2 .m8m73h{ font-size: 12px!important }
    .o9l44l .w84z88, .f07622, .e76r8a, .n90bv9 .a407p5{ font-size: 12.5px!important }
    .t77am5, .q746r5, .o188l9, .v976lh, .z48376 .o1m43l, .g30798, .d624lk, .u04m7q, .o4e46u, .z9y50r, .z493ea li a, .m1364c{ font-size: 13px!important }
    .e745l2{ font-size: 13.3px!important }
    .i31t7q, .e99v68, .w000w8, .y15u5Q li span strong, .qo3320{ font-size: 13.5px!important }
    .q892wd, .z48376 .t53z62{ font-size: 14px!important }
    .s3zb07 .br6312 .s63448{ font-size: 14.5px!important }
    .g65z5u .b0e620 .z9w60a .i745hd, .c250ba .container .row .col-md-2 .o50h0d{ font-size: 16px!important }
    .p5o2a6{ font-size: 17px!important }
    .q20g3c, .o49df7, .t4q297, .z2499o{ font-size: 18px!important }
    .q35h02, .c28g97, .m6083e{ font-size: 19px!important }
    .z93q7k, .p2z98p, #main_Middle .z93q7k, .se5658, .x661n3, .x35f74 .h4s606, .x35f74 .c741h7, .h119in .x35f74 .po7670, .g65z5u .y0422f .offset-md-4, .y7367g, .h405q4 .container .row .col-md-3:first-child .d540uf{ margin: 0!important }
    .m176ye svg{ margin: -1px 8px 0 0!important }
    .n959k8 svg{ margin: -2px 8px 0 0!important }
    .h33p0j{ margin: -25px 0 0 0!important }
    .pi4981{ margin-top: 0!important }
    .p2769m .b98w7p:last-child{ margin-right: 0!important }
    .x35f74 .c741h7 .r89a95:last-child{ margin-bottom: 0!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .t77am5, .d624lk{ margin: 4px 0 0 0!important }
    .d3726r{ margin: 18px 0 0 0!important }
    .m176ye, .n959k8, .o9l44l .w84z88, .w000w8, .qo3320, .i31t7q, .m6747x .h5l5y9{ margin: 8px 0 0 0!important }
    .w8M19n, .q317to, .d7fv32, .u04m7q, .t50h35{ margin: 10px 0 0 0!important }
    .b584xj{ margin: 12px 0 0 18px!important }
    .n90bv9 .r3Bx93, .m505cz{ margin: 12px 0 0 0!important }
    .p2769m .b98w7p .p72qx6{ margin-right: 12px!important }
    .c250ba .container .row .col-md-2:nth-child(5) .o720ha, .h68k88, .c250ba .container .row .col-md-2:nth-child(6) .o720ha, .e745l2, .v7672m .container .row .col-md-12 .col-md-4 .i700xb{ margin: 14px 0 0 0!important }
    .p2769m{ margin: 14px 0 0 0!important }
    .y15u5Q li span, .k51l81{ margin: 15px 0 0 0!important }
    .p2769m .b98w7p, .e99v68, .s3zb07 .container .row .col-md-10 .row .col-md-4:first-child .br6312, .b384t5{ margin: 18px 0 0 0!important }
    .b0e620 .container .row .col-md-2:nth-child(5) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(6) .z9w60a, .n98kk4, .y76v0s, .Up955K{ margin: 20px 0 0 0!important }
    .m6083e, .p2769m .b98w7p, .m6747x .container .row .col-md-4:last-child .h5l5y9{ margin-top: 24px!important }
    .v5t92l{ margin-bottom: 24px!important }
    .g64my5 .m505cz, .z9w60a, .g65z5u .h405q4 .d540uf, .q35322, .t883f0{ margin: 24px 0 0 0!important }
    .n90bv9 .a407p5{ margin: 30px 0 0 0!important }
    .a419o0 .i1888e{ margin-bottom: 36px!important }
    .c250ba .container .row .col-md-2 .o720ha, .k791vc, .a65254, .i700xb{ margin: 32px 0 0 0!important }
    .x35f74 .r89a95{ margin-bottom: 36px!important }
    #main_Middle, #main_Internet, #main_Success, #main_Internet{ margin: 36px 0 0 0!important }
    .m6747x{ margin: 40px 0 0 0!important }
    .ac1099, .sI631f, .n5a38b, .s3zb07{ margin: 48px 0 0 0!important }
    .x35f74 .r89a95, .a917yq, .v5t92l{ box-shadow: none!important }
    .t77am5, .y76v0s{ padding: 0!important }
    .r307z9::before, .bs0p47::before{ padding: 0 0 0 1px!important }
    .i63421 tbody tr:nth-child(1) td:nth-child(1), .i63421 tbody tr:nth-child(2) td:nth-child(1), .i63421 tbody tr:nth-child(3) td:nth-child(1), .i63421 tbody tr:nth-child(4) td:nth-child(1), .i63421 tbody tr:nth-child(5) td:nth-child(1), .i63421 thead tr th:nth-child(1){ padding: 0 0 0 12px!important }
    .o9l44l:last-child{ padding: 14px 0 0 0!important }
    .q76j70{ padding: 18px 0 !important }
    .z48376{ padding-left: 18px!important }
    #React_A01NB8, #React_B694LU{ padding-top: 18px!important }
    .y62e43{ padding: 32px 48px!important }
    .v7q534{ padding-bottom: 42px!important }
    .c68146{ padding-top: 48px!important }
    .k791vc{ padding-bottom: 320px!important }
    .q35h02, .c28g97, .m6083e, .o49df7, .t4q297, .z2499o{ line-height: 1!important }
    .y15u5Q li span strong{ line-height: 16px!important }
    .w8M19n, .n959k8, .q317to, .m176ye{ line-height: 20px!important }
    .s194hd{ line-height: 21px!important }
    .v976lh, .t77am5, .u04m7q{ line-height: 22px!important }
    .o4e46u, .d624lk, .m1364c{ line-height: 23px!important }
    .e99v68, .i31t7q, .qo3320, .w000w8, .q892wd{ line-height: 24px!important }
    .g30798, .e745l2, .c250ba .container .row .col-md-2 .m8m73h{ line-height: 25px!important }
    .q20g3c{ line-height: 26px!important }
    .v7q534{ border-bottom: 1px solid var(--css-m02l09) }
    .n97qf5{ position: absolute!important }
    .k791vc{ position: relative!important }
    .k791vc{ float: none!important }
    .u04m7q{ text-align: start!important }
}
@media screen and (min-width:500px) and (max-width:599px){
    .w000w8 br, .u04m7q br, .i63421 thead tr th:nth-child(3), .i63421 thead tr th:nth-child(4), .i63421 tbody tr td:nth-child(3), .i63421 tbody tr td:nth-child(4), .q35322::before{ display: none!important }
    .q892wd, .t77am5{ display: -webkit-box }
    .a419o0 .i1888e{ display: flex }
    #Career_Table thead tr th:nth-child(3), #Career_Table tbody tr td:nth-child(3){ display: block!important }
    .j0r112 .b98w7p{ width: auto!important }
    .y15u5Q li span i svg{ width: 8px!important }
    .m176ye svg, .j0z12w svg{ width: 15px!important }
    .y98o1m li a svg, .u4025u li label svg{ width: 16px!important }
    .v7c42n svg, .n959k8 svg, .y15u5Q li span i, .y347yn svg{ width: 17px!important }
    .m24ho4 img, .e88a72 svg{ width: 18px!important }
    .r89a95 img, .v5t92l img{ width: 19px!important }
    .q928g8 svg, .v10846 svg, .o840cp img{ width: 21px!important }
    .f062xc img{ width: 23px!important }
    .s5c45j svg{ width: 38px!important }
    .a419o0 .i1888e{ width: 48px!important }
    .h33p0j img{ width: 90px!important }
    .Up955K img{ width: 124px!important }
    .m505cz .row .col-md-1{ width: 25%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3, .b0e620 .container .row .col-md-2, .c250ba .container .row .col-md-2{ width: 33.3%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-3:last-child, .e745l2, .e745l2::before, .c250ba .container .row .col-md-2 .m8m73h, .qo3320::before, .z48376, .t53z62, .o1m43l, .b39yo6 img, .i31t7q::before, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, #main_Internet .qo3320::before, .g30798, .o01a97 img, .i63421 thead tr th span, .i63421 tbody tr td label, .q35322::before, .u61409, .q76j70, .z2499o, .q76j70, .q20g3c, .d624lk, .w057op .row .col-md-6, .m6747x .container .row .col-md-4{ width: 100%!important }
    .h33p0j, .u4025u li label{ min-width: auto!important }
    .p72qx6, .o840cp{ min-width: 100%!important }
    .d7fv32{ min-width: 180px!important }
    .e88a72, .y347yn{ max-width: 38px!important }
    .p72qx6 img{ max-width: 90px }
    .k51l81{ max-width: 165px!important }
    .d7fv32{ max-width: 175px!important }
    .q76j70{ max-width: 463px!important }
    .n97qf5{ height: 100%!important }
    .c741h7, .po7670, .p2769m, .p72qx6, .h33p0j, .h33p0j img, .b39yo6 img, #main_Internet, .a419o0, .p72qx6 img, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .o840cp, .e74i23, .a65254, .d3726r, .o01a97 img, .n98kk4, .y76v0s, .q35322::before, .i700xb{ height: auto!important }
    .y15u5Q li span i svg{ height: 8px!important }
    .m176ye svg, .y15u5Q li span, .j0z12w svg{ height: 15px!important }
    .y98o1m li a svg, .u4025u li label svg{ height: 16px!important }
    .n959k8 svg, .v7c42n svg, .y15u5Q li span i, .y347yn svg{ height: 17px!important }
    .m24ho4 img, .e88a72 svg{ height: 18px!important }
    .r89a95 img, .v5t92l img{ height: 19px!important }
    .q928g8 svg, .v10846 svg, .o840cp img{ height: 21px!important }
    .f062xc img{ height: 23px!important }
    .o091hh{ height: 30px!important }
    .s5c45j svg, .n772zy, .e88a72, .y347yn{ height: 38px!important }
    .k51l81, .b584xj{ height: 40px!important }
    .d7fv32{ height: 42px!important }
    .a419o0 .i1888e{ height: 48px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ height: 52px!important }
    .a917yq{ height: 76.8px!important }
    .z48376{ height: 90px!important }
    .g65z5u .m505cz .hl280a .a917yq{ height: 98px!important }
    .Up955K img{ height: 124px!important }
    #main_Middle .z93q7k, #main_Internet .z93q7k, #main_Success .z93q7k, .x63112, .x4i0g2{ height: 200px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-height: 300px }
    .d540uf{ min-height: auto!important }
    .e745l2, .qo3320, #main_Internet .i31t7q{ line-height: 0!important }
    .m6083e, .x661n3, .g4zf86 .e99v68, .c28g97, .q35h02, .c28g97, .t4q297, .z2499o{ line-height: 1!important }
    .y15u5Q li span strong{ line-height: 17px!important }
    .w8M19n, .n959k8, .q317to, .m176ye{ line-height: 20px!important }
    .t77am5{ line-height: 21px!important }
    #main_Internet .qo3320::before, #main_Success .qo3320::before, .i31t7q::before, .w000w8, .u04m7q, .o4e46u, .s194hd, .d624lk, .m1364c{ line-height: 23px!important }
    .e745l2::before, .qo3320::before, .q892wd{ line-height: 24px!important }
    .c250ba .container .row .col-md-2 .m8m73h, .g30798{ line-height: 25px!important }
    .q20g3c{ line-height: 26px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ line-height: 52px!important }
    .n772zy, .b584xj{ border-radius: 38px!important }
    .k51l81{ border-radius: 40px!important }
    .a419o0 .i1888e{ border-radius: 100%!important }
    .n959k8, .i63421 thead tr th span, .i63421 tbody tr td label{ font-size: 11.5px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .m176ye{ font-size: 12px!important }
    .x661n3{ font-size: 12.2px!important }
    .o9l44l .w84z88, .f07622, .e76r8a, .n90bv9 .a407p5{ font-size: 12.5px!important }
    .w000w8{ font-size: 12.9px!important }
    .o188l9, .c250ba .container .row .col-md-2 .m8m73h, .v976lh, .t77am5, .y15u5Q li span strong, .g30798, .o4e46u, .z9y50r, .s194hd, .pea034{ font-size: 13px!important }
    .d624lk{ font-size: 13.3px!important }
    .x0j627 li a, .y98o1m li a, .qo3320::before, .t53z62, .i31t7q::before, #main_Internet .qo3320::before, .q746r5, .u04m7q, .z493ea li a, .t027vd, .t456c4, .c68146, .m1364c{ font-size: 13.5px!important }
    .e745l2{ font-size: 13.8px!important }
    .q892wd, .g4zf86 .e99v68, .r22m60, .k8716o, .rm263w{ font-size: 14px!important }
    .s63448, .v406f3, .w432xg{ font-size: 15px!important }
    .c250ba .container .row .col-md-2 .o50h0d, .i745hd, .w0r597, .q20g3c{ font-size: 16px!important }
    .z2499o, .o49df7, .t4q297{ font-size: 17px!important }
    .m6083e, .q35h02, .c28g97, .p5o2a6{ font-size: 18px!important }
    .m97p51{ font-size: 124px!important }
    .q892wd, .t77am5{ -webkit-box-orient: vertical }
    .q892wd, .t77am5{ -webkit-line-clamp: 3 }
    .v7q534, .z93q7k, .x63112, .d01z5h, .i700xb{ align-items: start!important }
    .z48376, .a419o0 .i1888e, .q76j70{ align-items: center }
    .d01iq4{ justify-content: start!important }
    .p2769m, .z48376, .a419o0 .i1888e{ justify-content: center!important }
    .c741h7, .a419o0, .a65254{ justify-content: space-between!important }
    .c741h7, .p2769m, .a419o0, .a65254{ flex-direction: row!important }
    .p796s4{ flex-direction: column!important }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .q892wd, .o1m43l, .t77am5, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4{ overflow: hidden }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4{ white-space: nowrap }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4{ text-overflow: ellipsis }
    .e745l2, .u04m7q{ text-align: start!important }
    .pi4981, .r89a95, .qo3320::before, .b98w7p, .a419o0 .i1888e, .i31t7q::before, #main_Internet .qo3320::before, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .po7670, .y0422f .container .row .offset-md-4, .h405q4 .container .row .col-md-3:first-child .d540uf, .y7367g, .u4025u li{ margin: 0!important }
    .w728x2 .container .row .col-md-3:first-child .b384t5, .qcc560{ margin-top: 0!important }
    .y15u5Q li span, .t4q297, .w432xg, .q20g3c{ margin-bottom: 0!important }
    .p2769m .b98w7p:last-child{ margin-right: 0!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .u4025u li span{ margin: 0 0 0 8px!important }
    .y15u5Q li span i svg{ margin: .5px 0 0 0!important }
    .m176ye svg{ margin: -1px 8px 0 0!important }
    .n959k8 svg{ margin: -2px 8px 0 0!important }
    .o9l44l .w84z88, .m176ye, .n959k8, .qo3320, .v976lh, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(2) .s63448, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(3) .s63448, .w0r597, .r74wm6, .q20g3c{ margin: 8px 0 0 0!important }
    .w8M19n, .q317to, .pea034{ margin: 10px 0 0 0!important }
    .n90bv9 .r3Bx93, .k51l81, .w000w8, .e745l2, .o4e46u, .n98kk4, .d7fv32, .q76j70{ margin: 12px 0 0 0!important }
    .t079x8{ margin-bottom: 12px!important }
    .e745l2{ margin: 12px 0 0 0!important }
    .b584xj{ margin: 14px 0 0 18px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ margin: 16px 0 0 0!important }
    .p2769m .b98w7p{ margin-right: 16px!important }
    #React_A01NB8 .row .col-md-6:nth-child(2) .b384t5, #React_B694LU .row .col-md-6:nth-child(2) .b384t5{ margin-top: 16px!important }
    .c250ba .container .row .col-md-2:nth-child(4) .o720ha, .c250ba .container .row .col-md-2:nth-child(5) .o720ha, .h68k88, .c250ba .container .row .col-md-2:nth-child(6) .o720ha, .y15u5Q li:first-child span, .h33p0j, .z48376, .s63448, .g4zf86 .e99v68{ margin: 18px 0 0 0!important }
    .h68k88{ margin-bottom: 18px!important }
    .b0e620 .container .row .col-md-2:nth-child(4) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(5) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(6) .z9w60a{ margin: 18px 0 0 0!important }
    .m6083e, #main_Middle, .sI631f, .ac1099, #main_Internet, .j0r112 .container .row .col-md-3 .row .col-md-6:last-child .p2769m, .e10di5, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .m505cz .hl280a, .d3726r, .i700xb, .t883f0{ margin-top: 24px!important }
    .b0e620 .container .row .col-md-2 .z9w60a, .j0r112{ margin: 24px 0 0 0!important }
    .h4s606, .k791vc, .Up955K{ margin: 24px 0!important }
    .n90bv9 .a407p5, .y76v0s, .d540uf, .v5t92l{ margin: 30px 0 0 0!important }
    .c741h7, .c250ba .container .row .col-md-2 .o720ha, .a419o0, .j71bv3, .a9680l{ margin-top: 32px!important }
    .r87933{ margin-top: 42px!important }
    .g65z5u .Select{ margin: 80px 0 0 0!important }
    .z48376, .t77am5, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y76v0s, .q76j70{ padding: 0!important }
    .ja605u{ padding: 0 24px!important }
    .r87933{ padding-left: 4px!important }
    .o9l44l:last-child{ padding: 14px 0 0 0!important }
    .u4025u{ padding-top: 16px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ padding-bottom: 16px!important }
    .y98o1m{ padding-bottom: 18px!important }
    .y62e43{ padding: 18px 24px 18px 24px!important }
    .z48376{ padding-left: 24px!important }
    .c741h7{ padding-top: 24px!important }
    #React_A01NB8, #React_B694LU{ padding: 32px 0 0 0!important }
    .v7q534{ padding-bottom: 32px!important }
    .w728x2 .container .row .col-md-3:first-child .ck3902{ padding-top: 48px!important }
    .k791vc{ padding-bottom: 320px!important }
    .k791vc{ position: relative!important }
    .n97qf5{ position: absolute!important }
    .e745l2{ color: #FFFFFF!important }
    .qo3320, #main_Internet .i31t7q{ color: #F4F4F4!important }
    .e745l2::before, .qo3320::before, .i31t7q::before, #main_Internet .qo3320::before{ color: #3E3F41!important }
    #main_Middle, #main_Internet, #main_Success{ background: #F4F4F4!important }
    .e745l2::before, .qo3320::before, .i31t7q::before, #main_Internet .qo3320::before{ float: left!important }
    .k791vc{ float: none!important }
    .z493ea li a::after{ content: url( "./Media/Icon/016.svg" )!important }
    .v7q534, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ border-bottom: 1px solid var(--css-m02l09) }
    .c741h7{ border-top: 1px solid var(--css-m02l09) }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border: 0!important }
    .a419o0 .i1888e{ box-shadow: var(--css-j71y4n) }
    .e745l2::before{ content: "Ben Özgür Tosun, 7 Farklı Bölge'de, 68+ Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım." }
    .qo3320::before{ content: "Bugün 7 Bölge'de, 68 Şehir'de, 5.000 İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım." }
    #main_Internet .i31t7q::before{ content: "Bugün 7 Bölge'de, 68 Şehir'de, 5.000 İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım." }
    #main_Internet .qo3320::before{ content: "Profesyonel bir web tasarım ile müşteri tabanınızı genişleterek dijital varlığınızı güçlendirin ve müşteri kitlenizi genişletin." }
    #main_Success .qo3320::before{ content: "Müşteri tabanınızı büyütmek ve çevrimiçi varlığınızı güçlendirmek için profesyonel bir web tasarım ile sınırları zorlayın." }
    #main_Internet .i31t7q::before{ content: "Benimle çalışan işletmelere; değer katan bu markalar, küçük işletmelere başarılı olabilmeleri için gereken desteği ve araçları sunar." }
}
@media screen and (min-width:480px) and (max-width:499px){
    .w000w8 br, .u04m7q br, .i63421 thead tr th:nth-child(3), .i63421 thead tr th:nth-child(4), .i63421 tbody tr td:nth-child(3), .i63421 tbody tr td:nth-child(4), .q35322::before{ display: none!important }
    .q892wd, .t77am5{ display: -webkit-box }
    .a419o0 .i1888e, .se5658{ display: flex }
    #Career_Table thead tr th:nth-child(3), #Career_Table tbody tr td:nth-child(3){ display: block!important }
    .j0r112 .b98w7p{ width: auto!important }
    .y15u5Q li span i svg{ width: 8px!important }
    .m176ye svg, .j0z12w svg{ width: 15px!important }
    .y98o1m li a svg, .u4025u li label svg, .y347yn svg, .x0j627 li a svg{ width: 16px!important }
    .v7c42n svg, .n959k8 svg, .y15u5Q li span i, .e88a72 svg{ width: 17px!important }
    .m24ho4 img, .r89a95 img, .v5t92l img{ width: 18px!important }
    .q928g8 svg, .v10846 svg{ width: 21px!important }
    .o840cp img{ width: 22px!important }
    .f062xc img{ width: 23px!important }
    .s5c45j svg{ width: 38px!important }
    .a419o0 .i1888e{ width: 48px!important }
    .h33p0j img{ width: 84px!important }
    .Up955K img{ width: 124px!important }
    .m505cz .row .col-md-1{ width: 25%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3, .b0e620 .container .row .col-md-2, .c250ba .container .row .col-md-2{ width: 33.3%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-3:last-child, .e745l2, .e745l2::before, .c250ba .container .row .col-md-2 .m8m73h, .qo3320::before, .z48376, .t53z62, .o1m43l, .b39yo6 img, .i31t7q::before, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, #main_Internet .qo3320::before, .g30798, .o01a97 img, .i63421 thead tr th span, .i63421 tbody tr td label, .q35322::before, .u61409, .q76j70, .z2499o, .q76j70, .q20g3c, .d624lk, .w057op .row .col-md-6, .m6747x .container .row .col-md-4, .n98kk4 iframe{ width: 100%!important }
    .h33p0j, .u4025u li label{ min-width: auto!important }
    .p72qx6, .o840cp{ min-width: 100%!important }
    .d7fv32{ min-width: 180px!important }
    .e88a72, .y347yn{ max-width: 38px!important }
    .p72qx6 img{ max-width: 84px }
    .k51l81{ max-width: 165px!important }
    .d7fv32{ max-width: 175px!important }
    .q76j70{ max-width: 463px!important }
    .n97qf5, .n98kk4 iframe{ height: 100%!important }
    .c741h7, .po7670, .p2769m, .p72qx6, .h33p0j, .h33p0j img, .b39yo6 img, #main_Internet, .a419o0, .p72qx6 img, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .o840cp, .e74i23, .a65254, .d3726r, .o01a97 img, .y76v0s, .q35322::before, .i700xb{ height: auto!important }
    .n98kk4{ height: 0!important }
    .y15u5Q li span i svg{ height: 8px!important }
    .m176ye svg, .y15u5Q li span, .j0z12w svg{ height: 15px!important }
    .y98o1m li a svg, .u4025u li label svg, .y347yn svg, .x0j627 li a svg{ height: 16px!important }
    .n959k8 svg, .v7c42n svg, .y15u5Q li span i, .e88a72 svg{ height: 17px!important }
    .m24ho4 img, .r89a95 img, .v5t92l img{ height: 18px!important }
    .q928g8 svg, .v10846 svg{ height: 21px!important }
    .o840cp img{ height: 22px!important }
    .f062xc img{ height: 23px!important }
    .o091hh{ height: 30px!important }
    .s5c45j svg, .n772zy, .e88a72, .y347yn{ height: 38px!important }
    .k51l81, .b584xj{ height: 40px!important }
    .d7fv32{ height: 42px!important }
    .a419o0 .i1888e{ height: 48px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ height: 52px!important }
    .a917yq{ height: 91.8px!important }
    .z48376{ height: 84px!important }
    .g65z5u .m505cz .hl280a .a917yq{ height: 91.8px!important }
    .Up955K img{ height: 124px!important }
    #main_Middle .z93q7k, #main_Internet .z93q7k, #main_Success .z93q7k, .x63112, .x4i0g2{ height: 200px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-width: 325px }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-height: 247px }
    .d540uf{ min-height: auto!important }
    .e745l2, .qo3320, #main_Internet .i31t7q{ line-height: 0!important }
    .m6083e, .x661n3, .g4zf86 .e99v68, .c28g97, .q35h02, .c28g97, .t4q297, .z2499o{ line-height: 1!important }
    .y15u5Q li span strong{ line-height: 17px!important }
    .w8M19n, .n959k8, .q317to, .m176ye{ line-height: 20px!important }
    .t77am5{ line-height: 21px!important }
    #main_Internet .qo3320::before, #main_Success .qo3320::before, .w000w8, .u04m7q, .o4e46u, .s194hd, .d624lk, .m1364c, .i31t7q::before{ line-height: 23px!important }
    .qo3320::before, .q892wd{ line-height: 24px!important }
    .c250ba .container .row .col-md-2 .m8m73h, .g30798, .e745l2::before{ line-height: 25px!important }
    .q20g3c{ line-height: 26px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ line-height: 52px!important }
    .n772zy, .b584xj{ border-radius: 38px!important }
    .k51l81{ border-radius: 40px!important }
    .a419o0 .i1888e{ border-radius: 100%!important }
    .n959k8, .i63421 thead tr th span, .i63421 tbody tr td label{ font-size: 11.5px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .m176ye{ font-size: 12px!important }
    .x661n3{ font-size: 12.2px!important }
    .o9l44l .w84z88, .f07622, .e76r8a, .n90bv9 .a407p5, .c250ba .container .row .col-md-2 .m8m73h{ font-size: 12.5px!important }
    .o188l9, .t77am5, .w000w8, .y15u5Q li span strong, .g30798, .o4e46u, .z9y50r, .s194hd, .pea034, .t027vd, .t456c4{ font-size: 13px!important }
    .v976lh{ font-size: 13.2px!important }
    .d624lk{ font-size: 13.3px!important }
    .x0j627 li a, .y98o1m li a, .e745l2, .qo3320::before, .t53z62, #main_Internet .qo3320::before, .q746r5, .u04m7q, .z493ea li a, .c68146, .m1364c, .k8716o, .rm263w{ font-size: 13.5px!important }
    .i31t7q::before{ font-size: 13.8px!important }
    .q892wd, .g4zf86 .e99v68, .r22m60{ font-size: 14px!important }
    .s63448, .v406f3, .w432xg, .c250ba .container .row .col-md-2 .o50h0d, .i745hd{ font-size: 15px!important }
    .w0r597{ font-size: 16px!important }
    .z2499o, .o49df7, .t4q297, .q20g3c, .p5o2a6{ font-size: 17px!important }
    .m6083e, .q35h02, .c28g97{ font-size: 18px!important }
    .m97p51{ font-size: 124px!important }
    .q892wd, .t77am5{ -webkit-box-orient: vertical }
    .q892wd, .t77am5{ -webkit-line-clamp: 3 }
    .v7q534, .z93q7k, .x63112, .d01z5h, .i700xb{ align-items: start!important }
    .z48376, .a419o0 .i1888e, .q76j70, .se5658{ align-items: center!important }
    .d01iq4{ justify-content: start!important }
    .p2769m, .z48376, .a419o0 .i1888e, .se5658{ justify-content: center!important }
    .c741h7, .a419o0, .a65254{ justify-content: space-between!important }
    .c741h7, .p2769m, .a419o0, .a65254{ flex-direction: row!important }
    .p796s4{ flex-direction: column!important }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .q892wd, .o1m43l, .t77am5, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4{ overflow: hidden }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4{ white-space: nowrap }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4{ text-overflow: ellipsis }
    .e745l2, .u04m7q{ text-align: start!important }
    .pi4981, .r89a95, .qo3320::before, .b98w7p, .a419o0 .i1888e, .i31t7q::before, #main_Internet .qo3320::before, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .po7670, .y0422f .container .row .offset-md-4, .h405q4 .container .row .col-md-3:first-child .d540uf, .y7367g, .u4025u li{ margin: 0!important }
    .w728x2 .container .row .col-md-3:first-child .b384t5, .qcc560{ margin-top: 0!important }
    .y15u5Q li span, .t4q297, .w432xg, .q20g3c{ margin-bottom: 0!important }
    .p2769m .b98w7p:last-child{ margin-right: 0!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .u4025u li span{ margin: 0 0 0 8px!important }
    .y15u5Q li span i svg{ margin: .5px 0 0 0!important }
    .m176ye svg{ margin: -1px 8px 0 0!important }
    .n959k8 svg{ margin: -2px 8px 0 0!important }
    .o9l44l .w84z88, .m176ye, .n959k8, .qo3320, .v976lh, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(2) .s63448, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(3) .s63448, .w0r597, .r74wm6, .q20g3c{ margin: 8px 0 0 0!important }
    .w8M19n, .q317to, .pea034, .c37dc8{ margin: 10px 0 0 0!important }
    .n90bv9 .r3Bx93, .k51l81, .w000w8, .e745l2, .o4e46u, .n98kk4, .d7fv32, .q76j70{ margin: 12px 0 0 0!important }
    .t079x8{ margin-bottom: 12px!important }
    .e745l2{ margin: 12px 0 0 0!important }
    .b584xj{ margin: 14px 0 0 12px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ margin: 16px 0 0 0!important }
    .p2769m .b98w7p{ margin-right: 16px!important }
    #React_A01NB8 .row .col-md-6:nth-child(2) .b384t5, #React_B694LU .row .col-md-6:nth-child(2) .b384t5{ margin-top: 16px!important }
    .c250ba .container .row .col-md-2:nth-child(4) .o720ha, .c250ba .container .row .col-md-2:nth-child(5) .o720ha, .h68k88, .c250ba .container .row .col-md-2:nth-child(6) .o720ha, .y15u5Q li:first-child span, .h33p0j, .z48376, .s63448, .g4zf86 .e99v68{ margin: 18px 0 0 0!important }
    .h68k88{ margin-bottom: 18px!important }
    .b0e620 .container .row .col-md-2:nth-child(4) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(5) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(6) .z9w60a{ margin: 18px 0 0 0!important }
    .m6083e, #main_Middle, .sI631f, .ac1099, #main_Internet, .j0r112 .container .row .col-md-3 .row .col-md-6:last-child .p2769m, .e10di5, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .m505cz .hl280a, .d3726r, .i700xb, .t883f0{ margin-top: 24px!important }
    .b0e620 .container .row .col-md-2 .z9w60a, .j0r112{ margin: 24px 0 0 0!important }
    .h4s606, .k791vc, .Up955K{ margin: 24px 0!important }
    .n90bv9 .a407p5, .y76v0s, .d540uf, .v5t92l{ margin: 30px 0 0 0!important }
    .c741h7, .c250ba .container .row .col-md-2 .o720ha, .a419o0, .j71bv3, .a9680l{ margin-top: 32px!important }
    .r87933{ margin-top: 42px!important }
    .g65z5u .Select{ margin: 80px 0 0 0!important }
    .z48376, .t77am5, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y76v0s, .q76j70{ padding: 0!important }
    .ja605u{ padding: 0 24px!important }
    .r87933{ padding-left: 4px!important }
    .o9l44l:last-child{ padding: 14px 0 0 0!important }
    .u4025u{ padding-top: 16px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ padding-bottom: 16px!important }
    .y98o1m{ padding-bottom: 18px!important }
    .y62e43{ padding: 18px 24px 18px 24px!important }
    .z48376{ padding-left: 24px!important }
    .c741h7{ padding-top: 24px!important }
    #React_A01NB8, #React_B694LU{ padding: 32px 0 0 0!important }
    .v7q534{ padding-bottom: 32px!important }
    .w728x2 .container .row .col-md-3:first-child .ck3902{ padding-top: 48px!important }
    .k791vc{ padding-bottom: 320px!important }
    .n98kk4{ padding-bottom: 56.25% !important }
    .k791vc, .n98kk4{ position: relative!important }
    .n97qf5, .n98kk4 iframe{ position: absolute!important }
    .n98kk4 iframe{ left: 0 }
    .n98kk4 iframe{ top: 0 }
    .e745l2{ color: #FFFFFF!important }
    .qo3320, #main_Internet .i31t7q{ color: #F4F4F4!important }
    .e745l2::before, .qo3320::before, .i31t7q::before, #main_Internet .qo3320::before{ color: #3E3F41!important }
    #main_Middle, #main_Internet, #main_Success{ background: #F4F4F4!important }
    .e745l2::before, .qo3320::before, .i31t7q::before, #main_Internet .qo3320::before{ float: left!important }
    .k791vc{ float: none!important }
    .z493ea li a::after{ content: url( "./Media/Icon/016.svg" )!important }
    .v7q534, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ border-bottom: 1px solid var(--css-m02l09) }
    .c741h7{ border-top: 1px solid var(--css-m02l09) }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border: 0!important }
    .a419o0 .i1888e{ box-shadow: var(--css-j71y4n) }
    .e745l2::before{ content: "Ben Özgür Tosun, 7 Farklı Bölge'de, 68 Şehir'de, 5.000 Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım." }
    .qo3320::before{ content: "Bugün, 68+ Şehir'de, 5.000+ Farklı İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektör'de 12. Yılımı Geride Bıraktım." }
    #main_Internet .i31t7q::before{ content: "Bugün 7 Bölge'de, 68 Şehir'de, 5.000 İşletme İçin Hizmet Sunabilmenin Mutluluğu İle Sektörde 12. Yılımı Geride Bıraktım." }
    #main_Internet .qo3320::before{ content: "Profesyonel bir web tasarım ile müşteri tabanınızı genişleterek dijital varlığınızı güçlendirin ve müşteri kitlenizi genişletin." }
    #main_Success .qo3320::before{ content: "Müşteri tabanınızı büyütmek ve çevrimiçi varlığınızı güçlendirmek için profesyonel bir web tasarım ile sınırları zorlayın." }
    #main_Internet .i31t7q::before{ content: "İş ortaklarıma değer katan bu küresel markalar, işletmelerin başarılı olmaları için gerekli destek ve araçları sağlar." }
}
@media screen and (min-width:416px) and (max-width:479px){
    .w000w8 br, .u04m7q br, .i63421 thead tr th:nth-child(3), .i63421 thead tr th:nth-child(4), .i63421 tbody tr td:nth-child(3), .i63421 tbody tr td:nth-child(4), .q35322::before, .z7h704{ display: none!important }
    .q892wd, .t77am5{ display: -webkit-box }
    .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ display: flex }
    #Career_Table thead tr th:nth-child(3), #Career_Table tbody tr td:nth-child(3){ display: block!important }
    .j0r112 .b98w7p{ width: auto!important }
    .y15u5Q li span i svg{ width: 8px!important }
    .m176ye svg, .j0z12w svg{ width: 15px!important }
    .y98o1m li a svg, .u4025u li label svg, .y347yn svg, .v7c42n svg, .y15u5Q li span i, .m24ho4 img{ width: 16px!important }
    .n959k8 svg, .e88a72 svg, .r89a95 img{ width: 17px!important }
    .v5t92l img{ width: 18px!important }
    .q928g8 svg, .v10846 svg{ width: 20px!important }
    .o840cp img{ width: 21px!important }
    .f062xc img{ width: 23px!important }
    .s5c45j svg{ width: 38px!important }
    .a419o0 .i1888e{ width: 48px!important }
    .h33p0j img{ width: 80px!important }
    .Up955K img{ width: 100px!important }
    .m505cz .row .col-md-1{ width: 25%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3, .b0e620 .container .row .col-md-2, .c250ba .container .row .col-md-2{ width: 33.3%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-3:last-child, .e745l2, .e745l2::before, .c250ba .container .row .col-md-2 .m8m73h, .z48376, .t53z62, .o1m43l, .b39yo6 img, .i31t7q::before, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .g30798, .o01a97 img, .i63421 thead tr th span, .i63421 tbody tr td label, .q35322::before, .u61409, .q76j70, .z2499o, .q76j70, .q20g3c, .d624lk, .w057op .row .col-md-6, .m6747x .container .row .col-md-4, .z2596n{ width: 100%!important }
    .h33p0j, .u4025u li label{ min-width: auto!important }
    .p72qx6, .o840cp{ min-width: 100%!important }
    .d7fv32{ min-width: 175px!important }
    .e88a72, .y347yn{ max-width: 38px!important }
    .p72qx6 img{ max-width: 80px }
    .k51l81{ max-width: 165px!important }
    .d7fv32{ max-width: 175px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-width: 325px }
    .q76j70{ max-width: 463px!important }
    .n97qf5{ height: 100%!important }
    .c741h7, .po7670, .p2769m, .p72qx6, .h33p0j, .h33p0j img, .b39yo6 img, #main_Internet, .a419o0, .p72qx6 img, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .o840cp, .e74i23, .a65254, .d3726r, .o01a97 img, .n98kk4, .y76v0s, .q35322::before, .i700xb{ height: auto!important }
    .y15u5Q li span i svg{ height: 8px!important }
    .m176ye svg, .y15u5Q li span, .j0z12w svg{ height: 15px!important }
    .y98o1m li a svg, .u4025u li label svg, .y347yn svg, .v7c42n svg, .y15u5Q li span i, .m24ho4 img{ height: 16px!important }
    .n959k8 svg, .e88a72 svg, .r89a95 img{ height: 17px!important }
    .v5t92l img{ height: 18px!important }
    .q928g8 svg, .v10846 svg{ height: 20px!important }
    .o840cp img{ height: 21px!important }
    .f062xc img{ height: 23px!important }
    .o091hh{ height: 30px!important }
    .s5c45j svg, .n772zy, .e88a72, .y347yn, .k51l81{ height: 38px!important }
    .b584xj{ height: 40px!important }
    .d7fv32{ height: 42px!important }
    .a419o0 .i1888e{ height: 48px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ height: 52px!important }
    .a917yq{ height: 79.8px!important }
    .z48376{ height: 80px!important }
    .g65z5u .m505cz .hl280a .a917yq{ height: 79.8px!important }
    .Up955K img{ height: 100px!important }
    #main_Middle .z93q7k, #main_Internet .z93q7k, #main_Success .z93q7k, .x63112, .x4i0g2{ height: 200px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-height: 247px }
    .d540uf{ min-height: auto!important }
    .m6083e, .x661n3, .g4zf86 .e99v68, .c28g97, .q35h02, .c28g97, .t4q297, .z2499o{ line-height: 1!important }
    .y15u5Q li span strong{ line-height: 17px!important }
    .w8M19n, .n959k8, .q317to, .m176ye{ line-height: 20px!important }
    .t77am5{ line-height: 21px!important }
    .i31t7q::before, .w000w8{ line-height: 22px!important }
    .qo3320, .i31t7q, .u04m7q{ line-height: 22.5px!important }
    .o4e46u, .s194hd, .m1364c, .q892wd{ line-height: 23px!important }
    .d624lk{ line-height: 23.5px!important }
    .e745l2::before, .e745l2, .g30798{ line-height: 24px!important }
    .c250ba .container .row .col-md-2 .m8m73h{ line-height: 25px!important }
    .q20g3c{ line-height: 26px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ line-height: 52px!important }
    .n772zy, .b584xj{ border-radius: 38px!important }
    .k51l81{ border-radius: 40px!important }
    .a419o0 .i1888e{ border-radius: 100%!important }
    .n959k8, .i63421 thead tr th span, .i63421 tbody tr td label{ font-size: 11.5px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .m176ye, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798{ font-size: 12px!important }
    .x661n3{ font-size: 12.2px!important }
    .o9l44l .w84z88, .f07622, .e76r8a, .n90bv9 .a407p5{ font-size: 12.5px!important }
    .o188l9, .v976lh, .t77am5, .w000w8, .y15u5Q li span strong, .o4e46u, .z9y50r, .s194hd, .pea034, .t027vd, .t456c4, .t53z62{ font-size: 13px!important }
    .d624lk{ font-size: 13.3px!important }
    .x0j627 li a, .y98o1m li a, .e745l2, .i31t7q::before, .q746r5, .u04m7q, .z493ea li a, .c68146, .m1364c, .k8716o, .rm263w, .qo3320, .q892wd, .i31t7q{ font-size: 13.5px!important }
    .g4zf86 .e99v68, .r22m60, .s63448{ font-size: 14px!important }
    .v406f3, .w432xg, .c250ba .container .row .col-md-2 .o50h0d{ font-size: 15px!important }
    .w0r597, .i745hd{ font-size: 16px!important }
    .z2499o, .o49df7, .t4q297, .q20g3c{ font-size: 17px!important }
    .m6083e, .q35h02, .c28g97, .p5o2a6{ font-size: 17.5px!important }
    .m97p51{ font-size: 110px!important }
    .q892wd, .t77am5{ -webkit-box-orient: vertical }
    .q892wd, .t77am5{ -webkit-line-clamp: 3 }
    .z93q7k, .x63112, .d01z5h, .i700xb{ align-items: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ align-items: center!important }
    .d01iq4{ justify-content: start!important }
    .p2769m, .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ justify-content: center!important }
    .c741h7, .a419o0, .a65254{ justify-content: space-between!important }
    .c741h7, .p2769m, .a419o0, .a65254{ flex-direction: row!important }
    .p796s4{ flex-direction: column!important }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .q892wd, .o1m43l, .t77am5, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622{ overflow: hidden }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622{ white-space: nowrap }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622{ text-overflow: ellipsis }
    .e745l2, .u04m7q{ text-align: start!important }
    .pi4981, .r89a95, .b98w7p, .a419o0 .i1888e, .i31t7q::before, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y0422f .container .row .offset-md-4, .h405q4 .container .row .col-md-3:first-child .d540uf, .y7367g, .u4025u li, .h4s606, .y15u5Q li span i svg{ margin: 0!important }
    .w728x2 .container .row .col-md-3:first-child .b384t5, .qcc560, .q20g3c{ margin-top: 0!important }
    .y15u5Q li span, .t4q297, .w432xg, .q20g3c{ margin-bottom: 0!important }
    .p2769m .b98w7p:last-child{ margin-right: 0!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .u4025u li span{ margin: 0 0 0 8px!important }
    .m176ye svg{ margin: -1px 8px 0 0!important }
    .n959k8 svg{ margin: -2px 8px 0 0!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320, .d624lk, .u04m7q{ margin: 6px 0 0 0!important }
    .o9l44l .w84z88, .m176ye, .n959k8, .v976lh, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(2) .s63448, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(3) .s63448, .w0r597, .r74wm6, .q20g3c, .w000w8, .d7fv32{ margin: 8px 0 0 0!important }
    .w8M19n, .q317to, .pea034, .c37dc8, .e745l2{ margin: 10px 0 0 0!important }
    .n90bv9 .r3Bx93, .k51l81, .o4e46u, .n98kk4, .d7fv32, .q76j70, .j0r112{ margin: 12px 0 0 0!important }
    .t079x8{ margin-bottom: 12px!important }
    .k51l81{ margin: 14px 0 0 0!important }
    .b584xj{ margin: 14px 0 0 12px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ margin: 16px 0 0 0!important }
    .p2769m .b98w7p{ margin-right: 16px!important }
    #React_A01NB8 .row .col-md-6:nth-child(2) .b384t5, #React_B694LU .row .col-md-6:nth-child(2) .b384t5{ margin-top: 16px!important }
    .c250ba .container .row .col-md-2:nth-child(4) .o720ha, .c250ba .container .row .col-md-2:nth-child(5) .o720ha, .h68k88, .c250ba .container .row .col-md-2:nth-child(6) .o720ha, .y15u5Q li:first-child span, .h33p0j, .z48376, .s63448, .g4zf86 .e99v68{ margin: 16px 0 0 0!important }
    .h68k88{ margin-bottom: 18px!important }
    .b0e620 .container .row .col-md-2:nth-child(4) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(5) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(6) .z9w60a{ margin: 18px 0 0 0!important }
    .m6083e{ margin-top: 24px!important }
    #main_Middle, .sI631f, .ac1099, #main_Internet, .j0r112 .container .row .col-md-3 .row .col-md-6:last-child .p2769m, .e10di5, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .m505cz .hl280a, .d3726r, .i700xb, .t883f0{ margin-top: 24px!important }
    .b0e620 .container .row .col-md-2 .z9w60a{ margin: 24px 0 0 0!important }
    .c250ba .container .row .col-md-2 .o720ha, .c741h7, .po7670{ margin: 28px 0 0 0!important }
    .k791vc, .Up955K{ margin: 24px 0!important }
    .n90bv9 .a407p5, .y76v0s, .d540uf, .v5t92l{ margin: 30px 0 0 0!important }
    .a419o0, .j71bv3, .a9680l{ margin-top: 32px!important }
    .r87933{ margin-top: 42px!important }
    .g65z5u .Select{ margin: 80px 0 0 0!important }
    .z48376, .t77am5, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y76v0s, .q76j70{ padding: 0!important }
    .ja605u{ padding: 0 24px!important }
    .r87933{ padding-left: 4px!important }
    .o9l44l:last-child{ padding: 14px 0 0 0!important }
    .u4025u{ padding-top: 16px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ padding-bottom: 16px!important }
    .y98o1m{ padding-bottom: 18px!important }
    .y62e43{ padding: 18px 24px 18px 24px!important }
    .z48376{ padding-left: 24px!important }
    .c741h7{ padding-top: 24px!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ padding: 0 0 18px 0!important }
    #React_A01NB8, #React_B694LU{ padding: 32px 0 0 0!important }
    .v7q534{ padding-bottom: 32px!important }
    .w728x2 .container .row .col-md-3:first-child .ck3902{ padding-top: 48px!important }
    .sI631f{ padding: 50px 0!important }
    .k791vc{ padding-bottom: 320px!important }
    .k791vc{ position: relative!important }
    .n97qf5{ position: absolute!important }
    #main_Middle, #main_Internet, #main_Success{ background: #F4F4F4!important }
    .k791vc{ float: none!important }
    .z493ea li a::after{ content: url( "./Media/Icon/016.svg" )!important }
    .v7q534, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ border-bottom: 1px solid var(--css-m02l09) }
    .c741h7{ border-top: 1px solid var(--css-m02l09) }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-top: 0!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-bottom: 1px solid var(--css-m02l09)!important }
    .a419o0 .i1888e{ box-shadow: var(--css-j71y4n) }
    .r89a95{ box-shadow: none!important }
}
@media screen and (min-width:413px) and (max-width:415px){
    .w000w8 br, .u04m7q br, .i63421 thead tr th:nth-child(3), .i63421 thead tr th:nth-child(4), .i63421 tbody tr td:nth-child(3), .i63421 tbody tr td:nth-child(4), .q35322::before, .z7h704{ display: none!important }
    .q892wd, .t77am5{ display: -webkit-box }
    .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ display: flex }
    #Career_Table thead tr th:nth-child(3), #Career_Table tbody tr td:nth-child(3){ display: block!important }
    .j0r112 .b98w7p{ width: auto!important }
    .y15u5Q li span i svg{ width: 8px!important }
    .m176ye svg, .j0z12w svg, .m24ho4 img{ width: 15px!important }
    .y98o1m li a svg, .u4025u li label svg, .y347yn svg, .v7c42n svg, .y15u5Q li span i{ width: 16px!important }
    .n959k8 svg, .e88a72 svg, .r89a95 img, .v5t92l img{ width: 17px!important }
    .q928g8 svg, .v10846 svg{ width: 20px!important }
    .o840cp img{ width: 21px!important }
    .f062xc img{ width: 23px!important }
    .s5c45j svg{ width: 38px!important }
    .a419o0 .i1888e{ width: 48px!important }
    .h33p0j img{ width: 80px!important }
    .Up955K img{ width: 100px!important }
    .m505cz .row .col-md-1{ width: 25%!important }
    .b0e620 .container .row .col-md-2, .c250ba .container .row .col-md-2{ width: 33.3%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3{ width: 50%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-3:last-child, .e745l2, .e745l2::before, .c250ba .container .row .col-md-2 .m8m73h, .z48376, .t53z62, .o1m43l, .b39yo6 img, .i31t7q::before, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .g30798, .o01a97 img, .i63421 thead tr th span, .i63421 tbody tr td label, .q35322::before, .u61409, .q76j70, .z2499o, .q76j70, .q20g3c, .d624lk, .w057op .row .col-md-6, .m6747x .container .row .col-md-4, .z2596n, .u04m7q{ width: 100%!important }
    .h33p0j, .u4025u li label{ min-width: auto!important }
    .p72qx6, .o840cp{ min-width: 100%!important }
    .d7fv32{ min-width: 175px!important }
    .e88a72, .y347yn{ max-width: 38px!important }
    .p72qx6 img{ max-width: 80px }
    .k51l81{ max-width: 165px!important }
    .d7fv32{ max-width: 175px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-width: 325px }
    .q76j70{ max-width: 463px!important }
    .n97qf5{ height: 100%!important }
    .c741h7, .po7670, .p2769m, .p72qx6, .h33p0j, .h33p0j img, .b39yo6 img, #main_Internet, .a419o0, .p72qx6 img, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .o840cp, .e74i23, .a65254, .d3726r, .o01a97 img, .n98kk4, .y76v0s, .q35322::before, .i700xb{ height: auto!important }
    .y15u5Q li span i svg{ height: 8px!important }
    .m176ye svg, .y15u5Q li span, .j0z12w svg, .m24ho4 img{ height: 15px!important }
    .y98o1m li a svg, .u4025u li label svg, .y347yn svg, .v7c42n svg, .y15u5Q li span i{ height: 16px!important }
    .n959k8 svg, .e88a72 svg, .r89a95 img, .v5t92l img{ height: 17px!important }
    .q928g8 svg, .v10846 svg{ height: 20px!important }
    .o840cp img{ height: 21px!important }
    .f062xc img{ height: 23px!important }
    .o091hh{ height: 30px!important }
    .s5c45j svg, .n772zy, .e88a72, .y347yn, .k51l81{ height: 38px!important }
    .b584xj{ height: 40px!important }
    .d7fv32{ height: 42px!important }
    .a419o0 .i1888e{ height: 48px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ height: 52px!important }
    .a917yq{ height: 75.4px!important }
    .z48376{ height: 80px!important }
    .g65z5u .m505cz .hl280a .a917yq{ height: 75.4px!important }
    .Up955K img{ height: 100px!important }
    #main_Middle .z93q7k, #main_Internet .z93q7k, #main_Success .z93q7k, .x63112, .x4i0g2{ height: 200px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-height: 247px }
    .d540uf{ min-height: auto!important }
    .m6083e, .x661n3, .g4zf86 .e99v68, .c28g97, .q35h02, .c28g97, .t4q297, .z2499o{ line-height: 1!important }
    .y15u5Q li span strong{ line-height: 17px!important }
    .w8M19n, .n959k8, .q317to, .m176ye{ line-height: 20px!important }
    .t77am5{ line-height: 21px!important }
    .i31t7q::before, .w000w8{ line-height: 22px!important }
    .qo3320, .i31t7q, .u04m7q{ line-height: 22.5px!important }
    .o4e46u, .s194hd, .m1364c, .q892wd{ line-height: 23px!important }
    .d624lk{ line-height: 23.5px!important }
    .e745l2::before, .c250ba .container .row .col-md-2 .m8m73h, .e745l2, .g30798{ line-height: 24px!important }
    .q20g3c{ line-height: 25px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ line-height: 52px!important }
    .n772zy, .b584xj{ border-radius: 38px!important }
    .k51l81{ border-radius: 40px!important }
    .a419o0 .i1888e{ border-radius: 100%!important }
    .n959k8, .i63421 thead tr th span, .i63421 tbody tr td label{ font-size: 11.5px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .m176ye, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798{ font-size: 12px!important }
    .x661n3{ font-size: 12.2px!important }
    .o9l44l .w84z88, .f07622, .e76r8a, .n90bv9 .a407p5{ font-size: 12.5px!important }
    .o188l9, .v976lh, .t77am5, .w000w8, .y15u5Q li span strong, .o4e46u, .z9y50r, .s194hd, .pea034, .t027vd, .t456c4, .t53z62, .c68146{ font-size: 13px!important }
    .d624lk{ font-size: 13.3px!important }
    .x0j627 li a, .y98o1m li a, .e745l2, .i31t7q::before, .q746r5, .u04m7q, .z493ea li a, .m1364c, .k8716o, .rm263w, .qo3320, .q892wd, .i31t7q{ font-size: 13.5px!important }
    .g4zf86 .e99v68, .r22m60, .s63448{ font-size: 14px!important }
    .v406f3, .w432xg{ font-size: 15px!important }
    .w0r597, .c250ba .container .row .col-md-2 .o50h0d, .i745hd{ font-size: 16px!important }
    .t4q297, .o49df7, .q20g3c{ font-size: 16px!important }
    .z2499o, .m6083e, .q35h02, .c28g97, .p5o2a6{ font-size: 17px!important }
    .m97p51{ font-size: 110px!important }
    .q892wd, .t77am5{ -webkit-box-orient: vertical }
    .q892wd, .t77am5{ -webkit-line-clamp: 3 }
    .z93q7k, .x63112, .d01z5h, .i700xb{ align-items: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ align-items: center!important }
    .d01iq4{ justify-content: start!important }
    .p2769m, .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ justify-content: center!important }
    .c741h7, .a419o0, .a65254{ justify-content: space-between!important }
    .c741h7, .p2769m, .a419o0, .a65254{ flex-direction: row!important }
    .p796s4{ flex-direction: column!important }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .q892wd, .o1m43l, .t77am5, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622{ overflow: hidden }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622{ white-space: nowrap }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622{ text-overflow: ellipsis }
    .e745l2, .u04m7q{ text-align: start!important }
    .pi4981, .r89a95, .b98w7p, .a419o0 .i1888e, .i31t7q::before, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y0422f .container .row .offset-md-4, .h405q4 .container .row .col-md-3:first-child .d540uf, .y7367g, .u4025u li, .h4s606, .y15u5Q li span i svg{ margin: 0!important }
    .w728x2 .container .row .col-md-3:first-child .b384t5, .qcc560, .q20g3c{ margin-top: 0!important }
    .y15u5Q li span, .t4q297, .w432xg, .q20g3c{ margin-bottom: 0!important }
    .p2769m .b98w7p:last-child{ margin-right: 0!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .u4025u li span{ margin: 0 0 0 8px!important }
    .m176ye svg{ margin: -1px 8px 0 0!important }
    .n959k8 svg{ margin: -2px 8px 0 0!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320, .d624lk, .u04m7q{ margin: 8px 0 0 0!important }
    .o9l44l .w84z88, .m176ye, .n959k8, .v976lh, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(2) .s63448, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(3) .s63448, .w0r597, .r74wm6, .q20g3c, .w000w8, .d7fv32{ margin: 8px 0 0 0!important }
    .w8M19n, .q317to, .pea034, .c37dc8, .e745l2{ margin: 10px 0 0 0!important }
    .n90bv9 .r3Bx93, .k51l81, .o4e46u, .n98kk4, .d7fv32, .q76j70, .j0r112{ margin: 12px 0 0 0!important }
    .t079x8{ margin-bottom: 12px!important }
    .k51l81{ margin: 14px 0 0 0!important }
    .b584xj{ margin: 14px 0 0 12px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ margin: 16px 0 0 0!important }
    .p2769m .b98w7p{ margin-right: 16px!important }
    #React_A01NB8 .row .col-md-6:nth-child(2) .b384t5, #React_B694LU .row .col-md-6:nth-child(2) .b384t5{ margin-top: 16px!important }
    .c250ba .container .row .col-md-2:nth-child(4) .o720ha, .c250ba .container .row .col-md-2:nth-child(5) .o720ha, .h68k88, .c250ba .container .row .col-md-2:nth-child(6) .o720ha, .y15u5Q li:first-child span, .h33p0j, .z48376, .s63448, .g4zf86 .e99v68{ margin: 16px 0 0 0!important }
    .h68k88{ margin-bottom: 18px!important }
    .b0e620 .container .row .col-md-2:nth-child(4) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(5) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(6) .z9w60a{ margin: 18px 0 0 0!important }
    .m6083e{ margin-top: 20px!important }
    #main_Middle, .sI631f, .ac1099, #main_Internet, .j0r112 .container .row .col-md-3 .row .col-md-6:last-child .p2769m, .e10di5, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .m505cz .hl280a, .d3726r, .i700xb, .t883f0{ margin-top: 24px!important }
    .b0e620 .container .row .col-md-2 .z9w60a{ margin: 24px 0 0 0!important }
    .c250ba .container .row .col-md-2 .o720ha, .c741h7, .po7670{ margin: 28px 0 0 0!important }
    .k791vc, .Up955K{ margin: 24px 0!important }
    .n90bv9 .a407p5, .y76v0s, .d540uf, .v5t92l{ margin: 30px 0 0 0!important }
    .a419o0, .j71bv3, .a9680l{ margin-top: 32px!important }
    .r87933{ margin-top: 42px!important }
    .g65z5u .Select{ margin: 80px 0 0 0!important }
    .z48376, .t77am5, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y76v0s, .q76j70{ padding: 0!important }
    .r87933{ padding-left: 0!important }
    .r87933{ padding-right: 0!important }
    .ja605u{ padding: 0 24px!important }
    .o9l44l:last-child{ padding: 14px 0 0 0!important }
    .u4025u{ padding-top: 16px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ padding-bottom: 16px!important }
    .y98o1m{ padding-bottom: 18px!important }
    .y62e43{ padding: 18px 24px 18px 24px!important }
    .z48376{ padding-left: 24px!important }
    .c741h7{ padding-top: 24px!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ padding: 0 0 18px 0!important }
    #React_A01NB8, #React_B694LU{ padding: 32px 0 0 0!important }
    .v7q534{ padding-bottom: 32px!important }
    .w728x2 .container .row .col-md-3:first-child .ck3902{ padding-top: 48px!important }
    .sI631f{ padding: 50px 0!important }
    .k791vc{ padding-bottom: 320px!important }
    .k791vc{ position: relative!important }
    .n97qf5{ position: absolute!important }
    #main_Middle, #main_Internet, #main_Success{ background: #F4F4F4!important }
    .k791vc{ float: none!important }
    .z493ea li a::after{ content: url( "./Media/Icon/016.svg" )!important }
    .v7q534, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ border-bottom: 1px solid var(--css-m02l09) }
    .c741h7{ border-top: 1px solid var(--css-m02l09) }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-top: 0!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-bottom: 1px solid var(--css-m02l09)!important }
    .a419o0 .i1888e{ box-shadow: var(--css-j71y4n) }
    .r89a95{ box-shadow: none!important }
}
@media screen and (min-width:405px) and (max-width:412px){
    .w000w8 br, .u04m7q br, .i63421 thead tr th:nth-child(3), .i63421 thead tr th:nth-child(4), .i63421 tbody tr td:nth-child(3), .i63421 tbody tr td:nth-child(4), .q35322::before, .z7h704{ display: none!important }
    .q892wd, .t77am5{ display: -webkit-box }
    .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ display: flex }
    #Career_Table thead tr th:nth-child(3), #Career_Table tbody tr td:nth-child(3){ display: block!important }
    .j0r112 .b98w7p{ width: auto!important }
    .y15u5Q li span i svg{ width: 8px!important }
    .m176ye svg, .j0z12w svg, .m24ho4 img{ width: 15px!important }
    .y98o1m li a svg, .u4025u li label svg, .y347yn svg, .v7c42n svg, .y15u5Q li span i{ width: 16px!important }
    .n959k8 svg, .e88a72 svg, .r89a95 img, .v5t92l img{ width: 17px!important }
    .q928g8 svg, .v10846 svg{ width: 20px!important }
    .o840cp img{ width: 21px!important }
    .f062xc img{ width: 23px!important }
    .s5c45j svg{ width: 38px!important }
    .a419o0 .i1888e{ width: 48px!important }
    .h33p0j img{ width: 80px!important }
    .Up955K img{ width: 100px!important }
    .m505cz .row .col-md-1{ width: 25%!important }
    .b0e620 .container .row .col-md-2, .c250ba .container .row .col-md-2{ width: 33.3%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3{ width: 50%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-3:last-child, .e745l2, .e745l2::before, .c250ba .container .row .col-md-2 .m8m73h, .z48376, .t53z62, .o1m43l, .b39yo6 img, .i31t7q::before, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .g30798, .o01a97 img, .i63421 thead tr th span, .i63421 tbody tr td label, .q35322::before, .u61409, .q76j70, .z2499o, .q76j70, .q20g3c, .d624lk, .w057op .row .col-md-6, .m6747x .container .row .col-md-4, .z2596n, .u04m7q{ width: 100%!important }
    .h33p0j, .u4025u li label{ min-width: auto!important }
    .p72qx6, .o840cp{ min-width: 100%!important }
    .d7fv32{ min-width: 175px!important }
    .e88a72, .y347yn{ max-width: 38px!important }
    .p72qx6 img{ max-width: 80px }
    .k51l81{ max-width: 165px!important }
    .d7fv32{ max-width: 175px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-width: 325px }
    .q76j70{ max-width: 463px!important }
    .n97qf5{ height: 100%!important }
    .c741h7, .po7670, .p2769m, .p72qx6, .h33p0j, .h33p0j img, .b39yo6 img, #main_Internet, .a419o0, .p72qx6 img, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .o840cp, .e74i23, .a65254, .d3726r, .o01a97 img, .n98kk4, .y76v0s, .q35322::before, .i700xb{ height: auto!important }
    .y15u5Q li span i svg{ height: 8px!important }
    .m176ye svg, .y15u5Q li span, .j0z12w svg, .m24ho4 img{ height: 15px!important }
    .y98o1m li a svg, .u4025u li label svg, .y347yn svg, .v7c42n svg, .y15u5Q li span i{ height: 16px!important }
    .n959k8 svg, .e88a72 svg, .r89a95 img, .v5t92l img{ height: 17px!important }
    .q928g8 svg, .v10846 svg{ height: 20px!important }
    .o840cp img{ height: 21px!important }
    .f062xc img{ height: 23px!important }
    .o091hh{ height: 30px!important }
    .s5c45j svg, .n772zy, .e88a72, .y347yn, .k51l81{ height: 38px!important }
    .b584xj{ height: 40px!important }
    .d7fv32{ height: 42px!important }
    .a419o0 .i1888e{ height: 48px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ height: 52px!important }
    .a917yq{ height: 75.4px!important }
    .z48376{ height: 80px!important }
    .g65z5u .m505cz .hl280a .a917yq{ height: 75.4px!important }
    .Up955K img{ height: 100px!important }
    #main_Middle .z93q7k, #main_Internet .z93q7k, #main_Success .z93q7k, .x63112, .x4i0g2{ height: 200px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-height: 247px }
    .d540uf{ min-height: auto!important }
    .m6083e, .x661n3, .g4zf86 .e99v68, .c28g97, .q35h02, .c28g97, .t4q297, .z2499o{ line-height: 1!important }
    .y15u5Q li span strong{ line-height: 17px!important }
    .w8M19n, .n959k8, .q317to, .m176ye{ line-height: 20px!important }
    .t77am5{ line-height: 21px!important }
    .i31t7q::before, .w000w8{ line-height: 22px!important }
    .qo3320, .i31t7q, .u04m7q{ line-height: 22.5px!important }
    .o4e46u, .s194hd, .m1364c, .q892wd{ line-height: 23px!important }
    .d624lk{ line-height: 23.5px!important }
    .e745l2::before, .c250ba .container .row .col-md-2 .m8m73h, .e745l2, .g30798{ line-height: 24px!important }
    .q20g3c{ line-height: 25px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ line-height: 52px!important }
    .n772zy, .b584xj{ border-radius: 38px!important }
    .k51l81{ border-radius: 40px!important }
    .a419o0 .i1888e{ border-radius: 100%!important }
    .n959k8, .i63421 thead tr th span, .i63421 tbody tr td label{ font-size: 11.5px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .m176ye, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798{ font-size: 12px!important }
    .x661n3{ font-size: 12.2px!important }
    .o9l44l .w84z88, .f07622, .e76r8a, .n90bv9 .a407p5{ font-size: 12.5px!important }
    .o188l9, .v976lh, .t77am5, .w000w8, .y15u5Q li span strong, .o4e46u, .z9y50r, .s194hd, .pea034, .t027vd, .t456c4, .t53z62, .c68146{ font-size: 13px!important }
    .d624lk{ font-size: 13.3px!important }
    .x0j627 li a, .y98o1m li a, .e745l2, .i31t7q::before, .q746r5, .u04m7q, .z493ea li a, .m1364c, .k8716o, .rm263w, .qo3320, .q892wd, .i31t7q{ font-size: 13.5px!important }
    .g4zf86 .e99v68, .r22m60, .s63448{ font-size: 14px!important }
    .v406f3, .w432xg{ font-size: 15px!important }
    .w0r597, .c250ba .container .row .col-md-2 .o50h0d, .i745hd{ font-size: 16px!important }
    .t4q297, .o49df7, .q20g3c{ font-size: 16px!important }
    .z2499o, .m6083e, .q35h02, .c28g97, .p5o2a6{ font-size: 17px!important }
    .m97p51{ font-size: 110px!important }
    .q892wd, .t77am5{ -webkit-box-orient: vertical }
    .q892wd, .t77am5{ -webkit-line-clamp: 3 }
    .z93q7k, .x63112, .d01z5h, .i700xb{ align-items: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ align-items: center!important }
    .d01iq4{ justify-content: start!important }
    .p2769m, .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ justify-content: center!important }
    .c741h7, .a419o0, .a65254{ justify-content: space-between!important }
    .c741h7, .p2769m, .a419o0, .a65254{ flex-direction: row!important }
    .p796s4{ flex-direction: column!important }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .q892wd, .o1m43l, .t77am5, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622{ overflow: hidden }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622{ white-space: nowrap }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622{ text-overflow: ellipsis }
    .e745l2, .u04m7q{ text-align: start!important }
    .pi4981, .r89a95, .b98w7p, .a419o0 .i1888e, .i31t7q::before, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y0422f .container .row .offset-md-4, .h405q4 .container .row .col-md-3:first-child .d540uf, .y7367g, .u4025u li, .h4s606, .y15u5Q li span i svg{ margin: 0!important }
    .w728x2 .container .row .col-md-3:first-child .b384t5, .qcc560, .q20g3c{ margin-top: 0!important }
    .y15u5Q li span, .t4q297, .w432xg, .q20g3c{ margin-bottom: 0!important }
    .p2769m .b98w7p:last-child{ margin-right: 0!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .u4025u li span{ margin: 0 0 0 8px!important }
    .m176ye svg{ margin: -1px 8px 0 0!important }
    .n959k8 svg{ margin: -2px 8px 0 0!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Internet .qo3320, #main_Middle .qo3320, .d624lk, .u04m7q{ margin: 8px 0 0 0!important }
    .o9l44l .w84z88, .m176ye, .n959k8, .v976lh, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(2) .s63448, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(3) .s63448, .w0r597, .r74wm6, .q20g3c, .w000w8, .d7fv32{ margin: 8px 0 0 0!important }
    .w8M19n, .q317to, .pea034, .c37dc8, .e745l2{ margin: 10px 0 0 0!important }
    .n90bv9 .r3Bx93, .k51l81, .o4e46u, .n98kk4, .d7fv32, .q76j70, .j0r112{ margin: 12px 0 0 0!important }
    .t079x8{ margin-bottom: 12px!important }
    .k51l81{ margin: 14px 0 0 0!important }
    .b584xj{ margin: 14px 0 0 12px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ margin: 16px 0 0 0!important }
    .p2769m .b98w7p{ margin-right: 16px!important }
    #React_A01NB8 .row .col-md-6:nth-child(2) .b384t5, #React_B694LU .row .col-md-6:nth-child(2) .b384t5{ margin-top: 16px!important }
    .c250ba .container .row .col-md-2:nth-child(4) .o720ha, .c250ba .container .row .col-md-2:nth-child(5) .o720ha, .h68k88, .c250ba .container .row .col-md-2:nth-child(6) .o720ha, .y15u5Q li:first-child span, .h33p0j, .z48376, .s63448, .g4zf86 .e99v68{ margin: 16px 0 0 0!important }
    .h68k88{ margin-bottom: 18px!important }
    .b0e620 .container .row .col-md-2:nth-child(4) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(5) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(6) .z9w60a{ margin: 18px 0 0 0!important }
    .m6083e{ margin-top: 20px!important }
    #main_Middle, .sI631f, .ac1099, #main_Internet, .j0r112 .container .row .col-md-3 .row .col-md-6:last-child .p2769m, .e10di5, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .m505cz .hl280a, .d3726r, .i700xb, .t883f0{ margin-top: 24px!important }
    .b0e620 .container .row .col-md-2 .z9w60a{ margin: 24px 0 0 0!important }
    .c250ba .container .row .col-md-2 .o720ha, .c741h7, .po7670{ margin: 28px 0 0 0!important }
    .k791vc, .Up955K{ margin: 24px 0!important }
    .n90bv9 .a407p5, .y76v0s, .d540uf, .v5t92l{ margin: 30px 0 0 0!important }
    .a419o0, .j71bv3, .a9680l{ margin-top: 32px!important }
    .r87933{ margin-top: 42px!important }
    .g65z5u .Select{ margin: 80px 0 0 0!important }
    .z48376, .t77am5, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y76v0s, .q76j70{ padding: 0!important }
    .r87933{ padding-left: 0!important }
    .r87933{ padding-right: 0!important }
    .ja605u{ padding: 0 24px!important }
    .o9l44l:last-child{ padding: 14px 0 0 0!important }
    .u4025u{ padding-top: 16px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ padding-bottom: 16px!important }
    .y98o1m{ padding-bottom: 18px!important }
    .y62e43{ padding: 18px 24px 18px 24px!important }
    .z48376{ padding-left: 24px!important }
    .c741h7{ padding-top: 24px!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ padding: 0 0 18px 0!important }
    #React_A01NB8, #React_B694LU{ padding: 32px 0 0 0!important }
    .v7q534{ padding-bottom: 32px!important }
    .w728x2 .container .row .col-md-3:first-child .ck3902{ padding-top: 48px!important }
    .sI631f{ padding: 50px 0!important }
    .k791vc{ padding-bottom: 320px!important }
    .k791vc{ position: relative!important }
    .n97qf5{ position: absolute!important }
    #main_Middle, #main_Internet, #main_Success{ background: #F4F4F4!important }
    .k791vc{ float: none!important }
    .z493ea li a::after{ content: url( "./Media/Icon/016.svg" )!important }
    .v7q534, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ border-bottom: 1px solid var(--css-m02l09) }
    .c741h7{ border-top: 1px solid var(--css-m02l09) }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-top: 0!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-bottom: 1px solid var(--css-m02l09)!important }
    .a419o0 .i1888e{ box-shadow: var(--css-j71y4n) }
    .r89a95{ box-shadow: none!important }
}
@media screen and (min-width:395px) and (max-width:404px){
    .w000w8 br, .u04m7q br, .i63421 thead tr th:nth-child(3), .i63421 thead tr th:nth-child(4), .i63421 tbody tr td:nth-child(3), .i63421 tbody tr td:nth-child(4), .q35322::before, .z7h704{ display: none!important }
    .q892wd, .t77am5{ display: -webkit-box }
    .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ display: flex }
    #Career_Table thead tr th:nth-child(3), #Career_Table tbody tr td:nth-child(3){ display: block!important }
    .j0r112 .b98w7p{ width: auto!important }
    .y15u5Q li span i svg{ width: 7px!important }
    .j0z12w svg{ width: 14px!important }
    .m176ye svg, .y15u5Q li span i, .m24ho4 img{ width: 15px!important }
    .y98o1m li a svg, .u4025u li label svg, .y347yn svg, .v7c42n svg, .x0j627 li a svg{ width: 16px!important }
    .n959k8 svg, .e88a72 svg, .v5t92l img{ width: 16px!important }
    .r89a95 img{ width: 17px!important }
    .q928g8 svg, .v10846 svg, .o840cp img{ width: 20px!important }
    .f062xc img{ width: 23px!important }
    .s5c45j svg{ width: 36px!important }
    .a419o0 .i1888e{ width: 48px!important }
    .h33p0j img{ width: 80px!important }
    .Up955K img{ width: 92px!important }
    .m505cz .row .col-md-1{ width: 25%!important }
    .b0e620 .container .row .col-md-2, .c250ba .container .row .col-md-2{ width: 33.3%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3{ width: 50%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-3:last-child, .e745l2, .e745l2::before, .c250ba .container .row .col-md-2 .m8m73h, .z48376, .t53z62, .o1m43l, .b39yo6 img, .i31t7q::before, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .g30798, .o01a97 img, .i63421 thead tr th span, .i63421 tbody tr td label, .q35322::before, .u61409, .q76j70, .z2499o, .q76j70, .q20g3c, .d624lk, .w057op .row .col-md-6, .m6747x .container .row .col-md-4, .z2596n, .u04m7q, .c28g97, .o49df7{ width: 100%!important }
    .h33p0j, .u4025u li label{ min-width: auto!important }
    .p72qx6, .o840cp{ min-width: 100%!important }
    .d7fv32{ min-width: 175px!important }
    .e88a72, .y347yn{ max-width: 38px!important }
    .p72qx6 img{ max-width: 80px }
    .k51l81{ max-width: 165px!important }
    .d7fv32{ max-width: 175px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-width: 275px }
    .q76j70{ max-width: 463px!important }
    .n97qf5{ height: 100%!important }
    .c741h7, .po7670, .p2769m, .p72qx6, .h33p0j, .h33p0j img, .b39yo6 img, #main_Internet, .a419o0, .p72qx6 img, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .o840cp, .e74i23, .a65254, .d3726r, .o01a97 img, .n98kk4, .y76v0s, .q35322::before, .i700xb{ height: auto!important }
    .y15u5Q li span i svg{ height: 7px!important }
    .y15u5Q li span, .j0z12w svg{ height: 14px!important }
    .m176ye svg, .m24ho4 img{ height: 15px!important }
    .u4025u li label svg, .y347yn svg, .v7c42n svg, .y15u5Q li span i{ height: 15px!important }
    .n959k8 svg, .e88a72 svg, .v5t92l img, .y98o1m li a svg, .x0j627 li a svg{ height: 16px!important }
    .r89a95 img{ height: 17px!important }
    .q928g8 svg, .v10846 svg, .o840cp img{ height: 20px!important }
    .f062xc img{ height: 23px!important }
    .o091hh{ height: 29px!important }
    .s5c45j svg, .n772zy, .e88a72, .y347yn{ height: 36px!important }
    .k51l81, .b584xj{ height: 38px!important }
    .d7fv32{ height: 42px!important }
    .a419o0 .i1888e{ height: 48px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ height: 52px!important }
    .a917yq{ height: 71.8px!important }
    .z48376{ height: 80px!important }
    .g65z5u .m505cz .hl280a .a917yq{ height: 71.8px!important }
    .Up955K img{ height: 92px!important }
    #main_Middle .z93q7k, #main_Internet .z93q7k, #main_Success .z93q7k, .x63112, .x4i0g2{ height: 200px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-height: 200px }
    .d540uf{ min-height: auto!important }
    .m6083e, .x661n3, .g4zf86 .e99v68, .c28g97, .q35h02, .c28g97, .t4q297, .z2499o{ line-height: 1!important }
    .y15u5Q li span strong{ line-height: 15px!important }
    .w8M19n, .n959k8, .q317to, .m176ye{ line-height: 20px!important }
    .t77am5{ line-height: 22px!important }
    .qo3320, .i31t7q{ line-height: 22px!important }
    .v976lh{ line-height: 22px!important }
    .m1364c, .q892wd, .i31t7q::before, .w000w8, .s194hd{ line-height: 23px!important }
    .e745l2, .u04m7q, .o4e46u{ line-height: 23.5px!important }
    .d624lk{ line-height: 24px!important }
    .q20g3c, .e745l2::before{ line-height: 25px!important }
    .c250ba .container .row .col-md-2 .m8m73h, .g30798{ line-height: 26px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ line-height: 52px!important }
    .n772zy{ border-radius: 36px!important }
    .k51l81, .b584xj{ border-radius: 38px!important }
    .a419o0 .i1888e{ border-radius: 100%!important }
    .n959k8, .i63421 thead tr th span, .i63421 tbody tr td label{ font-size: 11.5px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .m176ye, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798{ font-size: 12px!important }
    .x661n3{ font-size: 12.2px!important }
    .o9l44l .w84z88, .f07622, .e76r8a, .n90bv9 .a407p5{ font-size: 12.5px!important }
    .o188l9, .t77am5, .y15u5Q li span strong, .s194hd, .pea034, .t027vd, .t456c4, .t53z62, .c68146{ font-size: 13px!important }
    .d624lk, .y98o1m li a, .x0j627 li a{ font-size: 13px!important }
    .v976lh{ font-size: 13px!important }
    .w000w8, .o4e46u, .z9y50r{ font-size: 13.3px!important }
    .i31t7q::before, .q746r5, .z493ea li a, .m1364c, .k8716o, .rm263w, .qo3320, .q892wd, .i31t7q, .e745l2, .u04m7q{ font-size: 13.5px!important }
    .g4zf86 .e99v68, .r22m60, .s63448{ font-size: 14px!important }
    .c250ba .container .row .col-md-2 .o50h0d, .i745hd{ font-size: 14.5px!important }
    .v406f3, .w432xg, .w0r597, .p5o2a6{ font-size: 15px!important }
    .z2499o, .t4q297{ font-size: 15.5px!important }
    .o49df7{ font-size: 16px!important }
    .q20g3c, .c28g97{ font-size: 17.1px!important }
    .q35h02{ font-size: 17.2px!important }
    .m6083e{ font-size: 17.4px!important }
    .m97p51{ font-size: 80px!important }
    .q892wd, .t77am5{ -webkit-box-orient: vertical }
    .q892wd, .t77am5{ -webkit-line-clamp: 3 }
    .z93q7k, .x63112, .d01z5h, .i700xb{ align-items: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .br6312{ align-items: center!important }
    .d01iq4, .p2769m{ justify-content: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ justify-content: center!important }
    .c741h7, .a419o0, .a65254{ justify-content: space-between!important }
    .c741h7, .p2769m, .a419o0, .a65254{ flex-direction: row!important }
    .p796s4{ flex-direction: column!important }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .q892wd, .o1m43l, .t77am5, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .c28g97, .o49df7{ overflow: hidden }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .c28g97, .o49df7{ white-space: nowrap }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .c28g97, .o49df7{ text-overflow: ellipsis }
    .e745l2, .u04m7q{ text-align: start!important }
    .v976lh{ text-align: center!important }
    .pi4981, .r89a95, .b98w7p, .a419o0 .i1888e, .i31t7q::before, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y0422f .container .row .offset-md-4, .h405q4 .container .row .col-md-3:first-child .d540uf, .y7367g, .u4025u li, .h4s606, .y15u5Q li span i svg{ margin: 0!important }
    .w728x2 .container .row .col-md-3:first-child .b384t5, .qcc560{ margin-top: 0!important }
    .y15u5Q li span, .t4q297, .w432xg, .q20g3c{ margin-bottom: 0!important }
    .p2769m .b98w7p:last-child{ margin-right: 0!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .u4025u li span{ margin: 0 0 0 8px!important }
    .m176ye svg{ margin: -1px 8px 0 0!important }
    .n959k8 svg{ margin: -2px 8px 0 0!important }
    .y98o1m li a svg, .x0j627 li a svg{ margin: 2.5px 0 0 0!important }
    .q20g3c{ margin: 4px 0 0 0!important }
    .d624lk{ margin: 4px 0 0 0!important }
    .u04m7q, #main_Internet .qo3320, #main_Internet .i31t7q, #main_Success .qo3320{ margin: 8px 0 0 0!important }
    .o9l44l .w84z88, .m176ye, .n959k8, .v976lh, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(2) .s63448, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(3) .s63448, .w0r597, .r74wm6, .d7fv32, .g4zf86 .e99v68{ margin: 8px 0 0 0!important }
    .w8M19n, .q317to, .pea034, .c37dc8, .e745l2, #main_Middle .qo3320, .o4e46u{ margin: 10px 0 0 0!important }
    .n90bv9 .r3Bx93, .n98kk4, .d7fv32, .q76j70, .j0r112, .k51l81, .w000w8{ margin: 12px 0 0 0!important }
    .t079x8{ margin-bottom: 12px!important }
    .b584xj{ margin: 14px 0 0 12px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ margin: 16px 0 0 0!important }
    .p2769m .b98w7p{ margin-right: 16px!important }
    #React_A01NB8 .row .col-md-6:nth-child(2) .b384t5, #React_B694LU .row .col-md-6:nth-child(2) .b384t5{ margin-top: 16px!important }
    .j0r112 .container .row .col-md-3:last-child .row .col-md-6 .p2769m{ margin-top: 21px!important }
    .h68k88{ margin-bottom: 18px!important }
    .b0e620 .container .row .col-md-2:nth-child(4) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(5) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(6) .z9w60a, .y15u5Q li:first-child span{ margin: 18px 0 0 0!important }
    .m6083e{ margin-top: 20px!important }
    .c250ba .container .row .col-md-2:nth-child(4) .o720ha, .c250ba .container .row .col-md-2:nth-child(5) .o720ha, .h68k88, .c250ba .container .row .col-md-2:nth-child(6) .o720ha, .h33p0j, .z48376, .s63448{ margin: 21px 0 0 0!important }
    #main_Middle, .sI631f, .ac1099, #main_Internet, .j0r112 .container .row .col-md-3 .row .col-md-6:last-child .p2769m, .e10di5, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .m505cz .hl280a, .d3726r, .i700xb, .t883f0{ margin-top: 24px!important }
    .b0e620 .container .row .col-md-2 .z9w60a{ margin: 24px 0 0 0!important }
    .sI631f{ margin-bottom: 12px!important }
    .c250ba .container .row .col-md-2 .o720ha, .c741h7{ margin: 28px 0 0 0!important }
    .k791vc, .Up955K{ margin: 24px 0!important }
    .n90bv9 .a407p5, .y76v0s, .d540uf, .v5t92l{ margin: 30px 0 0 0!important }
    .a419o0, .j71bv3, .a9680l{ margin-top: 32px!important }
    .po7670{ margin: 28px 0 0 0!important }
    .r87933{ margin-top: 42px!important }
    .g65z5u .Select{ margin: 80px 0 0 0!important }
    .z48376, .t77am5, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y76v0s, .q76j70, .ja605u{ padding: 0!important }
    .r87933{ padding-left: 0!important }
    .r87933{ padding-right: 0!important }
    .o9l44l:last-child{ padding: 14px 0 0 0!important }
    .u4025u{ padding-top: 16px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ padding-bottom: 16px!important }
    .y98o1m{ padding-bottom: 18px!important }
    .y62e43{ padding: 18px 24px 18px 24px!important }
    .z48376{ padding-left: 98px!important }
    .c741h7{ padding-top: 24px!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ padding: 0 0 18px 0!important }
    #React_A01NB8, #React_B694LU{ padding: 32px 0 0 0!important }
    .v7q534{ padding-bottom: 32px!important }
    .w728x2 .container .row .col-md-3:first-child .ck3902{ padding-top: 48px!important }
    .sI631f{ padding: 50px 0!important }
    .k791vc{ padding-bottom: 320px!important }
    .k791vc, .ja605u{ position: relative!important }
    .n97qf5, .z48376{ position: absolute!important }
    #main_Middle, #main_Internet, #main_Success{ background: #F4F4F4!important }
    .k791vc{ float: none!important }
    .z493ea li a::after{ content: url( "./Media/Icon/016.svg" )!important }
    .v7q534, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ border-bottom: 1px solid var(--css-m02l09) }
    .c741h7{ border-top: 1px solid var(--css-m02l09) }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-top: 0!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-bottom: 1px solid var(--css-m02l09)!important }
    .a419o0 .i1888e{ box-shadow: var(--css-j71y4n) }
    .r89a95, .v5t92l{ box-shadow: none!important }
}
@media screen and (min-width:392px) and (max-width:394px){
    .w000w8 br, .u04m7q br, .i63421 thead tr th:nth-child(2), .i63421 thead tr th:nth-child(3), .i63421 thead tr th:nth-child(4), .i63421 tbody tr td:nth-child(2), .i63421 tbody tr td:nth-child(3), .i63421 tbody tr td:nth-child(4), .q35322::before, .z7h704{ display: none!important }
    .q892wd, .t77am5{ display: -webkit-box }
    .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ display: flex }
    #Career_Table thead tr th:nth-child(3), #Career_Table tbody tr td:nth-child(3){ display: block!important }
    .j0r112 .b98w7p{ width: auto!important }
    .y15u5Q li span i svg{ width: 7px!important }
    .m176ye svg, .y98o1m li a svg, .m24ho4 img, .v7c42n svg, .u4025u li label svg{ width: 15px!important }
    .y347yn svg, .n959k8 svg, .e88a72 svg, .d8005g svg, .y15u5Q li span i, .j0z12w svg{ width: 16px!important }
    .r89a95 img, .v5t92l img{ width: 17px!important }
    .o840cp img, .q928g8 svg, .v10846 svg{ width: 19px!important }
    .f062xc img{ width: 22px!important }
    .s5c45j svg{ width: 36px!important }
    .r307z9::before, .bs0p47::before, .r307z9::after, .bs0p47::after, .v5t92l, .a419o0 .i1888e{ width: 42px!important }
    .h33p0j img{ width: 72px!important }
    .Up955K img{ width: 92px!important }
    .m505cz .row .col-md-1{ width: 25%!important }
    .c250ba .container .row .col-md-2, .b0e620 .container .row .col-md-2{ width: 33.3%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3{ width: 50%!important }
    .z9y50r{ width: 93%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-3:last-child, .e745l2, .e745l2::before, .m8m73h, .z48376, .t53z62, .o1m43l, .b39yo6 img, .i31t7q::before, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .g30798, .o01a97 img, .i63421 thead tr th span, .i63421 tbody tr td label, .q35322::before, .u61409, .q76j70, .q76j70, .q20g3c, .d624lk, .w057op .row .col-md-6, .m6747x .container .row .col-md-4, .z2596n, .u04m7q, .f07622, .n98kk4 iframe, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02, .q892wd, .m1364c{ width: 100%!important }
    .h33p0j, .u4025u li label{ min-width: auto!important }
    .p72qx6, .o840cp{ min-width: 100%!important }
    .j0z12w{ min-width: 7%!important }
    .z9y50r{ min-width: 93%!important }
    .d7fv32{ min-width: 175px!important }
    .e88a72, .y347yn{ max-width: 34px!important }
    .d8005g, .r89a95, .v5t92l{ max-width: 42px!important }
    .p72qx6 img{ max-width: 72px }
    .k51l81{ max-width: 160px!important }
    .d7fv32, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-width: 325px!important }
    .q76j70{ max-width: 463px!important }
    .p72qx6{ max-width: none!important }
    .n98kk4{ height: 0!important }
    .n97qf5, .n98kk4 iframe{ height: 100%!important }
    .c741h7, .po7670, .p2769m, .p72qx6, .h33p0j, .h33p0j img, .b39yo6 img, #main_Internet, .a419o0, .p72qx6 img, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .o840cp, .e74i23, .a65254, .d3726r, .o01a97 img, .y76v0s, .q35322::before, .i700xb{ height: auto!important }
    .y15u5Q li span i svg{ height: 7px!important }
    .y347yn svg, .m176ye svg, .y98o1m li a svg, .y15u5Q li span, .m24ho4 img, .v7c42n svg, .u4025u li label svg{ height: 15px!important }
    .n959k8 svg, .e88a72 svg, .d8005g svg, .y15u5Q li span i, .j0z12w svg{ height: 16px!important }
    .r89a95 img, .v5t92l img{ height: 17px!important }
    .o840cp img, .q928g8 svg, .v10846 svg{ height: 19px!important }
    .f062xc img{ height: 22px!important }
    .o091hh{ height: 31px!important }
    .s5c45j svg, .n772zy, .e88a72{ height: 36px!important }
    .y347yn, .k51l81{ height: 36px!important }
    .b584xj{ height: 36px!important }
    .d7fv32, .s2p966, .d8005g, .r307z9, .bs0p47, .r307z9::before, .bs0p47::before, .r89a95, .v5t92l, .a21z73, .z160i4, .a419o0 .i1888e{ height: 42px!important }
    .s8956w, .g2d163, .g2d163 .swiper{ height: 48px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ height: 50px!important }
    .z48376{ height: 72px!important }
    .m78d3j, .s5c45j, .ox8525, .v7c42n, .q928g8, .v10846{ height: 70px!important }
    .a917yq, .g65z5u .m505cz .hl280a .a917yq{ height: 70.2px!important }
    .Up955K img{ height: 92px!important }
    #main_Middle .z93q7k, #main_Internet .z93q7k, #main_Success .z93q7k, .x4i0g2, .x63112{ height: 225px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-height: 225px }
    .d540uf{ min-height: auto!important }
    .m6083e, .x661n3, .g4zf86 .e99v68, .q35h02, .c28g97, .t4q297, .z2499o{ line-height: 1!important }
    .y15u5Q li span strong{ line-height: 16px!important }
    .w8M19n, .n959k8, .q317to, .m176ye, .o1m43l{ line-height: 20px!important }
    .t77am5, .v976lh{ line-height: 21.5px!important }
    .w000w8, .o4e46u, .qo3320, .i31t7q{ line-height: 22px!important }
    .o49df7, .s194hd{ line-height: 22px!important }
    .u04m7q, .m1364c, .e745l2{ line-height: 23px!important }
    .d624lk, .q892wd{ line-height: 24px!important }
    .q20g3c{ line-height: 24.5px!important }
    .c250ba .container .row .col-md-2 .m8m73h, .g30798{ line-height: 26px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ line-height: 50px!important }
    .k51l81, .b584xj, .n772zy{ border-radius: 36px!important }
    .r307z9, .bs0p47{ border-radius: 42px!important }
    .a419o0 .i1888e{ border-radius: 100%!important }
    .i63421 thead tr th span, .i63421 tbody tr td label{ font-size: 11px!important }
    .c997p0::after{ font-size: 11.4px!important }
    .n959k8, .x661n3{ font-size: 11.5px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .m176ye, .o1m43l, .s2p966, .o9l44l .w84z88, .f07622, .e76r8a, .t53z62, .g65z5u .h405q4 .d540uf .d01z5h .r279j3, .g65z5u .Select::before, .g65z5u .w728x2 .ck3902::after, .n5x508::after, .c997p0, .z160i4, .c250ba .container .row .col-md-2 .m8m73h, .g30798{ font-size: 12px!important }
    .z493ea li a, .q746r5, .n90bv9 .a407p5, .g65z5u .h405q4 .d540uf .d01z5h .w67h3x, .g65z5u .h405q4 .d540uf .u4025u li span, .t456c4{ font-size: 12.5px!important }
    .t77am5, .v976lh, .t027vd{ font-size: 12.9px!important }
    .o188l9, .c68146, .y98o1m li a, .d624lk, .q20g3c, .r22m60, .pea034, .x0j627 li a, .k8716o, .rm263w, .w000w8, .y15u5Q li span strong, .o4e46u, .s194hd, .qo3320, .z9y50r, .e745l2, .e745l2, .g65z5u .h405q4 .d540uf .d01z5h .r74wm6, .i31t7q{ font-size: 13px!important }
    .u04m7q, .m1364c, .q892wd{ font-size: 13.4px!important }
    .g4zf86 .e99v68, .s63448{ font-size: 13.5px!important }
    .c250ba .container .row .col-md-2 .o50h0d, .w432xg{ font-size: 14px!important }
    .i745hd, .v406f3{ font-size: 14.5px!important }
    .z2499o, .t4q297, .w0r597{ font-size: 15px!important }
    .o49df7, .p5o2a6{ font-size: 16px!important }
    .c28g97, .q35h02{ font-size: 16.5px!important }
    .m6083e{ font-size: 17px!important }
    .m97p51{ font-size: 96px!important }
    .q892wd, .t77am5{ -webkit-box-orient: vertical }
    .q892wd, .t77am5{ -webkit-line-clamp: 3 }
    .z93q7k, .d01z5h, .x63112{ align-items: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .br6312{ align-items: center!important }
    .d01iq4, .i700xb, .p2769m{ justify-content: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ justify-content: center!important }
    .c741h7, .a419o0, .a65254{ justify-content: space-between!important }
    .c741h7, .p2769m, .a419o0, .a65254{ flex-direction: row!important }
    .p796s4{ flex-direction: column!important }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .q892wd, .o1m43l, .t77am5, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02, .t4q297{ overflow: hidden }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02, .t4q297{ white-space: nowrap }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02, .t4q297{ text-overflow: ellipsis }
    .e745l2, .u04m7q{ text-align: start!important }
    .v976lh{ text-align: center!important }
    .pi4981, .r89a95, .b98w7p, .a419o0 .i1888e, .i31t7q::before, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y0422f .container .row .offset-md-4, .h405q4 .container .row .col-md-3:first-child .d540uf, .y7367g, .u4025u li, .h4s606, .y15u5Q li span i svg{ margin: 0!important }
    .w728x2 .container .row .col-md-3:first-child .b384t5, .qcc560{ margin-top: 0!important }
    .y15u5Q li span, .t4q297, .w432xg, .q20g3c{ margin-bottom: 0!important }
    .p2769m .b98w7p:last-child{ margin-right: 0!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .u4025u li span{ margin: 0 0 0 8px!important }
    .m176ye svg{ margin: -1px 8px 0 0!important }
    .n959k8 svg{ margin: -2px 8px 0 0!important }
    .q20g3c, .d624lk, .u04m7q{ margin: 4px 0 0 0!important }
    .y15u5Q li span strong{ margin-left: 4px!important }
    .j0r112 .container .row .col-md-3:last-child .row .col-md-6 .p2769m{ margin-top: 8px!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Middle .qo3320, #main_Internet .qo3320, .o9l44l .w84z88, .m176ye, .n959k8, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(2) .s63448, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(3) .s63448, .w0r597, .r74wm6, .d7fv32, .g4zf86 .e99v68, .w8M19n, .q317to, .pea034, .c37dc8, .o4e46u, .v976lh{ margin: 8px 0 0 0!important }
    .w000w8, .e745l2{ margin: 10px 0 0 0!important }
    .n90bv9 .r3Bx93, .n98kk4, .d7fv32, .q76j70, .j0r112, .y15u5Q li:first-child span, .k51l81{ margin: 12px 0 0 0!important }
    .t079x8, .sI631f{ margin-bottom: 12px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ margin: 14px 0 0 0!important }
    .b584xj{ margin: 14px 0 0 12px!important }
    .s194hd{ margin-top: 14px!important }
    .p2769m .b98w7p{ margin-right: 16px!important }
    #React_A01NB8 .row .col-md-6:nth-child(2) .b384t5, #React_B694LU .row .col-md-6:nth-child(2) .b384t5{ margin-top: 16px!important }
    .b0e620 .container .row .col-md-2:nth-child(4) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(5) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(6) .z9w60a, .c250ba .container .row .col-md-2:nth-child(4) .o720ha, .c250ba .container .row .col-md-2:nth-child(5) .o720ha, .h68k88, .c250ba .container .row .col-md-2:nth-child(6) .o720ha, .m6083e{ margin: 18px 0 0 0!important }
    .a419o0, .j71bv3, .a9680l{ margin-top: 18px!important }
    .h68k88{ margin-bottom: 18px!important }
    .v7c42n, .n772zy{ margin-right: 23px!important }
    .o720ha, .h33p0j, .z48376, .s63448, .i700xb, .t883f0{ margin: 21px 0 0 0!important }
    #main_Middle, .sI631f, .ac1099, #main_Internet, .j0r112 .container .row .col-md-3 .row .col-md-6:last-child .p2769m, .e10di5, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .m505cz .hl280a, .d3726r, .v5t92l{ margin-top: 24px!important }
    .y76v0s, .Up955K, .po7670, .b0e620 .container .row .col-md-2 .z9w60a{ margin: 24px 0 0 0!important }
    .k791vc{ margin: 24px 0!important }
    .c250ba .container .row .col-md-2 .o720ha, .c741h7{ margin: 28px 0 0 0!important }
    .n90bv9 .a407p5, .d540uf{ margin: 30px 0 0 0!important }
    .r87933{ margin-top: 42px!important }
    .g65z5u .Select{ margin: 80px 0 0 0!important }
    .z48376, .t77am5, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y76v0s, .q76j70, .ja605u{ padding: 0!important }
    .r87933{ padding-left: 0!important }
    .r87933{ padding-right: 0!important }
    .r307z9::before{ padding: 2.5px 0 0 1px!important }
    .bs0p47::before{ padding: 5px 0 0 1.5px!important }
    .y62e43{ padding: 9px 18px 9px 18px!important }
    .o9l44l:last-child{ padding: 14px 0 0 0!important }
    .u4025u{ padding-top: 16px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ padding-bottom: 16px!important }
    .y98o1m{ padding-bottom: 18px!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ padding: 0 0 18px 0!important }
    .c741h7{ padding-top: 24px!important }
    #React_A01NB8, #React_B694LU{ padding: 32px 0 0 0!important }
    .v7q534{ padding-bottom: 32px!important }
    .w728x2 .container .row .col-md-3:first-child .ck3902{ padding-top: 48px!important }
    .sI631f{ padding: 50px 0!important }
    .z48376{ padding-left: 92px!important }
    .k791vc{ padding-bottom: 320px!important }
    .n98kk4{ padding-bottom: 56.25%!important }
    .k791vc, .ja605u, .n98kk4{ position: relative!important }
    .n97qf5, .z48376, .n98kk4 iframe{ position: absolute!important }
    .n98kk4 iframe{ left: 0 }
    .n98kk4 iframe{ top: 0 }
    #main_Middle, #main_Internet, #main_Success{ background: #F4F4F4!important }
    .k791vc{ float: none!important }
    .v7q534, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-bottom: 1px solid var(--css-m02l09) }
    .c741h7{ border-top: 1px solid var(--css-m02l09) }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-top: 0!important }
    .a419o0 .i1888e{ box-shadow: var(--css-j71y4n)!important }
    .r89a95, .v5t92l{ box-shadow: none!important }
    .r307z9::before{ content: url( "./Media/Mobile/010.svg" )!important }
    .bs0p47::before{ content: url( "./Media/Mobile/011.svg" )!important }
    .z493ea li a::after{ content: url( "./Media/Icon/009.svg" )!important }
    .w1324l .m78d3j .ox8525 .n772zy::before{ content: "Bilgilendirme Talebi" }
}
@media screen and (min-width:390px) and (max-width:391px){
    .w000w8 br, .u04m7q br, .i63421 thead tr th:nth-child(2), .i63421 thead tr th:nth-child(3), .i63421 thead tr th:nth-child(4), .i63421 tbody tr td:nth-child(2), .i63421 tbody tr td:nth-child(3), .i63421 tbody tr td:nth-child(4), .q35322::before, .z7h704{ display: none!important }
    .q892wd, .t77am5{ display: -webkit-box }
    .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ display: flex }
    #Career_Table thead tr th:nth-child(3), #Career_Table tbody tr td:nth-child(3){ display: block!important }
    .j0r112 .b98w7p{ width: auto!important }
    .y15u5Q li span i svg{ width: 7px!important }
    .m176ye svg, .y98o1m li a svg, .m24ho4 img, .v7c42n svg, .u4025u li label svg{ width: 15px!important }
    .y347yn svg, .n959k8 svg, .e88a72 svg, .d8005g svg, .y15u5Q li span i, .j0z12w svg{ width: 16px!important }
    .r89a95 img, .v5t92l img{ width: 17px!important }
    .o840cp img, .q928g8 svg, .v10846 svg{ width: 19px!important }
    .f062xc img{ width: 22px!important }
    .s5c45j svg{ width: 36px!important }
    .r307z9::before, .bs0p47::before, .r307z9::after, .bs0p47::after, .v5t92l, .a419o0 .i1888e{ width: 42px!important }
    .h33p0j img{ width: 72px!important }
    .Up955K img{ width: 92px!important }
    .m505cz .row .col-md-1{ width: 25%!important }
    .c250ba .container .row .col-md-2, .b0e620 .container .row .col-md-2{ width: 33.3%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3{ width: 50%!important }
    .z9y50r{ width: 93%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-3:last-child, .e745l2, .e745l2::before, .m8m73h, .z48376, .t53z62, .o1m43l, .b39yo6 img, .i31t7q::before, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .g30798, .o01a97 img, .i63421 thead tr th span, .i63421 tbody tr td label, .q35322::before, .u61409, .q76j70, .q76j70, .q20g3c, .d624lk, .w057op .row .col-md-6, .m6747x .container .row .col-md-4, .z2596n, .u04m7q, .f07622, .n98kk4 iframe, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02, .q892wd, .m1364c{ width: 100%!important }
    .h33p0j, .u4025u li label{ min-width: auto!important }
    .p72qx6, .o840cp{ min-width: 100%!important }
    .j0z12w{ min-width: 7%!important }
    .z9y50r{ min-width: 93%!important }
    .d7fv32{ min-width: 175px!important }
    .e88a72, .y347yn{ max-width: 34px!important }
    .d8005g, .r89a95, .v5t92l{ max-width: 42px!important }
    .p72qx6 img{ max-width: 72px }
    .k51l81{ max-width: 160px!important }
    .d7fv32, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-width: 325px!important }
    .q76j70{ max-width: 463px!important }
    .p72qx6{ max-width: none!important }
    .n98kk4{ height: 0!important }
    .n97qf5, .n98kk4 iframe{ height: 100%!important }
    .c741h7, .po7670, .p2769m, .p72qx6, .h33p0j, .h33p0j img, .b39yo6 img, #main_Internet, .a419o0, .p72qx6 img, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .o840cp, .e74i23, .a65254, .d3726r, .o01a97 img, .y76v0s, .q35322::before, .i700xb{ height: auto!important }
    .y15u5Q li span i svg{ height: 7px!important }
    .y347yn svg, .m176ye svg, .y98o1m li a svg, .y15u5Q li span, .m24ho4 img, .v7c42n svg, .u4025u li label svg{ height: 15px!important }
    .n959k8 svg, .e88a72 svg, .d8005g svg, .y15u5Q li span i, .j0z12w svg{ height: 16px!important }
    .r89a95 img, .v5t92l img{ height: 17px!important }
    .o840cp img, .q928g8 svg, .v10846 svg{ height: 19px!important }
    .f062xc img{ height: 22px!important }
    .o091hh{ height: 31px!important }
    .s5c45j svg, .n772zy, .e88a72{ height: 36px!important }
    .y347yn, .k51l81{ height: 36px!important }
    .b584xj{ height: 36px!important }
    .d7fv32, .s2p966, .d8005g, .r307z9, .bs0p47, .r307z9::before, .bs0p47::before, .r89a95, .v5t92l, .a21z73, .z160i4, .a419o0 .i1888e{ height: 42px!important }
    .s8956w, .g2d163, .g2d163 .swiper{ height: 48px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ height: 50px!important }
    .z48376{ height: 72px!important }
    .m78d3j, .s5c45j, .ox8525, .v7c42n, .q928g8, .v10846{ height: 70px!important }
    .a917yq, .g65z5u .m505cz .hl280a .a917yq{ height: 69.4px!important }
    .Up955K img{ height: 92px!important }
    #main_Middle .z93q7k, #main_Internet .z93q7k, #main_Success .z93q7k, .x4i0g2, .x63112{ height: 225px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-height: 225px }
    .d540uf{ min-height: auto!important }
    .m6083e, .x661n3, .g4zf86 .e99v68, .q35h02, .c28g97, .t4q297, .z2499o{ line-height: 1!important }
    .y15u5Q li span strong{ line-height: 16px!important }
    .w8M19n, .n959k8, .q317to, .m176ye, .o1m43l{ line-height: 20px!important }
    .t77am5, .v976lh{ line-height: 21.5px!important }
    .w000w8, .o4e46u, .qo3320, .i31t7q{ line-height: 22px!important }
    .o49df7, .s194hd{ line-height: 22px!important }
    .u04m7q, .m1364c, .e745l2{ line-height: 23px!important }
    .d624lk, .q892wd{ line-height: 24px!important }
    .q20g3c{ line-height: 24.5px!important }
    .c250ba .container .row .col-md-2 .m8m73h, .g30798{ line-height: 26px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ line-height: 50px!important }
    .k51l81, .b584xj, .n772zy{ border-radius: 36px!important }
    .r307z9, .bs0p47{ border-radius: 42px!important }
    .a419o0 .i1888e{ border-radius: 100%!important }
    .i63421 thead tr th span, .i63421 tbody tr td label{ font-size: 11px!important }
    .c997p0::after{ font-size: 11.4px!important }
    .n959k8, .x661n3{ font-size: 11.5px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .m176ye, .o1m43l, .s2p966, .o9l44l .w84z88, .f07622, .e76r8a, .t53z62, .g65z5u .h405q4 .d540uf .d01z5h .r279j3, .g65z5u .Select::before, .g65z5u .w728x2 .ck3902::after, .n5x508::after, .c997p0, .z160i4, .c250ba .container .row .col-md-2 .m8m73h, .g30798{ font-size: 12px!important }
    .z493ea li a, .q746r5, .n90bv9 .a407p5, .g65z5u .h405q4 .d540uf .d01z5h .w67h3x, .g65z5u .h405q4 .d540uf .u4025u li span, .t456c4{ font-size: 12.5px!important }
    .t77am5, .v976lh, .t027vd{ font-size: 12.9px!important }
    .o188l9, .c68146, .y98o1m li a, .d624lk, .q20g3c, .r22m60, .pea034, .x0j627 li a, .k8716o, .rm263w, .w000w8, .y15u5Q li span strong, .o4e46u, .s194hd, .qo3320, .z9y50r, .e745l2, .e745l2, .g65z5u .h405q4 .d540uf .d01z5h .r74wm6, .i31t7q{ font-size: 13px!important }
    .u04m7q, .m1364c, .q892wd{ font-size: 13.3px!important }
    .g4zf86 .e99v68, .s63448{ font-size: 13.5px!important }
    .c250ba .container .row .col-md-2 .o50h0d, .w432xg{ font-size: 14px!important }
    .i745hd, .v406f3{ font-size: 14.5px!important }
    .z2499o, .t4q297, .w0r597{ font-size: 15px!important }
    .o49df7, .p5o2a6{ font-size: 16px!important }
    .c28g97, .q35h02{ font-size: 16.5px!important }
    .m6083e{ font-size: 17px!important }
    .m97p51{ font-size: 88px!important }
    .q892wd, .t77am5{ -webkit-box-orient: vertical }
    .q892wd, .t77am5{ -webkit-line-clamp: 3 }
    .z93q7k, .d01z5h, .x63112{ align-items: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .br6312{ align-items: center!important }
    .d01iq4, .i700xb, .p2769m{ justify-content: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ justify-content: center!important }
    .c741h7, .a419o0, .a65254{ justify-content: space-between!important }
    .c741h7, .p2769m, .a419o0, .a65254{ flex-direction: row!important }
    .p796s4{ flex-direction: column!important }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .q892wd, .o1m43l, .t77am5, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02, .t4q297{ overflow: hidden }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02, .t4q297{ white-space: nowrap }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02, .t4q297{ text-overflow: ellipsis }
    .e745l2, .u04m7q{ text-align: start!important }
    .v976lh{ text-align: center!important }
    .pi4981, .r89a95, .b98w7p, .a419o0 .i1888e, .i31t7q::before, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y0422f .container .row .offset-md-4, .h405q4 .container .row .col-md-3:first-child .d540uf, .y7367g, .u4025u li, .h4s606, .y15u5Q li span i svg{ margin: 0!important }
    .w728x2 .container .row .col-md-3:first-child .b384t5, .qcc560{ margin-top: 0!important }
    .y15u5Q li span, .t4q297, .w432xg, .q20g3c{ margin-bottom: 0!important }
    .p2769m .b98w7p:last-child{ margin-right: 0!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .u4025u li span{ margin: 0 0 0 8px!important }
    .m176ye svg{ margin: -1px 8px 0 0!important }
    .n959k8 svg{ margin: -2px 8px 0 0!important }
    .q20g3c, .d624lk, .u04m7q{ margin: 4px 0 0 0!important }
    .y15u5Q li span strong{ margin-left: 4px!important }
    .j0r112 .container .row .col-md-3:last-child .row .col-md-6 .p2769m{ margin-top: 8px!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Middle .qo3320, #main_Internet .qo3320, .o9l44l .w84z88, .m176ye, .n959k8, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(2) .s63448, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(3) .s63448, .w0r597, .r74wm6, .d7fv32, .g4zf86 .e99v68, .w8M19n, .q317to, .pea034, .c37dc8, .o4e46u, .v976lh{ margin: 8px 0 0 0!important }
    .w000w8, .e745l2{ margin: 10px 0 0 0!important }
    .n90bv9 .r3Bx93, .n98kk4, .d7fv32, .q76j70, .j0r112, .y15u5Q li:first-child span, .k51l81{ margin: 12px 0 0 0!important }
    .t079x8, .sI631f{ margin-bottom: 12px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ margin: 14px 0 0 0!important }
    .b584xj{ margin: 14px 0 0 12px!important }
    .s194hd{ margin-top: 14px!important }
    .p2769m .b98w7p{ margin-right: 16px!important }
    #React_A01NB8 .row .col-md-6:nth-child(2) .b384t5, #React_B694LU .row .col-md-6:nth-child(2) .b384t5{ margin-top: 16px!important }
    .b0e620 .container .row .col-md-2:nth-child(4) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(5) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(6) .z9w60a, .c250ba .container .row .col-md-2:nth-child(4) .o720ha, .c250ba .container .row .col-md-2:nth-child(5) .o720ha, .h68k88, .c250ba .container .row .col-md-2:nth-child(6) .o720ha, .m6083e{ margin: 18px 0 0 0!important }
    .a419o0, .j71bv3, .a9680l{ margin-top: 18px!important }
    .h68k88{ margin-bottom: 18px!important }
    .v7c42n, .n772zy{ margin-right: 23px!important }
    .o720ha, .h33p0j, .z48376, .s63448, .i700xb, .t883f0{ margin: 21px 0 0 0!important }
    #main_Middle, .sI631f, .ac1099, #main_Internet, .j0r112 .container .row .col-md-3 .row .col-md-6:last-child .p2769m, .e10di5, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .m505cz .hl280a, .d3726r, .v5t92l{ margin-top: 24px!important }
    .y76v0s, .Up955K, .po7670, .b0e620 .container .row .col-md-2 .z9w60a{ margin: 24px 0 0 0!important }
    .k791vc{ margin: 24px 0!important }
    .c250ba .container .row .col-md-2 .o720ha, .c741h7{ margin: 28px 0 0 0!important }
    .n90bv9 .a407p5, .d540uf{ margin: 30px 0 0 0!important }
    .r87933{ margin-top: 42px!important }
    .g65z5u .Select{ margin: 80px 0 0 0!important }
    .z48376, .t77am5, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y76v0s, .q76j70, .ja605u{ padding: 0!important }
    .r87933{ padding-left: 0!important }
    .r87933{ padding-right: 0!important }
    .r307z9::before{ padding: 2.5px 0 0 1px!important }
    .bs0p47::before{ padding: 5px 0 0 1.5px!important }
    .y62e43{ padding: 9px 18px 9px 18px!important }
    .o9l44l:last-child{ padding: 14px 0 0 0!important }
    .u4025u{ padding-top: 16px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ padding-bottom: 16px!important }
    .y98o1m{ padding-bottom: 18px!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ padding: 0 0 18px 0!important }
    .c741h7{ padding-top: 24px!important }
    #React_A01NB8, #React_B694LU{ padding: 32px 0 0 0!important }
    .v7q534{ padding-bottom: 32px!important }
    .w728x2 .container .row .col-md-3:first-child .ck3902{ padding-top: 48px!important }
    .sI631f{ padding: 50px 0!important }
    .z48376{ padding-left: 92px!important }
    .k791vc{ padding-bottom: 320px!important }
    .n98kk4{ padding-bottom: 56.25%!important }
    .k791vc, .ja605u, .n98kk4{ position: relative!important }
    .n97qf5, .z48376, .n98kk4 iframe{ position: absolute!important }
    .n98kk4 iframe{ left: 0 }
    .n98kk4 iframe{ top: 0 }
    #main_Middle, #main_Internet, #main_Success{ background: #F4F4F4!important }
    .k791vc{ float: none!important }
    .v7q534, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-bottom: 1px solid var(--css-m02l09) }
    .c741h7{ border-top: 1px solid var(--css-m02l09) }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-top: 0!important }
    .a419o0 .i1888e{ box-shadow: var(--css-j71y4n)!important }
    .r89a95, .v5t92l{ box-shadow: none!important }
    .r307z9::before{ content: url( "./Media/Mobile/010.svg" )!important }
    .bs0p47::before{ content: url( "./Media/Mobile/011.svg" )!important }
    .z493ea li a::after{ content: url( "./Media/Icon/009.svg" )!important }
    .w1324l .m78d3j .ox8525 .n772zy::before{ content: "Bilgilendirme Talebi" }
}
@media screen and (min-width:381px) and (max-width:389px){
    .w000w8 br, .u04m7q br, .i63421 thead tr th:nth-child(2), .i63421 thead tr th:nth-child(3), .i63421 thead tr th:nth-child(4), .i63421 tbody tr td:nth-child(2), .i63421 tbody tr td:nth-child(3), .i63421 tbody tr td:nth-child(4), .q35322::before, .z7h704{ display: none!important }
    .q892wd, .t77am5{ display: -webkit-box }
    .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ display: flex }
    #Career_Table thead tr th:nth-child(3), #Career_Table tbody tr td:nth-child(3){ display: block!important }
    .j0r112 .b98w7p{ width: auto!important }
    .y15u5Q li span i svg{ width: 7px!important }
    .v7c42n svg, .u4025u li label svg{ width: 14px!important }
    .m176ye svg, .y98o1m li a svg, .m24ho4 img{ width: 15px!important }
    .y347yn svg, .n959k8 svg, .e88a72 svg, .d8005g svg, .y15u5Q li span i, .j0z12w svg{ width: 16px!important }
    .r89a95 img, .v5t92l img{ width: 17px!important }
    .q928g8 svg, .v10846 svg{ width: 18px!important }
    .o840cp img{ width: 19px!important }
    .f062xc img{ width: 21px!important }
    .s5c45j svg{ width: 34px!important }
    .r307z9::before, .bs0p47::before, .r307z9::after, .bs0p47::after, .v5t92l, .a419o0 .i1888e{ width: 42px!important }
    .h33p0j img{ width: 72px!important }
    .Up955K img{ width: 92px!important }
    .m505cz .row .col-md-1{ width: 25%!important }
    .c250ba .container .row .col-md-2, .b0e620 .container .row .col-md-2{ width: 33.3%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3{ width: 50%!important }
    .z9y50r{ width: 93%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-3:last-child, .e745l2, .e745l2::before, .m8m73h, .z48376, .t53z62, .o1m43l, .b39yo6 img, .i31t7q::before, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .g30798, .o01a97 img, .i63421 thead tr th span, .i63421 tbody tr td label, .q35322::before, .u61409, .q76j70, .q76j70, .q20g3c, .d624lk, .w057op .row .col-md-6, .m6747x .container .row .col-md-4, .z2596n, .u04m7q, .f07622, .n98kk4 iframe, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02, .q892wd, .m1364c{ width: 100%!important }
    .h33p0j, .u4025u li label{ min-width: auto!important }
    .p72qx6, .o840cp{ min-width: 100%!important }
    .j0z12w{ min-width: 7%!important }
    .z9y50r{ min-width: 93%!important }
    .d7fv32{ min-width: 175px!important }
    .e88a72, .y347yn{ max-width: 34px!important }
    .d8005g, .r89a95, .v5t92l{ max-width: 42px!important }
    .p72qx6 img{ max-width: 72px }
    .k51l81{ max-width: 160px!important }
    .d7fv32, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-width: 275px!important }
    .q76j70{ max-width: 463px!important }
    .p72qx6{ max-width: none!important }
    .n98kk4{ height: 0!important }
    .n97qf5, .n98kk4 iframe{ height: 100%!important }
    .c741h7, .po7670, .p2769m, .p72qx6, .h33p0j, .h33p0j img, .b39yo6 img, #main_Internet, .a419o0, .p72qx6 img, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .o840cp, .e74i23, .a65254, .d3726r, .o01a97 img, .y76v0s, .q35322::before, .i700xb{ height: auto!important }
    .y15u5Q li span i svg{ height: 7px!important }
    .v7c42n svg, .u4025u li label svg{ height: 14px!important }
    .y347yn svg, .m176ye svg, .y98o1m li a svg, .y15u5Q li span, .m24ho4 img{ height: 15px!important }
    .n959k8 svg, .e88a72 svg, .d8005g svg, .y15u5Q li span i, .j0z12w svg{ height: 16px!important }
    .r89a95 img, .v5t92l img{ height: 17px!important }
    .q928g8 svg, .v10846 svg{ height: 18px!important }
    .o840cp img{ height: 19px!important }
    .f062xc img{ height: 21px!important }
    .o091hh{ height: 31px!important }
    .s5c45j svg, .n772zy, .e88a72{ height: 34px!important }
    .y347yn, .k51l81{ height: 36px!important }
    .b584xj{ height: 36px!important }
    .d7fv32, .s2p966, .d8005g, .r307z9, .bs0p47, .r307z9::before, .bs0p47::before, .r89a95, .v5t92l, .a21z73, .z160i4, .a419o0 .i1888e{ height: 42px!important }
    .s8956w, .g2d163, .g2d163 .swiper{ height: 48px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ height: 50px!important }
    .z48376{ height: 72px!important }
    .m78d3j, .s5c45j, .ox8525, .v7c42n, .q928g8, .v10846{ height: 70px!important }
    .a917yq, .g65z5u .m505cz .hl280a .a917yq{ height: 67.8px!important }
    .Up955K img{ height: 92px!important }
    #main_Middle .z93q7k, #main_Internet .z93q7k, #main_Success .z93q7k, .x4i0g2, .x63112{ height: 225px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-height: 200px }
    .d540uf{ min-height: auto!important }
    .m6083e, .x661n3, .g4zf86 .e99v68, .q35h02, .c28g97, .t4q297, .z2499o{ line-height: 1!important }
    .y15u5Q li span strong{ line-height: 16px!important }
    .o1m43l{ line-height: 18px!important }
    .w8M19n, .n959k8, .q317to, .m176ye{ line-height: 20px!important }
    .q892wd, .t77am5, .v976lh{ line-height: 21.5px!important }
    .w000w8, .o4e46u, .qo3320, .i31t7q, .m1364c{ line-height: 22px!important }
    .u04m7q{ line-height: 22px!important }
    .e745l2, .o49df7, .s194hd{ line-height: 23px!important }
    .d624lk{ line-height: 24px!important }
    .q20g3c{ line-height: 24.5px!important }
    .c250ba .container .row .col-md-2 .m8m73h, .g30798{ line-height: 26px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ line-height: 50px!important }
    .n772zy{ border-radius: 34px!important }
    .k51l81, .b584xj{ border-radius: 36px!important }
    .r307z9, .bs0p47{ border-radius: 42px!important }
    .a419o0 .i1888e{ border-radius: 100%!important }
    .i63421 thead tr th span, .i63421 tbody tr td label{ font-size: 11px!important }
    .c997p0::after{ font-size: 11.4px!important }
    .n959k8, .x661n3{ font-size: 11.5px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .m176ye, .o1m43l, .q746r5, .s2p966, .o9l44l .w84z88, .f07622, .e76r8a, .n90bv9 .a407p5, .t53z62, .g65z5u .h405q4 .d540uf .d01z5h .w67h3x, .g65z5u .h405q4 .d540uf .u4025u li span, .g65z5u .h405q4 .d540uf .d01z5h .r279j3, .g65z5u .Select::before, .t027vd, .g65z5u .w728x2 .ck3902::after, .t456c4, .n5x508::after, .c997p0, .z160i4, .c250ba .container .row .col-md-2 .m8m73h, .g30798{ font-size: 12px!important }
    .i31t7q, .z493ea li a, .g65z5u .h405q4 .d540uf .d01z5h .r74wm6{ font-size: 12.5px!important }
    .t77am5, .v976lh{ font-size: 12.9px!important }
    .q892wd, .z9y50r, .e745l2, .e745l2{ font-size: 13px!important }
    .u04m7q, .m1364c{ font-size: 13.1px!important }
    .o188l9, .c68146, .y98o1m li a, .d624lk, .q20g3c, .r22m60, .pea034, .x0j627 li a, .k8716o, .rm263w, .w000w8, .y15u5Q li span strong, .o4e46u, .s194hd, .qo3320{ font-size: 13px!important }
    .g4zf86 .e99v68, .s63448, .w432xg{ font-size: 13.5px!important }
    .v406f3, .c250ba .container .row .col-md-2 .o50h0d{ font-size: 14px!important }
    .i745hd, .w0r597{ font-size: 14.5px!important }
    .z2499o, .t4q297{ font-size: 15px!important }
    .o49df7, .p5o2a6{ font-size: 15.5px!important }
    .q35h02, .c28g97{ font-size: 16px!important }
    .m6083e{ font-size: 16.5px!important }
    .m97p51{ font-size: 82px!important }
    .q892wd, .t77am5{ -webkit-box-orient: vertical }
    .q892wd, .t77am5{ -webkit-line-clamp: 3 }
    .z93q7k, .d01z5h, .x63112{ align-items: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .br6312, .d3726r, .y76v0s{ align-items: center!important }
    .d01iq4, .i700xb{ justify-content: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .p2769m{ justify-content: center!important }
    .c741h7, .a419o0, .a65254{ justify-content: space-between!important }
    .c741h7, .p2769m, .a419o0, .a65254{ flex-direction: row!important }
    .p796s4{ flex-direction: column!important }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .q892wd, .o1m43l, .t77am5, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02, .t4q297{ overflow: hidden }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02, .t4q297{ white-space: nowrap }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02, .t4q297{ text-overflow: ellipsis }
    .e745l2, .u04m7q{ text-align: start!important }
    .v976lh, .o4e46u, .s194hd, .q892wd{ text-align: center!important }
    .pi4981, .r89a95, .b98w7p, .a419o0 .i1888e, .i31t7q::before, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y0422f .container .row .offset-md-4, .h405q4 .container .row .col-md-3:first-child .d540uf, .y7367g, .u4025u li, .h4s606, .y15u5Q li span i svg{ margin: 0!important }
    .w728x2 .container .row .col-md-3:first-child .b384t5, .qcc560{ margin-top: 0!important }
    .y15u5Q li span, .t4q297, .w432xg, .q20g3c{ margin-bottom: 0!important }
    .p2769m .b98w7p:last-child{ margin-right: 0!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .u4025u li span{ margin: 0 0 0 8px!important }
    .m176ye svg{ margin: -1px 8px 0 0!important }
    .n959k8 svg{ margin: -2px 8px 0 0!important }
    .q20g3c, .d624lk, .u04m7q{ margin: 4px 0 0 0!important }
    .y15u5Q li span strong{ margin-left: 4px!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Middle .qo3320, #main_Internet .qo3320{ margin: 8px 0 0 0!important }
    .o9l44l .w84z88, .m176ye, .n959k8, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(2) .s63448, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(3) .s63448, .w0r597, .r74wm6, .d7fv32, .g4zf86 .e99v68, .w8M19n, .q317to, .pea034, .c37dc8, .o4e46u, .v976lh{ margin: 8px 0 0 0!important }
    .w000w8, .e745l2{ margin: 10px 0 0 0!important }
    .g65z5u .p3287u .j71bv3 .i63421 thead tr th:nth-child(1), .g65z5u .p3287u .j71bv3 .i63421 tbody tr:nth-child(1) td:nth-child(1), .g65z5u .p3287u .j71bv3 .i63421 tbody tr td{ padding: 0 0 0 10px!important }
    .n90bv9 .r3Bx93, .n98kk4, .d7fv32, .q76j70, .j0r112, .y15u5Q li:first-child span, .k51l81{ margin: 12px 0 0 0!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ margin: 14px 0 0 0!important }
    .t079x8, .sI631f{ margin-bottom: 12px!important }
    .b584xj{ margin: 14px 0 0 12px!important }
    .s194hd{ margin-top: 14px!important }
    .p2769m .b98w7p{ margin-right: 16px!important }
    #React_A01NB8 .row .col-md-6:nth-child(2) .b384t5, #React_B694LU .row .col-md-6:nth-child(2) .b384t5{ margin-top: 16px!important }
    .b0e620 .container .row .col-md-2:nth-child(4) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(5) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(6) .z9w60a{ margin: 18px 0 0 0!important }
    .c250ba .container .row .col-md-2:nth-child(4) .o720ha, .c250ba .container .row .col-md-2:nth-child(5) .o720ha, .h68k88, .c250ba .container .row .col-md-2:nth-child(6) .o720ha{ margin: 18px 0 0 0!important }
    .a419o0, .j71bv3, .a9680l{ margin-top: 18px!important }
    .h68k88{ margin-bottom: 18px!important }
    .m6083e{ margin: 18px 0 0 0!important }
    .v7c42n, .n772zy{ margin-right: 23px!important }
    .j0r112 .container .row .col-md-3:last-child .row .col-md-6 .p2769m{ margin-top: 21px!important }
    .o720ha, .h33p0j, .z48376, .s63448, .b0e620 .container .row .col-md-2 .z9w60a, .i700xb, .t883f0{ margin: 21px 0 0 0!important }
    #main_Middle, .sI631f, .ac1099, #main_Internet, .j0r112 .container .row .col-md-3 .row .col-md-6:last-child .p2769m, .e10di5, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .m505cz .hl280a, .d3726r, .v5t92l{ margin-top: 24px!important }
    .y76v0s, .Up955K, .po7670{ margin: 24px 0 0 0!important }
    .k791vc{ margin: 24px 0!important }
    .c250ba .container .row .col-md-2 .o720ha, .c741h7{ margin: 28px 0 0 0!important }
    .n90bv9 .a407p5, .d540uf{ margin: 30px 0 0 0!important }
    .r87933{ margin-top: 42px!important }
    .g65z5u .Select{ margin: 80px 0 0 0!important }
    .z48376, .t77am5, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y76v0s, .q76j70, .ja605u{ padding: 0!important }
    .r87933{ padding-left: 0!important }
    .r87933{ padding-right: 0!important }
    .r307z9::before{ padding: 2.5px 0 0 1px!important }
    .bs0p47::before{ padding: 5px 0 0 1.5px!important }
    .y62e43{ padding: 9px 18px 9px 18px!important }
    .o9l44l:last-child{ padding: 14px 0 0 0!important }
    .u4025u{ padding-top: 16px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ padding-bottom: 16px!important }
    .y98o1m{ padding-bottom: 18px!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ padding: 0 0 18px 0!important }
    .c741h7{ padding-top: 24px!important }
    #React_A01NB8, #React_B694LU{ padding: 32px 0 0 0!important }
    .v7q534{ padding-bottom: 32px!important }
    .w728x2 .container .row .col-md-3:first-child .ck3902{ padding-top: 48px!important }
    .sI631f{ padding: 50px 0!important }
    .z48376{ padding-left: 92px!important }
    .k791vc{ padding-bottom: 320px!important }
    .n98kk4{ padding-bottom: 56.25%!important }
    .k791vc, .ja605u, .n98kk4{ position: relative!important }
    .n97qf5, .z48376, .n98kk4 iframe{ position: absolute!important }
    .n98kk4 iframe{ left: 0 }
    .n98kk4 iframe{ top: 0 }
    #main_Middle, #main_Internet, #main_Success{ background: #F4F4F4!important }
    .k791vc{ float: none!important }
    .v7q534, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-bottom: 1px solid var(--css-m02l09) }
    .c741h7{ border-top: 1px solid var(--css-m02l09) }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-top: 0!important }
    .a419o0 .i1888e{ box-shadow: var(--css-j71y4n)!important }
    .r89a95, .v5t92l{ box-shadow: none!important }
    .r307z9::before{ content: url( "./Media/Mobile/010.svg" )!important }
    .bs0p47::before{ content: url( "./Media/Mobile/011.svg" )!important }
    .z493ea li a::after{ content: url( "./Media/Icon/009.svg" )!important }
    .w1324l .m78d3j .ox8525 .n772zy::before{ content: "Bilgilendirme Talebi" }
}
@media screen and (min-width:375px) and (max-width:380px){
    .w000w8 br, .u04m7q br, .i63421 thead tr th:nth-child(2), .i63421 thead tr th:nth-child(3), .i63421 thead tr th:nth-child(4), .i63421 tbody tr td:nth-child(2), .i63421 tbody tr td:nth-child(3), .i63421 tbody tr td:nth-child(4), .q35322::before, .z7h704, .b584xj{ display: none!important }
    .q892wd, .t77am5{ display: -webkit-box }
    .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ display: flex }
    #Career_Table thead tr th:nth-child(3), #Career_Table tbody tr td:nth-child(3){ display: block!important }
    .j0r112 .b98w7p{ width: auto!important }
    .y15u5Q li span i svg{ width: 7px!important }
    .v7c42n svg, .u4025u li label svg{ width: 14px!important }
    .m176ye svg, .y98o1m li a svg, .m24ho4 img{ width: 15px!important }
    .y347yn svg, .n959k8 svg, .e88a72 svg, .d8005g svg, .y15u5Q li span i, .j0z12w svg{ width: 16px!important }
    .r89a95 img, .v5t92l img{ width: 17px!important }
    .q928g8 svg, .v10846 svg, .o840cp img{ width: 18px!important }
    .f062xc img{ width: 21px!important }
    .s5c45j svg{ width: 34px!important }
    .r307z9::before, .bs0p47::before, .r307z9::after, .bs0p47::after, .v5t92l, .a419o0 .i1888e{ width: 42px!important }
    .h33p0j img{ width: 72px!important }
    .Up955K img{ width: 92px!important }
    .m505cz .row .col-md-1{ width: 25%!important }
    .c250ba .container .row .col-md-2, .b0e620 .container .row .col-md-2{ width: 33.3%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3{ width: 50%!important }
    .z9y50r{ width: 94%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-3:last-child, .e745l2, .e745l2::before, .m8m73h, .z48376, .t53z62, .o1m43l, .b39yo6 img, .i31t7q::before, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .g30798, .o01a97 img, .i63421 thead tr th span, .i63421 tbody tr td label, .q35322::before, .u61409, .q76j70, .z2499o, .q76j70, .q20g3c, .d624lk, .w057op .row .col-md-6, .m6747x .container .row .col-md-4, .z2596n, .u04m7q, .f07622, .o49df7, .n98kk4 iframe, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02{ width: 100%!important }
    .h33p0j, .u4025u li label{ min-width: auto!important }
    .p72qx6, .o840cp{ min-width: 100%!important }
    .d7fv32{ min-width: 175px!important }
    .e88a72, .y347yn{ max-width: 34px!important }
    .d8005g, .r89a95, .v5t92l{ max-width: 42px!important }
    .p72qx6 img{ max-width: 72px }
    .k51l81{ max-width: 160px!important }
    .d7fv32, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-width: 175px!important }
    .q76j70{ max-width: 463px!important }
    .p72qx6{ max-width: none!important }
    .n98kk4{ height: 0!important }
    .n97qf5, .n98kk4 iframe{ height: 100%!important }
    .c741h7, .po7670, .p2769m, .p72qx6, .h33p0j, .h33p0j img, .b39yo6 img, #main_Internet, .a419o0, .p72qx6 img, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .o840cp, .e74i23, .a65254, .d3726r, .o01a97 img, .y76v0s, .q35322::before, .i700xb{ height: auto!important }
    .y15u5Q li span i svg{ height: 7px!important }
    .v7c42n svg, .u4025u li label svg{ height: 14px!important }
    .y347yn svg, .m176ye svg, .y98o1m li a svg, .y15u5Q li span, .m24ho4 img{ height: 15px!important }
    .n959k8 svg, .e88a72 svg, .d8005g svg, .y15u5Q li span i, .j0z12w svg{ height: 16px!important }
    .r89a95 img, .v5t92l img{ height: 17px!important }
    .q928g8 svg, .v10846 svg, .o840cp img{ height: 18px!important }
    .f062xc img{ height: 21px!important }
    .o091hh{ height: 30px!important }
    .s5c45j svg, .n772zy, .e88a72{ height: 34px!important }
    .y347yn, .k51l81{ height: 36px!important }
    .b584xj{ height: 40px!important }
    .d7fv32, .s2p966, .d8005g, .r307z9, .bs0p47, .r307z9::before, .bs0p47::before, .r89a95, .v5t92l, .a21z73, .z160i4, .a419o0 .i1888e{ height: 42px!important }
    .s8956w, .g2d163, .g2d163 .swiper{ height: 48px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ height: 50px!important }
    .z48376{ height: 72px!important }
    .m78d3j, .s5c45j, .ox8525, .v7c42n, .q928g8, .v10846{ height: 70px!important }
    .a917yq, .g65z5u .m505cz .hl280a .a917yq{ height: 65.6px!important }
    .Up955K img{ height: 92px!important }
    #main_Middle .z93q7k, #main_Internet .z93q7k, #main_Success .z93q7k, .x4i0g2, .x63112{ height: 200px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-height: 228px }
    .d540uf{ min-height: auto!important }
    .m6083e, .x661n3, .g4zf86 .e99v68, .c28g97, .q35h02, .c28g97, .t4q297, .z2499o{ line-height: 1!important }
    .y15u5Q li span strong{ line-height: 16px!important }
    .o1m43l{ line-height: 18px!important }
    .w8M19n, .n959k8, .q317to, .m176ye{ line-height: 20px!important }
    .v976lh, .t77am5, .i31t7q{ line-height: 21px!important }
    .q892wd{ line-height: 21.5px!important }
    .w000w8, .o4e46u, .s194hd, .qo3320, .m1364c, .u04m7q{ line-height: 22px!important }
    .e745l2{ line-height: 23px!important }
    .d624lk{ line-height: 24px!important }
    .q20g3c{ line-height: 24.5px!important }
    .c250ba .container .row .col-md-2 .m8m73h, .g30798{ line-height: 25px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ line-height: 50px!important }
    .n772zy{ border-radius: 34px!important }
    .k51l81{ border-radius: 36px!important }
    .b584xj{ border-radius: 38px!important }
    .r307z9, .bs0p47{ border-radius: 42px!important }
    .a419o0 .i1888e{ border-radius: 100%!important }
    .i63421 thead tr th span, .i63421 tbody tr td label{ font-size: 11px!important }
    .c997p0::after{ font-size: 11.4px!important }
    .n959k8{ font-size: 11.5px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .m176ye, .o1m43l, .q746r5, .s2p966, .x661n3, .o9l44l .w84z88, .f07622, .e76r8a, .n90bv9 .a407p5, .t53z62, .g65z5u .h405q4 .d540uf .d01z5h .r74wm6, .g65z5u .h405q4 .d540uf .d01z5h .w67h3x, .g65z5u .h405q4 .d540uf .u4025u li span, .g65z5u .h405q4 .d540uf .d01z5h .r279j3, .g65z5u .Select::before, .t027vd, .g65z5u .w728x2 .ck3902::after, .t456c4, .n5x508::after, .c997p0, .z160i4, .c250ba .container .row .col-md-2 .m8m73h, .g30798{ font-size: 12px!important }
    .e745l2, .e745l2, .i31t7q, .t77am5, .v976lh, .z493ea li a{ font-size: 12.5px!important }
    .q892wd, .z9y50r, .m1364c, .u04m7q{ font-size: 13px!important }
    .o188l9, .c68146, .y98o1m li a, .d624lk, .q20g3c, .r22m60, .pea034, .v406f3, .w432xg, .x0j627 li a, .k8716o, .rm263w, .w000w8, .y15u5Q li span strong, .o4e46u, .s194hd, .qo3320{ font-size: 13px!important }
    .g4zf86 .e99v68, .s63448{ font-size: 13.5px!important }
    .w0r597{ font-size: 14px!important }
    .c250ba .container .row .col-md-2 .o50h0d, .i745hd, .t4q297{ font-size: 14.5px!important }
    .o49df7, .z2499o, .p5o2a6{ font-size: 15px!important }
    .o49df7{ font-size: 15.5px!important }
    .m6083e, .q35h02, .c28g97{ font-size: 16px!important }
    .m97p51{ font-size: 78px!important }
    .q892wd, .t77am5{ -webkit-box-orient: vertical }
    .q892wd, .t77am5{ -webkit-line-clamp: 3 }
    .z93q7k, .x63112, .d01z5h, .i700xb, .g4zf86{ align-items: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ align-items: center!important }
    .d01iq4, .p2769m{ justify-content: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ justify-content: center!important }
    .c741h7, .a419o0, .a65254{ justify-content: space-between!important }
    .c741h7, .p2769m, .a419o0, .a65254{ flex-direction: row!important }
    .p796s4{ flex-direction: column!important }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .q892wd, .o1m43l, .t77am5, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02{ overflow: hidden }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02{ white-space: nowrap }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02{ text-overflow: ellipsis }
    .e745l2, .u04m7q, .t77am5, .s194hd{ text-align: start!important }
    .pi4981, .r89a95, .b98w7p, .a419o0 .i1888e, .i31t7q::before, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y0422f .container .row .offset-md-4, .h405q4 .container .row .col-md-3:first-child .d540uf, .y7367g, .u4025u li, .h4s606, .y15u5Q li span i svg{ margin: 0!important }
    .w728x2 .container .row .col-md-3:first-child .b384t5, .qcc560{ margin-top: 0!important }
    .y15u5Q li span, .t4q297, .w432xg, .q20g3c{ margin-bottom: 0!important }
    .p2769m .b98w7p:last-child{ margin-right: 0!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .u4025u li span{ margin: 0 0 0 8px!important }
    .m176ye svg{ margin: -1px 8px 0 0!important }
    .n959k8 svg{ margin: -2px 8px 0 0!important }
    .q20g3c, .d624lk{ margin: 4px 0 0 0!important }
    .y15u5Q li span strong{ margin-left: 4px!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Middle .qo3320, #main_Internet .qo3320{ margin: 6px 0 0 0!important }
    .o9l44l .w84z88, .m176ye, .n959k8, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(2) .s63448, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(3) .s63448, .w0r597, .r74wm6, .d7fv32, .g4zf86 .e99v68, .w8M19n, .q317to, .pea034, .c37dc8, .o4e46u, .v976lh, .u04m7q{ margin: 8px 0 0 0!important }
    .w000w8, .e745l2{ margin: 10px 0 0 0!important }
    .g65z5u .p3287u .j71bv3 .i63421 thead tr th:nth-child(1), .g65z5u .p3287u .j71bv3 .i63421 tbody tr:nth-child(1) td:nth-child(1), .g65z5u .p3287u .j71bv3 .i63421 tbody tr td{ padding: 0 0 0 10px!important }
    .n90bv9 .r3Bx93, .n98kk4, .d7fv32, .q76j70, .j0r112, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb, .y15u5Q li:first-child span, .k51l81{ margin: 12px 0 0 0!important }
    .t079x8, .sI631f{ margin-bottom: 12px!important }
    .b584xj{ margin: 14px 0 0 12px!important }
    .s194hd{ margin-top: 14px!important }
    .p2769m .b98w7p{ margin-right: 16px!important }
    #React_A01NB8 .row .col-md-6:nth-child(2) .b384t5, #React_B694LU .row .col-md-6:nth-child(2) .b384t5{ margin-top: 16px!important }
    .b0e620 .container .row .col-md-2:nth-child(4) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(5) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(6) .z9w60a, .i700xb, .t883f0{ margin: 18px 0 0 0!important }
    .c250ba .container .row .col-md-2:nth-child(4) .o720ha, .c250ba .container .row .col-md-2:nth-child(5) .o720ha, .h68k88, .c250ba .container .row .col-md-2:nth-child(6) .o720ha{ margin: 21px 0 0 0!important }
    .a419o0, .j71bv3, .a9680l{ margin-top: 18px!important }
    .h68k88{ margin-bottom: 18px!important }
    .m6083e{ margin: 19px 0 0 0!important }
    .v7c42n, .n772zy{ margin-right: 23px!important }
    .j0r112 .container .row .col-md-3:last-child .row .col-md-6 .p2769m{ margin-top: 21px!important }
    .o720ha, .h33p0j, .z48376, .s63448, .b0e620 .container .row .col-md-2 .z9w60a{ margin: 21px 0 0 0!important }
    #main_Middle, .sI631f, .ac1099, #main_Internet, .j0r112 .container .row .col-md-3 .row .col-md-6:last-child .p2769m, .e10di5, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .m505cz .hl280a, .d3726r, .v5t92l{ margin-top: 24px!important }
    .y76v0s, .Up955K, .po7670{ margin: 24px 0 0 0!important }
    .k791vc{ margin: 24px 0!important }
    .c250ba .container .row .col-md-2 .o720ha, .c741h7{ margin: 28px 0 0 0!important }
    .n90bv9 .a407p5, .d540uf{ margin: 30px 0 0 0!important }
    .r87933{ margin-top: 42px!important }
    .g65z5u .Select{ margin: 80px 0 0 0!important }
    .z48376, .t77am5, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y76v0s, .q76j70, .ja605u{ padding: 0!important }
    .r87933{ padding-left: 0!important }
    .r87933{ padding-right: 0!important }
    .r307z9::before{ padding: 2.5px 0 0 1px!important }
    .bs0p47::before{ padding: 5px 0 0 1.5px!important }
    .y62e43{ padding: 9px 18px 9px 18px!important }
    .o9l44l:last-child{ padding: 14px 0 0 0!important }
    .u4025u{ padding-top: 16px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ padding-bottom: 16px!important }
    .y98o1m{ padding-bottom: 18px!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ padding: 0 0 18px 0!important }
    .c741h7{ padding-top: 24px!important }
    #React_A01NB8, #React_B694LU{ padding: 32px 0 0 0!important }
    .v7q534{ padding-bottom: 32px!important }
    .w728x2 .container .row .col-md-3:first-child .ck3902{ padding-top: 48px!important }
    .sI631f{ padding: 50px 0!important }
    .z48376{ padding-left: 92px!important }
    .k791vc{ padding-bottom: 320px!important }
    .n98kk4{ padding-bottom: 56.25%!important }
    .k791vc, .ja605u, .n98kk4{ position: relative!important }
    .n97qf5, .z48376, .n98kk4 iframe{ position: absolute!important }
    .n98kk4 iframe{ left: 0 }
    .n98kk4 iframe{ top: 0 }
    #main_Middle, #main_Internet, #main_Success{ background: #F4F4F4!important }
    .k791vc{ float: none!important }
    .v7q534, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-bottom: 1px solid var(--css-m02l09) }
    .c741h7{ border-top: 1px solid var(--css-m02l09) }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-top: 0!important }
    .a419o0 .i1888e{ box-shadow: var(--css-j71y4n)!important }
    .r89a95, .v5t92l{ box-shadow: none!important }
    .r307z9::before{ content: url( "./Media/Mobile/010.svg" )!important }
    .bs0p47::before{ content: url( "./Media/Mobile/011.svg" )!important }
    .z493ea li a::after{ content: url( "./Media/Icon/009.svg" )!important }
    .w1324l .m78d3j .ox8525 .n772zy::before{ content: "Bilgilendirme Talebi" }
}
@media screen and (min-width:359px) and (max-width:374px){
    .w000w8 br, .u04m7q br, .i63421 thead tr th:nth-child(2), .i63421 thead tr th:nth-child(3), .i63421 thead tr th:nth-child(4), .i63421 tbody tr td:nth-child(2), .i63421 tbody tr td:nth-child(3), .i63421 tbody tr td:nth-child(4), .q35322::before, .z7h704, .b584xj{ display: none!important }
    .q892wd, .t77am5{ display: -webkit-box }
    .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ display: flex }
    #Career_Table thead tr th:nth-child(3), #Career_Table tbody tr td:nth-child(3){ display: block!important }
    .j0r112 .b98w7p{ width: auto!important }
    .y15u5Q li span i svg{ width: 7px!important }
    .v7c42n svg, .u4025u li label svg{ width: 14px!important }
    .m176ye svg, .y98o1m li a svg, .v5t92l img, .m24ho4 img, .j0z12w svg{ width: 15px!important }
    .y347yn svg, .n959k8 svg, .e88a72 svg, .d8005g svg, .y15u5Q li span i{ width: 16px!important }
    .r89a95 img{ width: 17px!important }
    .q928g8 svg, .v10846 svg, .o840cp img{ width: 18px!important }
    .f062xc img{ width: 21px!important }
    .s5c45j svg{ width: 34px!important }
    .r307z9::before, .bs0p47::before, .r307z9::after, .bs0p47::after, .v5t92l, .a419o0 .i1888e{ width: 42px!important }
    .h33p0j img{ width: 72px!important }
    .Up955K img{ width: 92px!important }
    .m505cz .row .col-md-1{ width: 25%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3, .c250ba .container .row .col-md-2, .b0e620 .container .row .col-md-2{ width: 50%!important }
    .z9y50r{ width: 94%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-3:last-child, .e745l2, .e745l2::before, .c250ba .container .row .col-md-2 .m8m73h, .z48376, .t53z62, .o1m43l, .b39yo6 img, .i31t7q::before, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .g30798, .o01a97 img, .i63421 thead tr th span, .i63421 tbody tr td label, .q35322::before, .u61409, .q76j70, .z2499o, .q76j70, .q20g3c, .d624lk, .w057op .row .col-md-6, .m6747x .container .row .col-md-4, .z2596n, .u04m7q, .f07622, .o49df7, .n98kk4 iframe, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02{ width: 100%!important }
    .h33p0j, .u4025u li label{ min-width: auto!important }
    .p72qx6, .o840cp{ min-width: 100%!important }
    .d7fv32{ min-width: 175px!important }
    .e88a72, .y347yn{ max-width: 34px!important }
    .d8005g, .r89a95, .v5t92l{ max-width: 42px!important }
    .p72qx6 img{ max-width: 72px }
    .k51l81{ max-width: 160px!important }
    .d7fv32, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-width: 175px!important }
    .q76j70{ max-width: 463px!important }
    .p72qx6{ max-width: none!important }
    .n98kk4{ height: 0!important }
    .n97qf5, .n98kk4 iframe{ height: 100%!important }
    .c741h7, .po7670, .p2769m, .p72qx6, .h33p0j, .h33p0j img, .b39yo6 img, #main_Internet, .a419o0, .p72qx6 img, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .o840cp, .e74i23, .a65254, .d3726r, .o01a97 img, .y76v0s, .q35322::before, .i700xb{ height: auto!important }
    .y15u5Q li span i svg{ height: 7px!important }
    .v7c42n svg, .u4025u li label svg{ height: 14px!important }
    .y347yn svg, .m176ye svg, .y98o1m li a svg, .v5t92l img, .y15u5Q li span, .m24ho4 img, .j0z12w svg{ height: 15px!important }
    .n959k8 svg, .e88a72 svg, .d8005g svg, .y15u5Q li span i{ height: 16px!important }
    .r89a95 img{ height: 17px!important }
    .q928g8 svg, .v10846 svg, .o840cp img{ height: 18px!important }
    .f062xc img{ height: 21px!important }
    .o091hh{ height: 29px!important }
    .s5c45j svg, .n772zy, .e88a72{ height: 34px!important }
    .y347yn, .k51l81{ height: 36px!important }
    .b584xj{ height: 40px!important }
    .d7fv32, .s2p966, .d8005g, .r307z9, .bs0p47, .r307z9::before, .bs0p47::before, .r89a95, .v5t92l, .a21z73, .z160i4, .a419o0 .i1888e{ height: 42px!important }
    .s8956w, .g2d163, .g2d163 .swiper{ height: 48px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ height: 50px!important }
    .z48376{ height: 72px!important }
    .m78d3j, .s5c45j, .ox8525, .v7c42n, .q928g8, .v10846{ height: 70px!important }
    .a917yq, .g65z5u .m505cz .hl280a .a917yq{ height: 61.8px!important }
    .Up955K img{ height: 92px!important }
    #main_Middle .z93q7k, #main_Internet .z93q7k, #main_Success .z93q7k, .x4i0g2, .x63112{ height: 200px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-height: 228px }
    .d540uf{ min-height: auto!important }
    .m6083e, .x661n3, .g4zf86 .e99v68, .c28g97, .q35h02, .c28g97, .t4q297, .z2499o{ line-height: 1!important }
    .y15u5Q li span strong{ line-height: 16px!important }
    .o1m43l{ line-height: 18px!important }
    .w8M19n, .n959k8, .q317to, .m176ye{ line-height: 20px!important }
    .v976lh, .t77am5, .m1364c, .qo3320, .i31t7q{ line-height: 21px!important }
    .q892wd, .w000w8, .o4e46u, .s194hd, .u04m7q{ line-height: 22px!important }
    .e745l2{ line-height: 23px!important }
    .d624lk{ line-height: 24px!important }
    .q20g3c{ line-height: 24.5px!important }
    .c250ba .container .row .col-md-2 .m8m73h, .g30798{ line-height: 25px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ line-height: 50px!important }
    .n772zy{ border-radius: 34px!important }
    .k51l81{ border-radius: 36px!important }
    .b584xj{ border-radius: 38px!important }
    .r307z9, .bs0p47{ border-radius: 42px!important }
    .a419o0 .i1888e{ border-radius: 100%!important }
    .c997p0::after{ font-size: 11.4px!important }
    .n959k8, .i63421 thead tr th span, .i63421 tbody tr td label{ font-size: 11.5px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .m176ye, .o1m43l, .q746r5, .s2p966, .x661n3, .o9l44l .w84z88, .f07622, .e76r8a, .n90bv9 .a407p5, .t53z62, .z493ea li a, .g65z5u .h405q4 .d540uf .d01z5h .r74wm6, .g65z5u .h405q4 .d540uf .d01z5h .w67h3x, .g65z5u .h405q4 .d540uf .u4025u li span, .g65z5u .h405q4 .d540uf .d01z5h .r279j3, .g65z5u .Select::before, .t027vd, .g65z5u .w728x2 .ck3902::after, .t456c4, .n5x508::after, .c997p0, .z160i4, .c250ba .container .row .col-md-2 .m8m73h, .g30798{ font-size: 12px!important }
    .e745l2, .e745l2, .qo3320, .q892wd, .i31t7q, .t77am5, .v976lh, .m1364c, .z9y50r, .u04m7q{ font-size: 12.5px!important }
    .o188l9, .c68146, .y98o1m li a, .d624lk, .q20g3c, .r22m60, .pea034, .v406f3, .w432xg, .x0j627 li a, .k8716o, .rm263w, .w000w8, .y15u5Q li span strong, .o4e46u, .s194hd{ font-size: 13px!important }
    .g4zf86 .e99v68, .s63448, .w0r597{ font-size: 13.5px!important }
    .c250ba .container .row .col-md-2 .o50h0d, .i745hd{ font-size: 14px!important }
    .c28g97, .o49df7, .z2499o, .t4q297, .p5o2a6{ font-size: 15px!important }
    .m6083e, .q35h02{ font-size: 15.5px!important }
    .m97p51{ font-size: 78px!important }
    .q892wd, .t77am5{ -webkit-box-orient: vertical }
    .q892wd, .t77am5{ -webkit-line-clamp: 3 }
    .z93q7k, .x63112, .d01z5h, .i700xb, .g4zf86{ align-items: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ align-items: center!important }
    .d01iq4, .p2769m{ justify-content: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ justify-content: center!important }
    .c741h7, .a419o0, .a65254{ justify-content: space-between!important }
    .c741h7, .p2769m, .a419o0, .a65254{ flex-direction: row!important }
    .p796s4{ flex-direction: column!important }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .q892wd, .o1m43l, .t77am5, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02{ overflow: hidden }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02{ white-space: nowrap }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong, .q35h02{ text-overflow: ellipsis }
    .e745l2, .u04m7q, .t77am5, .s194hd{ text-align: start!important }
    .pi4981, .r89a95, .b98w7p, .a419o0 .i1888e, .i31t7q::before, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y0422f .container .row .offset-md-4, .h405q4 .container .row .col-md-3:first-child .d540uf, .y7367g, .u4025u li, .h4s606, .y15u5Q li span i svg{ margin: 0!important }
    .w728x2 .container .row .col-md-3:first-child .b384t5, .qcc560{ margin-top: 0!important }
    .y15u5Q li span, .t4q297, .w432xg, .q20g3c{ margin-bottom: 0!important }
    .p2769m .b98w7p:last-child{ margin-right: 0!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .u4025u li span{ margin: 0 0 0 8px!important }
    .m176ye svg{ margin: -1px 8px 0 0!important }
    .n959k8 svg{ margin: -2px 8px 0 0!important }
    .q20g3c, .d624lk{ margin: 4px 0 0 0!important }
    .y15u5Q li span strong{ margin-left: 4px!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Middle .qo3320, #main_Internet .qo3320{ margin: 6px 0 0 0!important }
    .o9l44l .w84z88, .m176ye, .n959k8, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(2) .s63448, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(3) .s63448, .w0r597, .r74wm6, .d7fv32, .g4zf86 .e99v68, .w8M19n, .q317to, .pea034, .c37dc8, .o4e46u, .v976lh, .u04m7q{ margin: 8px 0 0 0!important }
    .w000w8, .e745l2{ margin: 10px 0 0 0!important }
    .n90bv9 .r3Bx93, .n98kk4, .d7fv32, .q76j70, .j0r112, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb, .y15u5Q li:first-child span, .k51l81{ margin: 12px 0 0 0!important }
    .t079x8, .sI631f{ margin-bottom: 12px!important }
    .b584xj{ margin: 14px 0 0 12px!important }
    .s194hd{ margin-top: 14px!important }
    .p2769m .b98w7p{ margin-right: 16px!important }
    #React_A01NB8 .row .col-md-6:nth-child(2) .b384t5, #React_B694LU .row .col-md-6:nth-child(2) .b384t5{ margin-top: 16px!important }
    .b0e620 .container .row .col-md-2:nth-child(4) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(5) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(6) .z9w60a, .i700xb, .t883f0{ margin: 18px 0 0 0!important }
    .c250ba .container .row .col-md-2:nth-child(3) .o720ha, .c250ba .container .row .col-md-2:nth-child(4) .o720ha, .c250ba .container .row .col-md-2:nth-child(5) .o720ha, .h68k88, .c250ba .container .row .col-md-2:nth-child(6) .o720ha{ margin: 18px 0 0 0!important }
    .a419o0, .j71bv3, .a9680l{ margin-top: 18px!important }
    .h68k88{ margin-bottom: 18px!important }
    .m6083e{ margin: 19px 0 0 0!important }
    .v7c42n, .n772zy{ margin-right: 20px!important }
    .j0r112 .container .row .col-md-3:last-child .row .col-md-6 .p2769m{ margin-top: 21px!important }
    .o720ha, .h33p0j, .z48376, .s63448, .b0e620 .container .row .col-md-2 .z9w60a{ margin: 21px 0 0 0!important }
    #main_Middle, .sI631f, .ac1099, #main_Internet, .j0r112 .container .row .col-md-3 .row .col-md-6:last-child .p2769m, .e10di5, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .m505cz .hl280a, .d3726r, .v5t92l{ margin-top: 24px!important }
    .y76v0s, .Up955K, .po7670{ margin: 24px 0 0 0!important }
    .k791vc{ margin: 24px 0!important }
    .c250ba .container .row .col-md-2 .o720ha, .c741h7{ margin: 28px 0 0 0!important }
    .n90bv9 .a407p5, .d540uf{ margin: 30px 0 0 0!important }
    .r87933{ margin-top: 42px!important }
    .g65z5u .Select{ margin: 80px 0 0 0!important }
    .z48376, .t77am5, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y76v0s, .q76j70, .ja605u{ padding: 0!important }
    .r87933{ padding-left: 0!important }
    .r87933{ padding-right: 0!important }
    .r307z9::before{ padding: 2.5px 0 0 1px!important }
    .bs0p47::before{ padding: 5px 0 0 1.5px!important }
    .y62e43{ padding: 9px 18px 9px 18px!important }
    .g65z5u .p3287u .j71bv3 .i63421 tbody tr td{ padding: 0 0 0 10px!important }
    .g65z5u .p3287u .j71bv3 .i63421 thead tr th{ padding: 0 0 0 10px!important }
    .o9l44l:last-child{ padding: 14px 0 0 0!important }
    .u4025u{ padding-top: 16px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ padding-bottom: 16px!important }
    .y98o1m{ padding-bottom: 18px!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ padding: 0 0 18px 0!important }
    .c741h7{ padding-top: 24px!important }
    #React_A01NB8, #React_B694LU{ padding: 32px 0 0 0!important }
    .v7q534{ padding-bottom: 32px!important }
    .w728x2 .container .row .col-md-3:first-child .ck3902{ padding-top: 48px!important }
    .sI631f{ padding: 50px 0!important }
    .z48376{ padding-left: 92px!important }
    .k791vc{ padding-bottom: 320px!important }
    .n98kk4{ padding-bottom: 56.25%!important }
    .k791vc, .ja605u, .n98kk4{ position: relative!important }
    .n97qf5, .z48376, .n98kk4 iframe{ position: absolute!important }
    .n98kk4 iframe{ left: 0 }
    .n98kk4 iframe{ top: 0 }
    #main_Middle, #main_Internet, #main_Success{ background: #F4F4F4!important }
    .k791vc{ float: none!important }
    .v7q534, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-bottom: 1px solid var(--css-m02l09) }
    .c741h7{ border-top: 1px solid var(--css-m02l09) }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-top: 0!important }
    .a419o0 .i1888e{ box-shadow: var(--css-j71y4n)!important }
    .r89a95, .v5t92l{ box-shadow: none!important }
    .r307z9::before{ content: url( "./Media/Mobile/010.svg" )!important }
    .bs0p47::before{ content: url( "./Media/Mobile/011.svg" )!important }
    .z493ea li a::after{ content: url( "./Media/Icon/017.svg" )!important }
    .w1324l .m78d3j .ox8525 .n772zy::before{ content: "Bilgilendirme Talebi" }
}
@media screen and (min-width:321px) and (max-width:358px){
    .w000w8 br, .u04m7q br, .i63421 thead tr th:nth-child(3), .i63421 thead tr th:nth-child(4), .i63421 tbody tr td:nth-child(3), .i63421 tbody tr td:nth-child(4), .q35322::before, .z7h704, .b584xj{ display: none!important }
    .q892wd, .t77am5{ display: -webkit-box }
    .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ display: flex }
    #Career_Table thead tr th:nth-child(3), #Career_Table tbody tr td:nth-child(3){ display: block!important }
    .j0r112 .b98w7p{ width: auto!important }
    .y15u5Q li span i svg{ width: 6px!important }
    .v7c42n svg, .u4025u li label svg, .m24ho4 img, .j0z12w svg{ width: 14px!important }
    .m176ye svg, .y98o1m li a svg, .v5t92l img, .y15u5Q li span i{ width: 15px!important }
    .y347yn svg, .n959k8 svg, .e88a72 svg, .d8005g svg, .r89a95 img{ width: 16px!important }
    .q928g8 svg, .v10846 svg{ width: 18px!important }
    .o840cp img{ width: 20px!important }
    .f062xc img{ width: 21px!important }
    .s5c45j svg{ width: 34px!important }
    .r307z9::before, .bs0p47::before, .r307z9::after, .bs0p47::after, .v5t92l, .a419o0 .i1888e{ width: 42px!important }
    .h33p0j img{ width: 64px!important }
    .Up955K img{ width: 92px!important }
    .b0e620 .container .row .col-md-2, .c250ba .container .row .col-md-2, .m505cz .row .col-md-1{ width: 33.3%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3{ width: 50%!important }
    .z9y50r{ width: 94%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-3:last-child, .e745l2, .e745l2::before, .c250ba .container .row .col-md-2 .m8m73h, .z48376, .t53z62, .o1m43l, .b39yo6 img, .i31t7q::before, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .g30798, .o01a97 img, .i63421 thead tr th span, .i63421 tbody tr td label, .q35322::before, .u61409, .q76j70, .z2499o, .q76j70, .q20g3c, .d624lk, .w057op .row .col-md-6, .m6747x .container .row .col-md-4, .z2596n, .u04m7q, .f07622, .o49df7, .n98kk4 iframe, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong{ width: 100%!important }
    .h33p0j, .u4025u li label{ min-width: auto!important }
    .p72qx6, .o840cp{ min-width: 100%!important }
    .d7fv32{ min-width: 175px!important }
    .e88a72, .y347yn{ max-width: 34px!important }
    .d8005g, .r89a95, .v5t92l{ max-width: 42px!important }
    .p72qx6 img{ max-width: 64px }
    .k51l81{ max-width: 160px!important }
    .d7fv32, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-width: 175px!important }
    .q76j70{ max-width: 463px!important }
    .p72qx6{ max-width: none!important }
    .n98kk4{ height: 0!important }
    .n97qf5, .n98kk4 iframe{ height: 100%!important }
    .c741h7, .po7670, .p2769m, .p72qx6, .h33p0j, .h33p0j img, .b39yo6 img, #main_Internet, .a419o0, .p72qx6 img, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .o840cp, .e74i23, .a65254, .d3726r, .o01a97 img, .y76v0s, .q35322::before, .i700xb{ height: auto!important }
    .y15u5Q li span i svg{ height: 6px!important }
    .v7c42n svg, .u4025u li label svg, .m24ho4 img, .j0z12w svg{ height: 14px!important }
    .y347yn svg, .m176ye svg, .y98o1m li a svg, .v5t92l img, .y15u5Q li span i, .y15u5Q li span{ height: 15px!important }
    .n959k8 svg, .e88a72 svg, .d8005g svg, .r89a95 img{ height: 16px!important }
    .q928g8 svg, .v10846 svg{ height: 18px!important }
    .o840cp img{ height: 20px!important }
    .f062xc img{ height: 21px!important }
    .o091hh{ height: 29px!important }
    .s5c45j svg, .n772zy, .e88a72{ height: 34px!important }
    .y347yn, .k51l81{ height: 36px!important }
    .b584xj{ height: 40px!important }
    .d7fv32, .s2p966, .d8005g, .r307z9, .bs0p47, .r307z9::before, .bs0p47::before, .r89a95, .v5t92l, .a21z73, .z160i4, .a419o0 .i1888e{ height: 42px!important }
    .s8956w, .g2d163, .g2d163 .swiper{ height: 48px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ height: 50px!important }
    .z48376{ height: 64px!important }
    .m78d3j, .s5c45j, .ox8525, .v7c42n, .q928g8, .v10846{ height: 70px!important }
    .a917yq, .g65z5u .m505cz .hl280a .a917yq{ height: 85.75px!important }
    .Up955K img{ height: 92px!important }
    #main_Middle .z93q7k, #main_Internet .z93q7k, #main_Success .z93q7k, .x4i0g2, .x63112{ height: 150px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-height: 228px }
    .d540uf{ min-height: auto!important }
    .m6083e, .x661n3, .g4zf86 .e99v68, .c28g97, .q35h02, .c28g97, .t4q297, .z2499o{ line-height: 1!important }
    .y15u5Q li span strong{ line-height: 16px!important }
    .o1m43l{ line-height: 18px!important }
    .w8M19n, .n959k8, .q317to, .m176ye, .i31t7q{ line-height: 20px!important }
    .s194hd, .v976lh, .t77am5, .w000w8{ line-height: 21px!important }
    .e745l2, .qo3320, .q892wd, .u04m7q, .m1364c, .o4e46u{ line-height: 22px!important }
    .d624lk{ line-height: 24px!important }
    .q20g3c, .e745l2::before, .g30798, .c250ba .container .row .col-md-2 .m8m73h{ line-height: 25px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ line-height: 50px!important }
    .n772zy{ border-radius: 34px!important }
    .k51l81{ border-radius: 36px!important }
    .b584xj{ border-radius: 38px!important }
    .r307z9, .bs0p47{ border-radius: 42px!important }
    .a419o0 .i1888e{ border-radius: 100%!important }
    .g30798, .c997p0::after{ font-size: 11px!important }
    .n959k8, .i63421 thead tr th span, .i63421 tbody tr td label, .c250ba .container .row .col-md-2 .m8m73h{ font-size: 11.5px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .m176ye, .o1m43l, .q746r5, .s2p966, .x661n3, .o9l44l .w84z88, .f07622, .e76r8a, .n90bv9 .a407p5, .t53z62, .z9y50r, .z493ea li a, .g65z5u .h405q4 .d540uf .d01z5h .r74wm6, .g65z5u .h405q4 .d540uf .d01z5h .w67h3x, .g65z5u .h405q4 .d540uf .u4025u li span, .g65z5u .h405q4 .d540uf .d01z5h .r279j3, .g65z5u .Select::before, .t027vd, .g65z5u .w728x2 .ck3902::after, .t456c4, .n5x508::after, .c997p0, .z160i4{ font-size: 12px!important }
    .e745l2, .e745l2, .w000w8, .y15u5Q li span strong, .qo3320, .q892wd, .i31t7q, .t77am5, .v976lh, .o4e46u, .s194hd{ font-size: 12.5px!important }
    .o188l9, .c68146, .y98o1m li a, .d624lk, .q20g3c, .r22m60, .i745hd, .pea034, .p5o2a6, .w0r597, .v406f3, .w432xg, .x0j627 li a, .k8716o, .rm263w{ font-size: 13px!important }
    .m1364c, .u04m7q{ font-size: 13.1px!important }
    .g4zf86 .e99v68, .s63448, .z2499o{ font-size: 13.5px!important }
    .c250ba .container .row .col-md-2 .o50h0d, .o49df7, .t4q297{ font-size: 14px!important }
    .m6083e, .q35h02, .c28g97{ font-size: 14.5px!important }
    .m97p51{ font-size: 72px!important }
    .q892wd, .t77am5{ -webkit-box-orient: vertical }
    .q892wd, .t77am5{ -webkit-line-clamp: 3 }
    .z93q7k, .x63112, .d01z5h, .i700xb, .g4zf86{ align-items: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ align-items: center!important }
    .d01iq4, .p2769m{ justify-content: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ justify-content: center!important }
    .c741h7, .a419o0, .a65254{ justify-content: space-between!important }
    .c741h7, .p2769m, .a419o0, .a65254{ flex-direction: row!important }
    .p796s4{ flex-direction: column!important }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .q892wd, .o1m43l, .t77am5, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong{ overflow: hidden }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong{ white-space: nowrap }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong{ text-overflow: ellipsis }
    .e745l2, .u04m7q, .t77am5, .s194hd{ text-align: start!important }
    .pi4981, .r89a95, .b98w7p, .a419o0 .i1888e, .i31t7q::before, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y0422f .container .row .offset-md-4, .h405q4 .container .row .col-md-3:first-child .d540uf, .y7367g, .u4025u li, .h4s606, .y15u5Q li span i svg{ margin: 0!important }
    .w728x2 .container .row .col-md-3:first-child .b384t5, .qcc560{ margin-top: 0!important }
    .y15u5Q li span, .t4q297, .w432xg, .q20g3c{ margin-bottom: 0!important }
    .p2769m .b98w7p:last-child{ margin-right: 0!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .u4025u li span{ margin: 0 0 0 8px!important }
    .m176ye svg{ margin: -1px 8px 0 0!important }
    .n959k8 svg{ margin: -2px 8px 0 0!important }
    .q20g3c, .d624lk{ margin: 4px 0 0 0!important }
    .y15u5Q li span strong{ margin-left: 4px!important }
    .o9l44l .w84z88, .m176ye, .n959k8, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(2) .s63448, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(3) .s63448, .w0r597, .r74wm6, .d7fv32, .g4zf86 .e99v68, .e745l2, .w8M19n, .q317to, .pea034, .c37dc8, .o4e46u, #main_Internet .i31t7q, #main_Success .qo3320, #main_Middle .qo3320, #main_Internet .qo3320, .v976lh, .u04m7q{ margin: 8px 0 0 0!important }
    .k51l81{ margin: 10px 0 0 0!important }
    .n90bv9 .r3Bx93, .n98kk4, .d7fv32, .q76j70, .j0r112, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb, .w000w8{ margin: 12px 0 0 0!important }
    .t079x8, .sI631f{ margin-bottom: 12px!important }
    .b584xj{ margin: 14px 0 0 12px!important }
    .y15u5Q li:first-child span, .s194hd{ margin-top: 14px!important }
    .p2769m .b98w7p{ margin-right: 16px!important }
    #React_A01NB8 .row .col-md-6:nth-child(2) .b384t5, #React_B694LU .row .col-md-6:nth-child(2) .b384t5{ margin-top: 16px!important }
    .b0e620 .container .row .col-md-2:nth-child(4) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(5) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(6) .z9w60a, .i700xb, .t883f0, .m6083e{ margin: 18px 0 0 0!important }
    .a419o0, .j71bv3, .a9680l{ margin-top: 18px!important }
    .h68k88{ margin-bottom: 18px!important }
    .v7c42n, .n772zy{ margin-right: 20px!important }
    .j0r112 .container .row .col-md-3:last-child .row .col-md-6 .p2769m{ margin-top: 21px!important }
    .c250ba .container .row .col-md-2:nth-child(4) .o720ha, .c250ba .container .row .col-md-2:nth-child(5) .o720ha, .h68k88, .c250ba .container .row .col-md-2:nth-child(6) .o720ha, .h33p0j, .z48376, .s63448, .b0e620 .container .row .col-md-2 .z9w60a{ margin: 21px 0 0 0!important }
    #main_Middle, .sI631f, .ac1099, #main_Internet, .j0r112 .container .row .col-md-3 .row .col-md-6:last-child .p2769m, .e10di5, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .m505cz .hl280a, .d3726r, .v5t92l{ margin-top: 24px!important }
    .y76v0s, .Up955K, .po7670{ margin: 24px 0 0 0!important }
    .k791vc{ margin: 24px 0!important }
    .c250ba .container .row .col-md-2 .o720ha, .c741h7{ margin: 28px 0 0 0!important }
    .n90bv9 .a407p5, .d540uf{ margin: 30px 0 0 0!important }
    .r87933{ margin-top: 42px!important }
    .g65z5u .Select{ margin: 80px 0 0 0!important }
    .z48376, .t77am5, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y76v0s, .q76j70, .ja605u{ padding: 0!important }
    .r87933{ padding-left: 0!important }
    .r87933{ padding-right: 0!important }
    .y62e43{ padding: 9px 18px 9px 18px!important }
    .o9l44l:last-child{ padding: 14px 0 0 0!important }
    .u4025u{ padding-top: 16px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ padding-bottom: 16px!important }
    .y98o1m{ padding-bottom: 18px!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ padding: 0 0 18px 0!important }
    .c741h7{ padding-top: 24px!important }
    #React_A01NB8, #React_B694LU{ padding: 32px 0 0 0!important }
    .v7q534{ padding-bottom: 32px!important }
    .w728x2 .container .row .col-md-3:first-child .ck3902{ padding-top: 48px!important }
    .sI631f{ padding: 50px 0!important }
    .z48376{ padding-left: 80px!important }
    .k791vc{ padding-bottom: 320px!important }
    .n98kk4{ padding-bottom: 56.25%!important }
    .k791vc, .ja605u, .n98kk4{ position: relative!important }
    .n97qf5, .z48376, .n98kk4 iframe{ position: absolute!important }
    .n98kk4 iframe{ left: 0 }
    .n98kk4 iframe{ top: 0 }
    #main_Middle, #main_Internet, #main_Success{ background: #F4F4F4!important }
    .k791vc{ float: none!important }
    .v7q534, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-bottom: 1px solid var(--css-m02l09) }
    .c741h7{ border-top: 1px solid var(--css-m02l09) }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-top: 0!important }
    .a419o0 .i1888e, .v5t92l{ box-shadow: var(--css-j71y4n)!important }
    .r89a95{ box-shadow: none!important }
    .r307z9::before{ content: url( "./Media/Mobile/010.svg" )!important }
    .bs0p47::before{ content: url( "./Media/Mobile/011.svg" )!important }
    .z493ea li a::after{ content: url( "./Media/Icon/017.svg" )!important }
    .w1324l .m78d3j .ox8525 .n772zy::before{ content: "Bilgilendirme" }
}
@media screen and (max-width: 320px){
    .w000w8 br, .u04m7q br, .i63421 thead tr th:nth-child(3), .i63421 thead tr th:nth-child(4), .i63421 tbody tr td:nth-child(3), .i63421 tbody tr td:nth-child(4), .q35322::before, .z7h704, .b584xj{ display: none!important }
    .q892wd, .t77am5{ display: -webkit-box }
    .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658{ display: flex }
    #Career_Table thead tr th:nth-child(3), #Career_Table tbody tr td:nth-child(3){ display: block!important }
    .j0r112 .b98w7p{ width: auto!important }
    .y15u5Q li span i svg{ width: 6px!important }
    .m24ho4 img, .j0z12w svg{ width: 13px!important }
    .v7c42n svg, .y15u5Q li span i, .u4025u li label svg, .r89a95 img{ width: 14px!important }
    .m176ye svg, .y98o1m li a svg, .v5t92l img{ width: 15px!important }
    .y347yn svg, .n959k8 svg, .e88a72 svg, .d8005g svg{ width: 16px!important }
    .q928g8 svg, .v10846 svg{ width: 19px!important }
    .o840cp img{ width: 18px!important }
    .f062xc img{ width: 21px!important }
    .s5c45j svg{ width: 34px!important }
    .r307z9::before, .bs0p47::before, .r307z9::after, .bs0p47::after, .v5t92l, .a419o0 .i1888e{ width: 42px!important }
    .h33p0j img{ width: 64px!important }
    .Up955K img{ width: 92px!important }
    .m505cz .row .col-md-1{ width: 33.3%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-9 .row .col-md-3, .c250ba .container .row .col-md-2, .b0e620 .container .row .col-md-2{ width: 50%!important }
    .z9y50r{ width: 94%!important }
    .g26s0e .container .row .col-md-12 .row .col-md-3:last-child, .e745l2, .e745l2::before, .c250ba .container .row .col-md-2 .m8m73h, .z48376, .t53z62, .o1m43l, .b39yo6 img, .i31t7q::before, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .g30798, .o01a97 img, .i63421 thead tr th span, .i63421 tbody tr td label, .q35322::before, .u61409, .q76j70, .z2499o, .q76j70, .q20g3c, .d624lk, .w057op .row .col-md-6, .m6747x .container .row .col-md-4, .z2596n, .u04m7q, .f07622, .n98kk4 iframe, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong{ width: 100%!important }
    .h33p0j, .u4025u li label{ min-width: auto!important }
    .p72qx6, .o840cp{ min-width: 100%!important }
    .d7fv32{ min-width: 175px!important }
    .e88a72, .y347yn{ max-width: 34px!important }
    .d8005g, .r89a95, .v5t92l{ max-width: 42px!important }
    .p72qx6 img{ max-width: 64px }
    .k51l81{ max-width: 160px!important }
    .d7fv32, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-width: 175px!important }
    .q76j70{ max-width: 463px!important }
    .p72qx6{ max-width: none!important }
    .n98kk4{ height: 0!important }
    .n97qf5, .n98kk4 iframe{ height: 100%!important }
    .c741h7, .po7670, .p2769m, .p72qx6, .h33p0j, .h33p0j img, .b39yo6 img, #main_Internet, .a419o0, .p72qx6 img, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img, .o840cp, .e74i23, .a65254, .d3726r, .o01a97 img, .y76v0s, .q35322::before, .i700xb{ height: auto!important }
    .y15u5Q li span i svg{ height: 6px!important }
    .y15u5Q li span{ height: 12px!important }
    .m24ho4 img, .j0z12w svg{ height: 13px!important }
    .v7c42n svg, .y15u5Q li span i, .u4025u li label svg, .r89a95 img{ height: 14px!important }
    .y347yn svg, .m176ye svg, .y98o1m li a svg, .v5t92l img{ height: 15px!important }
    .n959k8 svg, .e88a72 svg, .d8005g svg{ height: 16px!important }
    .q928g8 svg, .v10846 svg{ height: 18px!important }
    .o840cp img{ height: 19px!important }
    .f062xc img{ height: 21px!important }
    .o091hh{ height: 29px!important }
    .s5c45j svg, .n772zy, .e88a72{ height: 34px!important }
    .y347yn, .k51l81{ height: 36px!important }
    .b584xj{ height: 40px!important }
    .d7fv32, .s2p966, .d8005g, .r307z9, .bs0p47, .r307z9::before, .bs0p47::before, .r89a95, .v5t92l, .a21z73, .z160i4, .a419o0 .i1888e{ height: 42px!important }
    .s8956w, .g2d163, .g2d163 .swiper{ height: 48px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ height: 50px!important }
    .z48376{ height: 64px!important }
    .m78d3j, .s5c45j, .ox8525, .v7c42n, .q928g8, .v10846{ height: 70px!important }
    .a917yq, .g65z5u .m505cz .hl280a .a917yq{ height: 76.96px!important }
    .Up955K img{ height: 92px!important }
    #main_Middle .z93q7k, #main_Internet .z93q7k, #main_Success .z93q7k, .x4i0g2, .x63112{ height: 150px!important }
    .m6747x .container .row .col-md-4 .h5l5y9 .se5658 img{ max-height: 228px }
    .d540uf{ min-height: auto!important }
    .m6083e, .x661n3, .g4zf86 .e99v68, .c28g97, .q35h02, .c28g97, .t4q297, .z2499o{ line-height: 1!important }
    .y15u5Q li span strong{ line-height: 14px!important }
    .o1m43l{ line-height: 18px!important }
    .t77am5{ line-height: 19.5px!important }
    .w8M19n, .n959k8, .q317to, .m176ye, .q892wd, .v976lh, .i31t7q, .m1364c, .u04m7q, .qo3320, .o4e46u{ line-height: 20px!important }
    .s194hd, .w000w8{ line-height: 21px!important }
    .e745l2{ line-height: 22px!important }
    .d624lk{ line-height: 24px!important }
    .q20g3c, .e745l2::before{ line-height: 25px!important }
    .c250ba .container .row .col-md-2 .m8m73h, .g30798{ line-height: 26px!important }
    .x0j627 li a, .y98o1m li a, .z493ea li a{ line-height: 50px!important }
    .n772zy{ border-radius: 34px!important }
    .k51l81{ border-radius: 36px!important }
    .b584xj{ border-radius: 38px!important }
    .r307z9, .bs0p47{ border-radius: 42px!important }
    .a419o0 .i1888e{ border-radius: 100%!important }
    .g30798, .c250ba .container .row .col-md-2 .m8m73h, .c997p0::after{ font-size: 11px!important }
    .n959k8, .i63421 thead tr th span, .i63421 tbody tr td label{ font-size: 11.5px!important }
    .r3Bx93 li a, .w8M19n, .q317to, .m176ye, .o1m43l, .q746r5, .s2p966, .x661n3, .o9l44l .w84z88, .f07622, .e76r8a, .n90bv9 .a407p5, .e745l2, .w000w8, .y15u5Q li span strong, .qo3320, .t53z62, .q892wd, .v976lh, .t77am5, .i31t7q, .u04m7q, .m1364c, .o4e46u, .z9y50r, .s194hd, .z493ea li a, .g65z5u .h405q4 .d540uf .d01z5h .r74wm6, .g65z5u .h405q4 .d540uf .d01z5h .w67h3x, .g65z5u .h405q4 .d540uf .u4025u li span, .g65z5u .h405q4 .d540uf .d01z5h .r279j3, .g65z5u .Select::before, .t027vd, .g65z5u .w728x2 .ck3902::after, .t456c4, .n5x508::after, .c997p0, .z160i4{ font-size: 12px!important }
    .o188l9, .c68146, .y98o1m li a, .c250ba .container .row .col-md-2 .o50h0d, .d624lk, .q20g3c, .g4zf86 .e99v68, .r22m60, .s63448, .z2499o, .i745hd, .c28g97, .pea034, .p5o2a6, .w0r597, .v406f3, .w432xg, .x0j627 li a, .k8716o, .rm263w, .o49df7{ font-size: 13px!important }
    .t4q297{ font-size: 13.5px!important }
    .m6083e, .q35h02{ font-size: 14px!important }
    .m97p51{ font-size: 72px!important }
    .q892wd, .t77am5{ -webkit-box-orient: vertical }
    .q892wd, .t77am5{ -webkit-line-clamp: 3 }
    .d01z5h, .i700xb{ align-items: start!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .br6312, .v7q534, .c250ba .container .row .col-md-2 .o720ha, .po7670, .b0e620 .container .row .col-md-2 .z9w60a, .d3726r{ align-items: center!important }
    .z48376, .a419o0 .i1888e, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .d01iq4{ justify-content: center!important }
    .c741h7, .a419o0, .a65254{ justify-content: space-between!important }
    .c741h7, .p2769m, .a419o0, .a65254{ flex-direction: row!important }
    .p796s4{ flex-direction: column!important }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .q892wd, .o1m43l, .t77am5, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong{ overflow: hidden }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong{ white-space: nowrap }
    .r3Bx93 li a, .w8M19n, .q317to, .a407p5, .c250ba .container .row .col-md-2 .m8m73h, .o1m43l, .g30798, .i63421 thead tr th span, .i63421 tbody tr td label, .t027vd, .t456c4, .f07622, .y15u5Q li span strong, .z9y50r, .o49df7, .c28g97, .g65z5u .k84mu0 .t079x8 .k96847 .y8450e li span strong{ text-overflow: ellipsis }
    .e745l2, .u04m7q, .t77am5, .s194hd{ text-align: start!important }
    .v976lh, .t77am5, .e745l2, .c250ba .container .row .col-md-2 .m8m73h, .w000w8, #main_Internet .i31t7q, #main_Success .qo3320, #main_Middle .qo3320, #main_Internet .qo3320, .u04m7q, .g30798, .o4e46u{ text-align: center!important }
    .pi4981, .r89a95, .b98w7p, .a419o0 .i1888e, .i31t7q::before, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y0422f .container .row .offset-md-4, .h405q4 .container .row .col-md-3:first-child .d540uf, .y7367g, .u4025u li, .h4s606, .y15u5Q li span i svg{ margin: 0!important }
    .w728x2 .container .row .col-md-3:first-child .b384t5, .qcc560{ margin-top: 0!important }
    .y15u5Q li span, .t4q297, .w432xg, .q20g3c{ margin-bottom: 0!important }
    .p2769m .b98w7p:last-child{ margin-right: 0!important }
    .r3Bx93 li a{ margin: 0 0 6px 0!important }
    .u4025u li span{ margin: 0 0 0 8px!important }
    .m176ye svg{ margin: -1px 8px 0 0!important }
    .n959k8 svg{ margin: -2px 8px 0 0!important }
    .q20g3c, .d624lk, .v976lh{ margin: 4px 0 0 0!important }
    .y15u5Q li span strong{ margin-left: 4px!important }
    #main_Internet .i31t7q, #main_Success .qo3320, #main_Middle .qo3320, #main_Internet .qo3320, .u04m7q{ margin: 8px 0 0 0!important }
    .o9l44l .w84z88, .m176ye, .n959k8, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(2) .s63448, .s3zb07 .container .row .col-md-10:last-child .row .col-md-4:nth-child(3) .s63448, .w0r597, .r74wm6, .d7fv32, .g4zf86 .e99v68, .w000w8, .w8M19n, .q317to, .pea034, .c37dc8, .o4e46u{ margin: 8px 0 0 0!important }
    .e745l2{ margin: 10px 0 0 0!important }
    .n90bv9 .r3Bx93, .n98kk4, .d7fv32, .q76j70, .j0r112, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb, .k51l81{ margin: 12px 0 0 0!important }
    .t079x8, .sI631f{ margin-bottom: 12px!important }
    .b584xj{ margin: 14px 0 0 12px!important }
    .y15u5Q li:first-child span, .s194hd{ margin-top: 14px!important }
    .p2769m .b98w7p{ margin-right: 16px!important }
    #React_A01NB8 .row .col-md-6:nth-child(2) .b384t5, #React_B694LU .row .col-md-6:nth-child(2) .b384t5{ margin-top: 16px!important }
    .b0e620 .container .row .col-md-2:nth-child(4) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(5) .z9w60a, .b0e620 .container .row .col-md-2:nth-child(6) .z9w60a, .i700xb, .t883f0, .m6083e{ margin: 18px 0 0 0!important }
    .a419o0, .j71bv3, .a9680l{ margin-top: 18px!important }
    .h68k88{ margin-bottom: 18px!important }
    .v7c42n, .n772zy{ margin-right: 20px!important }
    .j0r112 .container .row .col-md-3:last-child .row .col-md-6 .p2769m{ margin-top: 21px!important }
    .c250ba .container .row .col-md-2:nth-child(4) .o720ha, .c250ba .container .row .col-md-2:nth-child(5) .o720ha, .h68k88, .c250ba .container .row .col-md-2:nth-child(6) .o720ha, .h33p0j, .z48376, .s63448, .b0e620 .container .row .col-md-2 .z9w60a, .c250ba .container .row .col-md-2:nth-child(3) .o720ha{ margin: 21px 0 0 0!important }
    #main_Middle, .sI631f, .ac1099, #main_Internet, .j0r112 .container .row .col-md-3 .row .col-md-6:last-child .p2769m, .e10di5, #main_Success, .m6747x .container .row .col-md-4 .h5l5y9 .se5658, .m505cz .hl280a, .d3726r, .v5t92l{ margin-top: 24px!important }
    .y76v0s, .Up955K, .po7670{ margin: 24px 0 0 0!important }
    .k791vc{ margin: 24px 0!important }
    .c250ba .container .row .col-md-2 .o720ha, .c741h7{ margin: 28px 0 0 0!important }
    .n90bv9 .a407p5, .d540uf{ margin: 30px 0 0 0!important }
    .r87933{ margin-top: 42px!important }
    .g65z5u .Select{ margin: 80px 0 0 0!important }
    .z48376, .t77am5, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7, .y76v0s, .q76j70, .ja605u{ padding: 0!important }
    .r87933{ padding-left: 0!important }
    .r87933{ padding-right: 0!important }
    .r307z9::before{ padding: 4px 0 0 1px!important }
    .y62e43{ padding: 9px 18px 9px 18px!important }
    .o9l44l:last-child{ padding: 14px 0 0 0!important }
    .u4025u{ padding-top: 16px!important }
    .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb{ padding-bottom: 16px!important }
    .y98o1m{ padding-bottom: 18px!important }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ padding: 0 0 18px 0!important }
    .c741h7{ padding-top: 24px!important }
    #React_A01NB8, #React_B694LU{ padding: 32px 0 0 0!important }
    .v7q534{ padding-bottom: 32px!important }
    .w728x2 .container .row .col-md-3:first-child .ck3902{ padding-top: 48px!important }
    .sI631f{ padding: 50px 0!important }
    .z48376{ padding-left: 80px!important }
    .k791vc{ padding-bottom: 320px!important }
    .n98kk4{ padding-bottom: 56.25%!important }
    .k791vc, .ja605u, .n98kk4{ position: relative!important }
    .n97qf5, .z48376, .n98kk4 iframe{ position: absolute!important }
    .n98kk4 iframe{ left: 0 }
    .n98kk4 iframe{ top: 0 }
    #main_Middle, #main_Internet, #main_Success{ background: #F4F4F4!important }
    .k791vc{ float: none!important }
    .r307z9::before{ content: url( "./Media/Mobile/010.svg" )!important }
    .bs0p47::before{ content: url( "./Media/Mobile/011.svg" )!important }
    .z493ea li a::after{ content: url( "./Media/Icon/017.svg" )!important }
    .v7q534, .v7672m .container .row .col-md-12 .row .col-md-4 .i700xb, .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-bottom: 1px solid var(--css-m02l09) }
    .c741h7{ border-top: 1px solid var(--css-m02l09) }
    .x35f74 .container .row .col-md-1:nth-child(3) .c741h7{ border-top: 0!important }
    .a419o0 .i1888e, .v5t92l{ box-shadow: var(--css-j71y4n)!important }
    .r89a95{ box-shadow: none!important }
    .w1324l .m78d3j .ox8525 .n772zy::before{ content: "Bilgilendirme" }
}